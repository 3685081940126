import React, {Component} from "react";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FeedbackRepository from "../../repository/FeedbackRepository";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import moment from "moment";
import {strings} from "../../Localization/Localization";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./css/FeedbackToPublish.css"
import TabInstitutions from "./Tab/TabInstitutions";
import TabTags from "./Tab/TabTags";
import TabCategories from "./Tab/TabCategories";

class FeedbackToPublishNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: null,
            institutionIds: [],
            tagsIds: [],
            feedbackPublications: [],
            pageCount: 1,
            isWaitingToPublish: false,
            selectedTabInstitutions: true,
            selectedTabTags: false,
            selectedTabCategories: false,
            institutions: [],
            tags: [],
            categories: [],
            isFeedback: true,
        }
    }

    lang = localStorage.getItem('activeLanguage')

    fetchData = (selectedPage = 0) => {
        let feedbackId = this.props.match.params.id;
        FeedbackPublicationRepository.getFeedbackPublication(feedbackId, selectedPage).then(res => {
            this.setState({
                feedbackPublications: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(err => {
            console.log(err)
        })
    }
    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    feedbackForPublish = () => {
        let id = this.props.match.params.id;
        FeedbackRepository.getFeedbackById(id).then(res => {
            this.setState({feedback: res.data})
        }).catch(() => {
            toast.error(strings.errorWhileLoadingFeedback);
        });
    };

    componentDidMount() {
        this.fetchData();
        this.feedbackForPublish();
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
        this.changeTabToPublish("institutions")
    }

    handleInstitutionChange = (e) => {
        this.setState({institutionIds: e})
    }
    handleTagsChange = (e) => {
        this.setState({tagsIds: e})
    };
    publishToInstitutions = () => {
        if (this.state.institutionIds.length !== 0) {
            this.setState({
                isWaitingToPublish: true
            })
            let feedbackId = this.props.match.params.id;
            let i = this.state.institutionIds.toString();
            FeedbackPublicationRepository.publishFeedbackToInstitutions(feedbackId, i).then(() => {
                this.fetchData();
                toast.success(strings.successfullyPublishedFeedback)
                this.setState({
                    isWaitingToPublish: false
                })
                setTimeout(() => {
                    window.location = '/publishfeedback/' + feedbackId
                }, 5000)
            }).catch(err => {
                toast.error(err.message)
                this.setState({
                    isWaitingToPublish: false
                })
                setTimeout(() => {
                    window.location = '/publishfeedback/' + feedbackId
                }, 5000)
            });
        }
    }
    publishFeedbackToTags = () => {
        if (this.state.tagsIds.length !== 0) {
            this.setState({
                isWaitingToPublish: true
            })
            let annId = this.props.match.params.id;
            let t = this.state.tagsIds.toString();
            FeedbackPublicationRepository.publishFeedbackToTags(annId, t).then(() => {
                this.fetchData();
                toast.success(strings.successfullyPublishedFeedback)
                this.setState({
                    isWaitingToPublish: false
                })
                setTimeout(() => {
                    window.location = '/publishfeedback/' + annId
                }, 5000)

            }).catch(err => {
                // toast.error(strings.failedToPublishFeedback)
                this.setState({
                    isWaitingToPublish: false
                })
                setTimeout(() => {
                    window.location = '/publishfeedback/' + annId
                }, 5000)

            });
        }
    };

    changeTabToPublish = (name) => {
        if(name === "institutions"){
            this.setState({
                selectedTabInstitutions: true,
                selectedTabTags: false,
                selectedTabCategories: false
            })
        } else if(name === "tags"){
            this.setState({
                selectedTabInstitutions: false,
                selectedTabTags: true,
                selectedTabCategories: false
            })
        } else if(name === "categories"){
            this.setState({
                selectedTabInstitutions: false,
                selectedTabTags: false,
                selectedTabCategories: true
            })
        }
    }

    render() {
        if (!this.state.feedback) {
            return (<div/>)
        } else {
            return (
                <div className="container-fluid">
                    <div className="row" style={{color: "black", marginTop: "10px"}}>
                        <div className="col-md-12 text-center">
                            <h2 style={{borderBottom: "2px solid gray", display: "inline"}}>
                                {this.state.feedback.name}
                            </h2>
                        </div>
                        <div className="col-md-12 text-center" style={{marginTop: "20px"}}>
                            <h5 style={{fontWeight: "300"}}>
                                {this.state.feedback.description}
                            </h5>
                        </div>
                        <div className="col-md-12 text-left" style={{color: "red", fontSize: "12pt"}}>
                            <span>
                                Рок до:&nbsp;
                            </span>
                            <span style={{fontWeight: "500"}}>
                                {moment(this.state.feedback.dueDate).local().format('DD-MM-YYYY')}
                            </span>
                        </div>
                    </div>

                    <div className="row" style={{color: "black", marginTop: "50px"}}>
                        <div className="col-md-9">
                            <button className="tabButton" onClick={() => this.changeTabToPublish('institutions')}
                                style={{backgroundColor: this.state.selectedTabInstitutions ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabInstitutions ? "underline" : ""}}>
                                <h6 style={{display: "inline", fontWeight: "600"}}>
                                    Објави на институција:
                                </h6>
                            </button>

                            <button className="tabButton" onClick={() => this.changeTabToPublish('tags')}
                                style={{backgroundColor: this.state.selectedTabTags ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabTags ? "underline" : ""}}>
                                <h6 style={{display: "inline", fontWeight: "600"}}>
                                    Објави на ознаки:
                                </h6>
                            </button>

                            <button className="tabButton" onClick={() => this.changeTabToPublish('categories')}
                                style={{backgroundColor: this.state.selectedTabCategories ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabCategories ? "underline" : ""}}>
                                <h6 style={{display: "inline", fontWeight: "600"}}>
                                    Објави на категории:
                                </h6>
                            </button>
                        </div>

                        <div className="col-md-3 text-right">
                            <a href={`/feedback/${this.state.feedback.id}/previouspublications`} className="defaultBtn btn btn-primary" style={{float: "right"}}>
                                <FontAwesomeIcon icon={faCopy}/>
                                &nbsp;Сите објави за оваа анкета
                            </a>
                        </div>
                    </div>

                    {this.state.selectedTabInstitutions ?
                        <TabInstitutions feedbackId={this.state.feedback.id} isFeedback={this.state.isFeedback}/>
                        : ""
                    }
                    {this.state.selectedTabTags ?
                        <TabTags feedbackId={this.state.feedback.id} isFeedback={this.state.isFeedback}/>
                        : ""
                    }
                    {this.state.selectedTabCategories ?
                        <TabCategories feedbackId={this.state.feedback.id} isFeedback={this.state.isFeedback}/>
                        : ""
                    }
                </div>
            )
        }
    }

}

export default FeedbackToPublishNew;