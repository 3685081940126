import React, {Component} from 'react';
import NapAreaRepository from "../../repository/NapAreaRepository";
import {toast} from "react-toastify";
import {strings} from "../../Localization/Localization";
import CrudModal from "../Crud/CrudModal";
import ReactPaginate from "react-paginate";
import SearchBar from "../Search/SearchBar";
import CrmEntryRepository from "../../repository/CrmEntryRepository";

class CrmEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crmEntries: [],
            pageCount: 1,
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (keyword, selectedPage = 0) => {
        CrmEntryRepository.getAllPaged(selectedPage, 10).then(res => {
            this.setState({
                crmEntries: res.data.content,
                pageCount: res.data.totalPages,
            })
        }).catch(err => {
            console.log(err);
        })
    }

    handlePageClick = async data => {
        await this.fetchData(null, data.selected);
    }

    handleSearchData = (keyword) => {
        this.setState({
            keyword: keyword
        })

        if (keyword === "") {
            this.fetchData(keyword, this.state.selectedData)
        } else {
            this.fetchData(keyword, 0);
        }
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.crmSynchronization}</h2>
                    </div>
                    <div className="col-6">
                        <p style={{textAlign: "right"}}
                            className="mt-4 mb-3">{strings.crmSynchronizationInfo}</p>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-3">*/}
                {/*        <SearchBar handleSearch={this.handleSearchData}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.crmDirectorFirstName}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.crmDirectorLastName}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.portalDirectorFirstName}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.portalDirectorLastName}</th>
                    <th className="tableHeading lastHeading" style={{width: '11%'}}> {strings.institution}</th>

                    </thead>
                    <tbody>
                    {this.state.crmEntries.map(this.renderCrmEntries)}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        );
    }

    renderCrmEntries = (crmEntry) => {
        return (
            <tr key={crmEntry.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{crmEntry.crmDirectorFirstName}</td>
                <td className="tableData">{crmEntry.crmDirectorLastName}</td>
                <td className="tableData">{crmEntry.portalDirectorFirstName}</td>
                <td className="tableData">{crmEntry.portalDirectorLastName}</td>
                <td className="tableData">{crmEntry.institution?.nameMk}</td>
            </tr>
        )
    }
}

export default CrmEntry;