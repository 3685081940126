import React, {useEffect, useState} from "react";
import Select from "react-select";
import NapAreaRepository from "../../../repository/NapAreaRepository";
import StrategyGoalRepository from "../../../repository/StrategyGoalRepository";
import {strings} from "../../../Localization/Localization";
import NAPRepository from "../../../repository/NAPRepository";

const ProblemEdit = (props) => {

    const [listNapAreas, setListNapAreas] = useState([])
    const [proposedNapAreas, setProposedNapAreas] = useState([])
    const [proposedStrategyGoals, setProposedStrategyGoals] = useState([])

    const [listStrategyGoals, setListStrategyGoals] = useState([])
    const [selectedStrategyGoal, setSelectedStrategyGoal] = useState([]);

    const [selectedNapArea, setSelectedNapArea] = useState(null)
    const [selectedProposedNapArea, setSelectedProposedNapArea] = useState(null)

    const [naps, setNaps] = useState([])
    const [selectedNap, setSelectedNap] = useState([])

    const [chosenStrategyGoals, setChosenStrategyGoals] = useState([]);
    const [chosenProposedStrategyGoals, setChosenProposedStrategyGoals] = useState([]);
    const [isProposedNap, setIsProposedNap] = useState(false);


    const [validation] = useState([])

    const getNapAreas = () => {
        NapAreaRepository.findAllActiveNapAreas().then(res => {
            res.data.map(item => {
                listNapAreas.push({value: item.id, label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl, name: "napArea"})
            })
        })
    }

    const getStrategyGoals = () => {
        StrategyGoalRepository.getAllStrategyGoalsList().then( res => {
            res.data.map(item => {
                listStrategyGoals.push({value: item.id, label: item.nameMk, name:"strategyGoal"})
            })
        })
    }

    const getNaps = () => {
        NAPRepository.getAllNAPsList().then(res => {
            res.data.map(item => {
                naps.push({value: item.id, label: item.nameMk, name:"napId"})
            })
        })
    }

    const onChangeHandler = (e) => {
        const target = e.target;

        const onChangeHandler = (e) => {
            const target = e.target;

            if (target.type === 'checkbox') {
                // onCheckHandler();
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                if(name === 'isProposedNap')
                    setIsProposedNap(value)

                props.onChange(name, value);
            }

            const value = target.value;
            const name = target.name;

            if(validation[name] !== undefined){
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        };

        const value = target.value;
        const name = target.name;

        if(validation[name] !== undefined){
            validation[name].error = value === null || value === '';
        }

        props.onChange(name, value);
    };

    const onSelectedNapAreaChangeHandler = (napArea) => {
        if(napArea !== null && napArea.type !== "blur"){
            props.onChange(napArea.name, napArea.value)
            validation["napArea"].error = false;
            setSelectedNapArea(napArea.value)
        }
        else{
            if (napArea === null || selectedNapArea === null) {
                props.onChange("napArea", null)
                validation["napArea"].error = true;
                setSelectedNapArea(null);
            }
        }
    }

    const onSelectedProposedNapAreaChangeHandler = (proposedNapArea) => {
        if(proposedNapArea !== null && proposedNapArea.type !== "blur"){
            props.onChange(proposedNapArea.name, proposedNapArea.value)
            setSelectedProposedNapArea(proposedNapArea.value)
        }
        else{
            if (proposedNapArea === null || selectedProposedNapArea === null) {
                props.onChange("proposedNapArea", null)
                setSelectedProposedNapArea(null);
            }
        }
    }

    const onSelectedNapChangeHandler = (nap) => {
        if(nap !== null && nap.type !== "blur"){
            props.onChange(nap.name, nap.value)
            setSelectedNap(nap.value)
        }
        else{
            if (nap === null || selectedNap === null) {
                props.onChange("nap", null)
                setSelectedNap(null);
            }
        }
    }

    const handleStrategyGoalChange = (strategyGoals) => {
        if(strategyGoals != null){
            props.onChange("strategyGoals", Array.isArray(strategyGoals) ? strategyGoals.map(x => x.value || x.id) : [])
            validation["strategyGoals"].error = false;
        }
        else{
            props.onChange("strategyGoals", []);
            validation["strategyGoals"].error = true;
        }
        setSelectedStrategyGoal(Array.isArray(strategyGoals) ? strategyGoals.map(x => x.value || x.id) : []);
    }

    const handleProposedStrategyGoalChange = (strategyGoals) => {
        if(strategyGoals != null){
            props.onChange("proposedStrategyGoals", Array.isArray(strategyGoals) ? strategyGoals.map(x => x.value || x.id) : [])
        }
        else{
            props.onChange("proposedStrategyGoals", []);
        }
        setSelectedStrategyGoal(Array.isArray(strategyGoals) ? strategyGoals.map(x => x.value || x.id) : []);
    }

    const setValidationErrors = () => {
        validation["nameMk"]=({error: false, name: 'nameMk', message: strings.fillProblemNameMk})
        validation["descriptionMk"]=({error: false, name: 'descriptionMk', message: strings.descMk})
        validation["napArea"]=({error: false, name: 'napArea', message: strings.fillNapArea})
        validation["strategyGoals"]=({error: false, name: 'strategyGoal', message: strings.atLeastOneStrategyGoal})
        validation["napId"]=({error: false, name: 'napId', message: strings.PLEASE_CHOOSE_NAP})
    }

    let getSelectedStrategyGoals = () => {
        props.fixedEntity.realStrategyGoals.map( (item) => {
            chosenStrategyGoals.push({value: item.id, label: item.nameMk, name: "strategyGoal"})
        })
    }

    let getSelectedProposedStrategyGoals = () => {
        props.fixedEntity.strategyGoals.map( (item) => {
            chosenProposedStrategyGoals.push({value: item.id, label: item.nameMk, name: "proposedStrategyGoal"})
        })
    }

    useEffect(() => {
        getNapAreas();
        setValidationErrors();
        getStrategyGoals();
        getNaps();
        getSelectedStrategyGoals();
        getSelectedProposedStrategyGoals();

        setIsProposedNap(props.entity.napArea ? true : false);

        onSelectedNapChangeHandler({
            value: props.entity.nap.id,
            label: props.entity.nap.nameMk,
            name: "nap"
        });
        onSelectedNapAreaChangeHandler({
            value: props.entity.realNapArea?.id,
            label: props.entity.realNapArea?.nameMk,
            name: "napArea"
        });
        onSelectedProposedNapAreaChangeHandler({
            value: props.fixedEntity.napArea?.id,
            label: props.fixedEntity.napArea?.nameMk,
            name: "proposedNapArea"
        });

        setProposedNapAreas(props.fields?.proposedNapAreas?.map(el => {
            return {value: el.id, label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl, name: "proposedNapArea"}
        }))
        setProposedStrategyGoals(props.fields?.proposedStrategyGoals?.map(el => {
            return {value: el.id, label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl, name: "proposedStrategyGoal"}
        }))

        handleStrategyGoalChange(props.fixedEntity.realStrategyGoals);
        //handleProposedStrategyGoalChange(props.fixedEntity.strategyGoals);
    }, []);

    return(
        <div className="container">
            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.PLEASE_CHOOSE_NAP}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={naps}
                    onBlur={onSelectedNapChangeHandler}
                    onChange={onSelectedNapChangeHandler}
                    name={"nap"}
                    defaultValue={props.entity.nap !== null && props.entity.nap !== undefined ? {
                        value: props.entity.nap.id,
                        label: props.entity.nap.nameMk,
                        name: "nap"
                    } : ""}
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.problemNameMk}
                </label>
                <input
                    required={true}
                    defaultValue={props.entity.nameMk ? props.entity.nameMk : ""}
                    placeholder={""}
                    name={"nameMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                />
                <p>Ве молиме пред името внесете го&nbsp;
                    <span style={{textDecoration: 'underline'}}>следниот реден број на проблемот</span>  и&nbsp;
                    <span style={{textDecoration: 'underline'}}>точка</span>.
                    Пример: <span style={{textDecoration: 'underline'}}>2.</span> каде што 2 е следниот реден број на проблемот.
                </p>
                <small style={{color: "red"}}>{validation["nameMk"] && validation["nameMk"].error && validation["nameMk"].message}</small>
            </div>

            <div className="col-12 mt-2">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.problemNameAl}
                </label>

                <input
                    placeholder={""}
                    defaultValue={props.entity.nameAl ? props.entity.nameAl : ""}
                    name={"nameAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.descriptionOfProblemMk}
                </label>

                <textarea
                    placeholder={""}
                    defaultValue={props.entity.descriptionMk ? props.entity.descriptionMk : ""}
                    name={"descriptionMk"}
                    rows={6}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
                <small style={{color: "red"}}>{validation["descriptionMk"] && validation["descriptionMk"].error && validation["descriptionMk"].message}</small>
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.descriptionOfProblemAl}
                </label>

                <textarea
                    placeholder={""}
                    defaultValue={props.entity.descriptionAl ? props.entity.descriptionAl : ""}
                    name={"descriptionAl"}
                    rows={6}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
            </div>


            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedNap}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedNap"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedNap}
                />
            </div>

            {!isProposedNap && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.napArea}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={listNapAreas}
                    onBlur={onSelectedNapAreaChangeHandler}
                    onChange={onSelectedNapAreaChangeHandler}
                    name={"napArea"}
                    defaultValue={props.entity.realNapArea !== null && props.entity.realNapArea !== undefined ? {
                        value: props.entity.realNapArea.id,
                        label: props.entity.realNapArea.nameMk,
                        name: "napArea"
                    } : ""}
                />
                <small style={{color: "red"}}>{validation["napArea"] && validation["napArea"].error && validation["napArea"].message}</small>
            </div>}

            {isProposedNap && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.PROPOSALS_NAP_AREA}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedNapAreas}
                    // onBlur={onSelectedProposedNapAreaChangeHandler}
                    onChange={onSelectedProposedNapAreaChangeHandler}
                    name={"proposedNapArea"}
                    defaultValue={props.entity.napArea !== null && props.entity.napArea !== undefined ? {
                        value: props.entity.napArea.id,
                        label: props.entity.napArea.nameMk,
                        name: "proposedNapArea"
                    } : ""}
                />
            </div>
            }
            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.strategyGoals}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isMulti={true}
                    isSearchable={true}
                    defaultValue={chosenStrategyGoals}
                    options={listStrategyGoals}
                    onChange={handleStrategyGoalChange}
                    name={"strategyGoals"}
                />

                <small style={{color: "red"}}>{validation["strategyGoals"] && validation["strategyGoals"].error && validation["strategyGoals"].message}</small>
            </div>

        </div>
    )

}
export default ProblemEdit;