import React, {Component} from "react";
import {toast} from "react-toastify";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import {strings} from "../../Localization/Localization";
import {NavLink} from "react-router-dom";
import {faArrowLeft, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SERVER_ADDRESS} from "../../shared/server-address";
import FeedbackRepository from "../../repository/FeedbackRepository";

class VisualizeAnswers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            feedbackPublication: null,
            feedbackId: 0,
            options: [],
            feedbackTitle: null,
            proactivityId: null
        }
    }

    componentDidMount() {
        if(this.props.location.search != null)
        {
            let proactivitytId = this.props.location.search.split('=')[1];
            this.setState({ proactivityId: proactivitytId})
        }
        this.getAllFeedbackAnswers();

        let feedbackPubId = this.props.match.params.id;

        FeedbackPublicationRepository.getFeedbackPublicationById(feedbackPubId).then(async res => {
            this.setState({
                feedbackPublication: res.data
            })
            const feedbackId = res.data.feedback.id
            await FeedbackRepository.getFeedbackById(feedbackId).then(res => {
                this.setState({
                    feedbackTitle: res.data.name
                })
            })
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{color: "#1C4857"}}
                            className="mt-4 mb-4 text-center">{strings.previewAnswer}: {this.state.feedbackTitle ? this.state.feedbackTitle : ""}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-1"/>
                    <div className="col-10">
                        {this.state.answers.length > 0 ? this.state.answers.map(this.displayChoice)
                            : (<div style={{ backgroundColor: "white", padding: "2.5rem", border: "1px solid gray", borderRadius: "1.25rem",
                                boxShadow: ",1.875rem"}}>
                                <h3>{strings.answersAreReviewed}</h3> <br/>
                                <h3>{strings.checkYouEmailForNote}</h3>
                            </div>)}
                    </div>
                </div>


                <div className="row">
                    <div className="col-1"/>
                    <div className="col-10 mt-3 mb-3 text-right">

                        {
                            this.state.proactivityId ? ( <NavLink to={`/proactivityFilter/${this.state.proactivityId}`}>
                                <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                     style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                            </NavLink>) :   (this.state.feedbackPublication &&
                                this.state.feedbackPublication.feedback.isFeedback
                                    ?
                                    <NavLink to="/myProactivityPublications">
                                        <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                            <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                             style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                                    </NavLink>
                                    :
                                    <NavLink to="/myProactivityPublications">
                                        <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                            <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                             style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                                    </NavLink>
                            )
                        }



                    </div>
                </div>
            </div>
        )
    }


    displayChoice = (items, index) => {
        let finalOptions = []
        if (items.item.options) {
            let optionsQuestion = items.item.options.map((item, index) => {
                if (items.item.type === "SINGLE_CHOICE") {
                    let e;
                    if (items.value === item) {
                        e = <div key={index}>
                            <input name={items.item.id}
                                   style={{color: 'black', backgroundColor: 'black'}}
                                   className="visualizeFeedbackCheckInput"
                                   readOnly={true}
                                   value={items.value}
                                   checked={true}
                                   disabled={true}
                                   type={"radio"}/>
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)
                    } else {
                        e = <div key={index}>
                            <input name={items.item.id}
                                   style={{color: 'black', backgroundColor: 'black'}}
                                   readOnly={true}
                                   className="visualizeFeedbackCheckInput"
                                   value={items.value}
                                   disabled={true}
                                   type={"radio"}/>
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)
                    }
                    return e
                } else if (items.item.type === "MULTIPLE_CHOICE") {
                    let values = items.value.split(";"); // c i s
                    let e;
                    let flag;
                    flag = values.includes(item)
                    if (flag) {
                        e = <div className="d-inline-block col-12 pb-2" key={index}>
                            <input
                                className="visualizeFeedbackCheckInput align-top mt-1"
                                checked={true}
                                style={{color: 'black', backgroundColor: 'black'}}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)

                    } else {
                        e = <div className="d-inline-block col-12 pb-2" key={index}>
                            <input
                                className="visualizeFeedbackCheckInput align-top mt-1"
                                style={{color: 'black', backgroundColor: 'black'}}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)

                    }
                    flag = false;
                    return e
                }

            })
        }
        if (items.item.type === "NUMERIC_FIELD") {
            let y = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       type={"text"}/>
            </div>
            finalOptions.push(y)
        } else if (items.item.type === "TEXT_FIELD") {
            let x = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       type={"text"}/>
            </div>
            finalOptions.push(x)
        }
        else if (items.item.type === "NOTE") {
            let x = <div>
                    {items.item?.options === null ? "" : items.item?.options}

        </div>
            finalOptions.push(x)
        }
        else if(items.item.type === "ATTACHMENT"){
            let x = <div key={index}>
                <a style={{color: "blue", marginBottom: "4px"}} className="visualizeFeedbackCheckInput"
                   href={SERVER_ADDRESS + "rest/attachment/download/" + items.attachment.id}
                   target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFilePdf}
                                     color="red"
                                     size="lg"
                                     style={{paddingRight: '4px'}}
                    />
                    {items.value}
                </a>
            </div>
            finalOptions.push(x)
        }

        return (
            <div className="container-fluid visualizeFeedbackQuestionContainer" key={index}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{items.item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    <div className="col-12">
                        <h3>{items.item.description}</h3>
                    </div>
                    <div className="col-12 form-group kt-checkbox">
                        {finalOptions}
                    </div>
                </div>
            </div>
        )
    }

    getAllFeedbackAnswers = () => {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.getAnswers(feedbackPubId).then(res => {

            let level = res.data.filter(x => x.item.name.includes("НИВО НА ДЕЛУВАЊЕ НА ИНСТИТУЦИЈАТА"))
            let answersList = []
            for(let i=0; i< res.data.length; i++){
                if(res.data[i].value === ''){
                    answersList.push(res.data[i].item)
                }
            }
            answersList = answersList.map(x => x.id)
            let nameInst = res.data.filter(x => x.item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА"))
            let itemToFilter =  nameInst.filter(x => x.item.dependentAnswer === level[0].value)
            let list = res.data.filter(x => !answersList.includes(x.item.id))
            this.setState({
                answers: list.filter(x => x != itemToFilter[0]),
            });

        }).then(() => {
            this.state.answers.map(item => {
                this.setState(prevState => ({
                    options: [...prevState.options, item.item.options]
                }))
            })
        }).catch(() => {
            toast.error(strings.failedToLoadAnswers)
        })
    }

}

export default VisualizeAnswers;