import React, { Component, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "../../Localization/Localization";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class UserCheckModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedUsers: [],
            show: true
        }
    }


    updateAnswer = (id) => {
        this.setState(prevState => ({
            selectedUsers: [...prevState.selectedUsers, id]
        }))
        localStorage.setItem("firstLogin",false)
    }

    changeUsers = () => {
        this.props.onSubmit(this.state.selectedUsers)
        this.setState({
            show: false
        })
    }

    renderCheckbox = () => {
        const options = this.props.userData.map(item => (
            <div key={item.id}>
                <input 
                    className="visualizeFeedbackMulCheckInput"
                    onClick={() => this.updateAnswer(item.id)}
                    value={item.id}
                    type="checkbox"
                    checked={this.state.selectedUsers.includes(item.id)}
                />
                <label className="form-check-label ml-1">{item.firstName + " " + item.lastName}</label>
            </div>
        ))
        return options
    }

    setShow = (checkShow) => {
        this.setState({
            show: checkShow
        })
    }

    render() {
        return (
            <Modal contentClassName="area-modal" show={this.state.show} onHide={() => this.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.users}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Ве молиме одберете ги корисниците што треба да се отстранат:</h3>
                    <div className="col-8 form-group kt-checkbox mb-3 visualizeFeedbackQuestionBodyDiv">
                        {this.renderCheckbox()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setShow(false)}>{strings.cancel}</Button>
                    <Button className="btn btn-outline-warning" type="submit" onClick={this.changeUsers}> {strings.confirm}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default UserCheckModal;