import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "../../Localization/Localization";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class UsefulDocumentModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: null,
            description: "",
            show: false
        }
    }

    onDescriptionChange = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    setShow = (toShow) => {
        this.setState({ show: toShow })
    }

    uploadFile = () => {
        this.props.onSubmit(this.state.file,this.state.description)
        this.setState({
            show: false,
            file: null,
            description: "",
        })
    }

    render() {
        return (
            <Aux>
                <Button
                    onClick={() => this.setShow(true)}
                    style={{ float: 'right', borderRadius: "10px", marginLeft: "auto" }}
                    className="pendingButton w-20"
                >
                    <FontAwesomeIcon icon={faChartArea} />
                    {strings.upload}
                </Button>
                <Modal contentClassName="document-modal" show={this.state.show} onHide={() => this.setShow(false)} size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>{strings.upload}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="file"
                                id="fileNomination"
                                name="fileNomination"
                                className='form-control-file mb-3'
                                onChange={this.onFileChange} />
                            <textarea rows={5} onChange={this.onDescriptionChange} className="w-100" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setShow(false)}>{strings.cancel}</Button>
                            <Button className="btn btn-outline-warning" type="submit" onClick={this.uploadFile}> {strings.upload}</Button>
                        </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default UsefulDocumentModal;