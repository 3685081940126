import React, { Component } from 'react';
import '../../assets/css/theme/base.css'
import Background from '../../assets/images/0-3788_simple-light-blue-background.jpg'
import { NavLink } from "react-router-dom";
import { strings } from "../../Localization/Localization";
import Select from "react-select";
import UserRepository from '../../repository/UserRepository';
import { toast } from 'react-toastify';
import ErrorPopup from '../ErrorHandler/ErrorPopup';
import ReCAPTCHA from "react-google-recaptcha";

const regValid = /^\w+([\.-]?\w)*@([a-zA-Z])+([\.]?[a-zA-Z]{2,})*$/;
const customStyle = {
    control: (base, state) => ({
        ...base,
        background: "rgb(241, 250, 255)",
        borderRadius: "10px",
        height: "calc(1.5em + 1.3rem + 2px)",
        padding: "0.65rem 1rem",
        fontSize: "16px",
        borderColor: "#e2e5ec",
        fontWeight: 400,
        lineHeight: 1.5,
        transition: "border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
        alignContent: "center",
        fontFamily: "sans-serif",
        textAlign: "center"
    }),
    placeholder: (base) => ({
        alignContent: "center"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        flexWrap: "noWrap",
    })
};
class RegisterUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            email: "",
            name: "",
            lastName: "",
            institution: null,
            areaOfInterest: null,
            institutions: [],
            areasOfInterest: [],
            fileNomination: null,
            errorMsgVisible: false,
            confirmPassword: "",
            embgToShow: false,
            embg: "",
            captchaRef: React.createRef()

        }
    }


    fetchData = () => {
        UserRepository.registrationData().then(resp => {
            let options = []
            resp.data.areaOfInterestShortDtoList.map(group => {
                if (group.name !== "Администратор")
                    options.push({ name: group.name, label: group.name, value: group.id })
            })
            this.setState({
                areasOfInterest: options,
                institutions: resp.data.institutionShortDtoList
            })
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    onSelectedAreasOfInterestChange = (e) => {
        let embgToShow = false;
        for (let i = 0; i < e.length; i++) {
            if (e[i].name === 'Регистар на именувани лица') {
                embgToShow = true
                break
            }
        }
        this.setState({ areaOfInterest: e, embgToShow: embgToShow })
    }


    register = (e) => {
        let obj = this.state;
        let msg = this.validateForm(obj);
        e.preventDefault();
        if (msg === null) {
            const form = new FormData();
            form.append("name", obj.name);
            form.append("lastName", obj.lastName);
            form.append("email", obj.email);
            form.append("password", obj.password);
            form.append("institutionIds", obj.institution);
            form.append("embg", obj.embg)
            if (obj.areaOfInterest !== null) {
                let areaIds = []
                obj.areaOfInterest.forEach(item => {
                    areaIds.push(item.value)
                })
                form.append("areasOfInterestIds", areaIds);
                form.append("nominations", obj.fileNomination);
            }
            UserRepository.createUser(form).then(() => {
                this.goBackToLogin('/login')
            }).then(() => toast.success(strings.successfullyRegisteredUser)).catch((error) => {
                toast.error(strings.failTryAgain)
            }).catch(err => {
                if(err.response.data.message === "Email not sent"){
                    toast.error(strings.failedToSendMail)
                }
                else toast.error(strings.error)
            });
        }
        else {
            toast.error(msg);
        }
    }

    validateForm = (obj) => {
        if (obj.name === "") {
            return strings.pleaseFillInFirstName
        }
        else if (obj.lastName === "") {
            return strings.pleaseFillInLastName
        }
        else if (obj.password !== obj.confirmPassword) {
            return strings.pleaseFillInThePassword
        }
        else if (!regValid.test(obj.email)) {
            return strings.pleaseEnterEmail
        }
        else if (obj.institution === null || obj.institution === 'Choose institution') {
            return strings.pleaseFillInInstitution
        }
        else if (obj.areaOfInterest === null) {
            return strings.pleaseFillInAtLeastOneAreaOfInterest
        }
        else if (obj.fileNomination === null) {
            return strings.pleaseAttachNomination
        }
        else if (obj.embg.length !== 0 && obj.embg.length !== 13) {
            return strings.pleaseEnterValidEmbg
        }
        return null;
    }

    onInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    goBackToLogin = (path) => {
        this.props.history.push(path);
    };

    onInstitutionChange = (e) => {
        this.setState({ institution: e?.value })
    }
    onNominationChange = (e) => {
        this.setState({ fileNomination: e.target.files[0] })
    }
    closeErrorDiv = () => {
        this.setState({ errorMsgVisible: false })
    }
    checkCaptcha = async () => {
        await this.state.captchaRef.current.executeAsync();
    }


    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                    id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="https://dksk.mk/" target="_blank" rel="noopener noreferrer">
                                                <img src={require(process.env.REACT_APP_LOGO)}
                                                    className={"img-responsive"} alt="" />
                                            </a>
                                        </div>
                                        <div className="kt-login__signin">
                                            <div className="kt-login__head">
                                                <h3 className="kt-login__title">{strings.registerUser}</h3>
                                                <div className="kt-login__desc">{strings.enterRegData}
                                                </div>
                                                <form id="registerForm" className='kt-form' onSubmit={this.register}>
                                                    <div className='form-group'>
                                                        <input type="text" className="form-control registerText" name="name" placeholder={strings.name} onChange={this.onInputChange}></input>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input type="text" className="form-control registerText" name="lastName" placeholder={strings.lastName} onChange={this.onInputChange}></input>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input type="text" className="form-control registerText" name="email" placeholder={strings.email} onChange={this.onInputChange}></input>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input type="password" className="form-control registerText" name="password" placeholder={strings.password} onChange={this.onInputChange}></input>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input type="password" className="form-control registerText" name="confirmPassword" placeholder={strings.repeatPassword} onChange={this.onInputChange}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <Select
                                                            placeholder={strings.institutionPlaceholder}
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isRtl={false}
                                                            isSearchable={true}
                                                            styles={customStyle}
                                                            options={this.state.institutions.map(item => {
                                                                return { value: item.id, name: item.id, label: item.name }
                                                            })}
                                                            name="institutiodId"
                                                            id="institutiodId"
                                                            onChange={this.onInstitutionChange}
                                                        />
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            placeholder={strings.areaOfInterestPlaceholder}
                                                            isMulti
                                                            styles={customStyle}
                                                            options={this.state.areasOfInterest}
                                                            onChange={this.onSelectedAreasOfInterestChange}
                                                        ></Select>
                                                    </div>
                                                    {this.state.embgToShow &&
                                                        <div className='form-group'>
                                                            <input type="text" maxLength="13" className="form-control registerText" name="embg" placeholder={strings.embg} onChange={this.onInputChange}></input>
                                                        </div>
                                                    }
                                                    <div
                                                        className='form-group col-12 w-100 h-50 shadow-sm p-3 mb-3 bg-light rounded'>
                                                        <label>Номинација:</label>
                                                        <input type="file"
                                                            id="fileNomination"
                                                            name="fileNomination"
                                                            className='form-control-file registerText bg-light'
                                                            onChange={this.onNominationChange} />
                                                    </div>
                                                    <div className="kt-login__actions">
                                                        <button id="kt_login_signin_submit"
                                                            className="btnLogin btn btn-brand btn-pill btn-elevate w-30"
                                                            type="submit" form="registerForm">{strings.request}
                                                        </button>
                                                        <NavLink id="kt_login_forgot_cancel"
                                                            className="btn btn-brand btn-outline-danger btn-pill btn-elevate btnClose w-30"
                                                            to={'/login'}>
                                                            <p className="mt-1">{strings.close}</p></NavLink>
                                                    </div>
                                                    <div className='container-fluid'>
                                                    <div style={{ paddingTop: "10px", cursor:"pointer" }} onClick={() => this.checkCaptcha()} className="btnLogin btn btn-brand btn-pill btn-elevate">
                                                            <input id="captcha" name='captcha' type='checkbox' className='mr-1' required
                                                                onClick={() => this.checkCaptcha()} />
                                                            <label htmlFor='captcha' style={{ fontWeight: "400" }}>{strings.captcha}</label>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.errorMsgVisible && <ErrorPopup errorMessage={strings.invalidEmailOrPassword}
                                closeErrDiv={this.closeErrorDiv} />}
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{ backgroundImage: "url(" + Background + ")" }}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <div className="registrationTextDiv">
                                            <h4 className="redText">{strings.mision}</h4>
                                            <p className="registrationText">
                                                {strings.misionText}
                                            </p>
                                            <br />
                                            <h4 className="redText">{strings.vision}</h4>
                                            <p className="registrationText">
                                                {strings.visionText}
                                            </p>
                                            <br />
                                            <a className="faqLink" href="/FAQ">{strings.faq}</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReCAPTCHA
                    ref={this.state.captchaRef}
                    sitekey="6LdrUrElAAAAAFK8QTPhQH_oYkwph8KhT0oRVVmg"
                    size="invisible"
                />
            </div>

        );
    }
}

export default RegisterUser;
