import React, { Component } from "react";
import FeedbackItemRepository from './../../repository/FeedbackItemRepository';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import CrudModal from "../Crud/CrudModal";
import DeleteModal from "../Crud/DeleteModal";
import { strings } from "../../Localization/Localization";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faShare } from "@fortawesome/free-solid-svg-icons";
import FeedbackRepository from "../../repository/FeedbackRepository";
import EditFeedbackItemNew from "../Feedback/EditFeedbackItemNew";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./css/designProactiveTransparency.css"


class DesignProactiveTransparency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedbackItems: [],
            pageCount: 1,
            feedbackName: '',
            totalElements: 0
        }
    }

    componentDidMount() {
        this.fetchData();
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    handleOnDragEnd = (result) => {
        const items = Array.from(this.state.feedbackItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let tmp = [];
        items.forEach(item => {
            tmp.push(item.id)
        })
        FeedbackItemRepository.changeQuestionOrder(tmp.toString(),parseInt(this.props.match.params.id));
        this.setState({
            feedbackItems: items
        })
    }

    highlightDependent = (item) => {
        if (item.dependentFeedbackItem?.id) {
            document.getElementById(item.dependentFeedbackItem.id).style.background = "#67c4ef"
        }
        else {
            this.state.feedbackItems.forEach(depItem => {
                if (depItem.dependentFeedbackItem?.id === item.id) {
                    document.getElementById(depItem.id).style.background = "#67c4ef"
                }
            })
        }
    }

    removeHighlight = (item) => {
        if (item.dependentFeedbackItem?.id) {
            document.getElementById(item.dependentFeedbackItem.id).style.background = "#BCDFEF"
        }
        else {
            this.state.feedbackItems.forEach(depItem => {
                if (depItem.dependentFeedbackItem?.id === item.id) {
                    document.getElementById(depItem.id).style.background = "#BCDFEF"
                }
            })
        }
    }

    renderDraggables = (item, index) => {
        return (
            <Draggable key={item.id} draggableId={'' + item.id} index={index}>
                {provided => (
                    <li ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className="row mt-2 mb-2 ml-2">
                            <div id={item.id} className=" pt-3 pb-3" style={{ background: "#BCDFEF", borderRadius: "5px", display: "inline-block", textAlign: "center", width: "100%" }}>
                                <div className="designFeedbackItem mr-1" style={{ width: "25%" }}>{item.name}</div>
                                <div className="designFeedbackItem mr-1" style={{ width: "15%" }}>{item.dateCreated}</div>
                                <div className="designFeedbackItem mr-1" style={{ width: "15%" }}>{item.type}</div>
                                <div onMouseEnter={() => this.highlightDependent(item)} onMouseLeave={() => this.removeHighlight(item)} className="designFeedbackItem mr-1" style={{ width: "25%" }}>{item.dependentFeedbackItem?.name ? item.dependentFeedbackItem?.name : strings.independent}</div>
                                <div className="d-inline-block ml-3" style={{ width: "15%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <ButtonGroup>
                                            <CrudModal entity={item}
                                                icon="edit"
                                                btnClass="defaultBtnEdit btn btn-success btn-sm ml-3"
                                                title={strings.edit}
                                                onSubmit={this.onEditFeedbackItem}
                                                body={EditFeedbackItemNew}
                                                showText={true} />
                                            <DeleteModal btnClass={"defaultBtn btn ml-3"}
                                                showText={strings.remove}
                                                title="delete"
                                                prompt={strings.deleteFeedbackQuestion}
                                                doDelete={() => this.deleteFeedbackItem(item.id)} />
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                )}
            </Draggable>
        )
    }

    render() {
        return (
            <div>
                <h2 style={{ textAlign: "left", color: "#1C4857" }}
                    className="mt-4">{strings.designProactiveItem}: {this.state.feedbackName ? "„" + this.state.feedbackName + "“" : ""}</h2>
                <div style={{ display: "flex" }} className="mt-5">

                    <CrudModal
                        entity={{}}
                        icon="add"
                        showText={true}
                        btnClass="defaultBtnAdd btn btn-primary mb-2 ml-5"
                        title={strings.addNewEntry}
                        onSubmit={this.onAddFeedbackItem}
                        body={EditFeedbackItemNew} />

                    <ButtonToolbar style={{ marginLeft: "auto" }}>
                        <Link to={"/proactivity"}
                            onClick={() => localStorage.setItem("currentPage", 0)}
                            className="defaultBtn btn btn-dark mb-2 btn btn-dark mr-1"><FontAwesomeIcon
                                icon={faArrowLeft}
                                size="lg"
                                style={{ paddingRight: '4px' }} />{strings.goBack}
                        </Link>
                        <Link to={"/previewTransparency/" + this.props.match.params.id}
                            className="defaultBtn btn btn-default mb-2 btn btn-default mr-1"
                            style={{ backgroundColor: "#ff8b3d" }}><FontAwesomeIcon icon={faEye} size="lg"
                                style={{ paddingRight: '4px' }} />{strings.preview}
                        </Link>
                        <Link to={"/publishProactivity/" + this.props.match.params.id}
                            className="defaultBtn btn btn-info mb-2 btn btn-info"><FontAwesomeIcon icon={faShare}
                                size="lg"
                                style={{ paddingRight: '4px' }} />{strings.publish}
                        </Link>
                    </ButtonToolbar>
                </div>

                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                    <Droppable droppableId="listfeedbackItems">
                        {provided => (
                            <>
                                <div className="row mt-2 mb-2 ml-2">
                                    <div className="headerColumn pt-3 pb-3" style={{width:"100%", textAlign:"center", marginLeft: "2.5rem"}}>
                                        <div className="headerCell" style={{  width: "25%",  marginLeft: "1rem" }}>{strings.nameQuestion}</div>
                                        <div className="headerCell" style={{  width: "15%" }}>{strings.dateCreated}</div>
                                        <div className="headerCell" style={{  width: "15%" }}>{strings.type}</div>
                                        <div className="headerCell" style={{  width: "25%" }}>{strings.dependentFeedbackItem}</div>
                                        <div className="headerCell" style={{  width: "15%", marginLeft:"1.5rem" }}>{strings.options}</div>
                                    </div>
                                </div>
                                <ol className="categories" {...provided.droppableProps} ref={provided.innerRef}>
                                    {this.state.feedbackItems.map((item, index) => this.renderDraggables(item, index))}
                                </ol>
                            </>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    fetchData = () => {
        let selectedPage = localStorage.getItem("currentPage");
        let feedbackId = this.props.match.params.id;
        FeedbackRepository.getFeedbackById(feedbackId).then(res => {
            this.setState({
                feedbackName: res.data.name
            })
        })
        FeedbackItemRepository.getAllFeedbackItemsRest(feedbackId).then(res => {
            this.setState({
                feedbackItems: res.data
            })
        }).catch(() => toast.error(strings.failedToLoadData));
    }

    handlePageClick = (e) => {
        localStorage.setItem("currentPage", e.selected)
        this.fetchData();
    }

    onAddFeedbackItem = (entity) => {
        let feedbackId = this.props.match.params.id;
        const obj = {}
        obj.name = entity.name
        // obj.description = entity.description;
        obj.required = entity.required;
        obj.type = entity.type;
        obj.options = []
        obj.values = []
        obj.dependentFeedbackItem = {
            "id": entity.dependentFeedbackItemId,
        }
        obj.dependentAnswer = entity.dependentAnswer;
        obj.minValue = entity.minValue
        obj.maxValue = entity.maxValue
        // if (entity.options) {
        //     let data = entity.options;
        //     obj.options = data.split('\n');
        // }
        let i = 0;
        for (var e in entity) {
            if (e === `option${i}`) {
                obj.options.push(entity[`option${i}`])
                obj.values.push(entity[`v${i}`])
                i++;
            }
            else if (e === "note") {
                obj.options.push(entity["note"])
                obj.values.push(entity["note"])
            }
        }
        return FeedbackItemRepository.addFeedbackItem(feedbackId, obj).then(async () => {
            toast.success(strings.successfullyAddedFeedbackItem)
            await this.sleep(2000);
            window.location.reload();
        }).catch(async () => {
            toast.error(strings.failedToAddFeedbackItem);
            await this.sleep(2000);
            window.location.reload();
        });
    }

    onEditFeedbackItem = (entity) => {
        if (typeof entity.options !== undefined) {
            entity.options = []
            entity.values = []
            // for (let i = 0; i < entity["options"]; ++i) {
            //     if (entity[`option${i}`]) {
            //         entity.options.push(entity[`option${i}`])
            //     }
            //     if (entity[`v${i}`]) {
            //         entity.values.push(entity[`v${i}`])
            //     }
            // }
            var tmpInt = 0
            while (true) {
                if (entity.note) {
                    entity.options.push(entity["note"])
                    entity.values.push(entity["note"])
                    break;
                }
                else if (entity[`option${tmpInt}`]) {
                    entity.options.push(entity[`option${tmpInt}`])
                    if (entity[`v${tmpInt}`]) {
                        entity.values.push(entity[`v${tmpInt}`])
                    }
                    tmpInt++;
                }
                else break;
            }
        }
        if (entity.type === "NUMERIC_FIELD" || entity.type === "TEXT_FIELD" || entity.type === "ATTACHMENT") {
            entity.options = []
            entity.values = []
        }
        if (isNaN(entity.dependentFeedbackItemId)) {
            entity.dependentFeedbackItemId = 0
        }
        if(entity.dependentFeedbackItemId === 0 && entity.dependentFeedbackItem !== null){
            entity.dependentFeedbackItem = null
            entity.dependentAnswer = null
        }
        delete entity.feedback.enabled;
        delete entity.feedback.creator;
        const dependentFeedbackItemId = entity.dependentFeedbackItemId;
        delete entity.dependentFeedbackItemId;
        if(tmpInt > 0) {
            tmpInt = tmpInt-1;
        }
        while(tmpInt >= 0) {
            delete entity[`option${tmpInt}`]
            delete entity[`v${tmpInt}`]
            tmpInt--;
        }
        //delete entity.option
        return FeedbackItemRepository.updateFeedbackItem(entity, dependentFeedbackItemId).then(async () => {
            toast.success(strings.successfullyEditedFeedbackItem);
            await this.sleep(2000);
        }).catch(async () => {
            toast.error(strings.failedToEditFeedbackItem);
            await this.sleep(20000);
        })
    }

    deleteFeedbackItem = (id) => {
        return FeedbackItemRepository.deleteFeedbackItem(id).then(() => {
            toast.success(strings.successfullyDeletedFeedbackItem);
            this.fetchData();
        }).catch(() => {
            toast.error(strings.failedToDeleteFeedbackItem);
        })
    }

}

export default DesignProactiveTransparency;

