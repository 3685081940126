import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {strings} from "../../Localization/Localization";

const ConfirmModal = props => {

    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const [show, setShow] = useState(false);

    const handleDelete = () => {
        props.onSubmit()
        setShow(false);
    }

    return (
        <Aux>
            <Button className="btn btn-primary" type="submit" onClick={() => setShow(true)}>
                {strings.submit}
            </Button>
            <Modal contentClassName="confirm-modal" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.warning ? strings.warning : strings.remove}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                        {props.prompt}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>{strings.cancel}</Button>
                    <Button className="btn btn-outline-warning" type="submit" onClick={handleDelete}>{strings.confirm}</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default ConfirmModal;
