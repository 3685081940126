import axios from '../axios/app';

const IndicatorReportRealRepository = {

    getAllIndicatorReportRealByIndicator: (id) => {
        return axios.get(`/rest/indicator-report-real/all-by-indicator/${id}`);
    },

    getAllIndicatorReportsRealStatuses: () => {
        return axios.get(`/rest/indicator-report-real/all-statuses`);
    },

    changeIndicatorReportRealStatus:(statusId, id) => {
        return axios.post(`/rest/indicator-report-real/change-status/${id}?statusId=${statusId}`);
    },

    updateIndicatorReportReal: (counter, finished, id) => {
        return axios.post(`/rest/indicator-report-real/update/${id}?counter=${counter}&finished=${finished}`);
    },

    getAllMyIndicatorReportsReal: (statusId) =>{
        return axios.get(`/rest/indicator-report-real/all-my-indicators/${statusId}`);
    },

    updateReadStatus: id => {
        return axios.put(`/rest/indicator-report-real/update-read/${id}`);
    },

    getUnreadIndicatorsByUser: () => {
        return axios.get('/rest/indicator-report-real/unread-indicators');
    },

    indicatorReturnedForCompletion: (id, message) => {
        return axios.post(`/rest/indicator-report-real/indicator-returned/${id}?message=${message}`)
    },

    getStatusReports: id => {
        return axios.get(`/rest/indicator-report-real/get-status-reports/${id}`)
    }

}

export default IndicatorReportRealRepository;