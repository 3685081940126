import React from "react";

export default function TutorialVideo() {

    return (
        <div className="container-fluid" style={{height: "100%"}}>
            <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-3">
                Упатство
            </h2>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FBal6gS9snY" title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </div>
    )

}