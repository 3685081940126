import React, { Component } from "react";
import { toast } from "react-toastify";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import { strings } from "../../Localization/Localization";
import { NavLink } from "react-router-dom";
import { faArrowLeft, faExclamation, faFilePdf, faPaperPlane, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeedbackRepository from "../../repository/FeedbackRepository";
import FeedbackItemRepository from "../../repository/FeedbackItemRepository";
import './css/DesignPageAnswerAndSend.css'
import { SERVER_ADDRESS } from "../../shared/server-address";
import {Spinner} from "reactstrap";

class PreviewAnsweredAndSendReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            answersAdmin: [],
            feedbackPublicationUser: {},
            feedbackId: 0,
            options: [],
            bfOptions: [],
            optionsAdmin: [],
            feedbackTitle: null,
            feedItems: [],
            institutionName: "",
            textFromAdmin: "",
            fileFromAdmin: null,
            file: null,
            files: [],
            allAnswers: [],
            errors: {},
            spinner: false,
        }
    }

    componentDidMount() {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.getFeedbackPublicationById(feedbackPubId).then(async res => {
            const feedbackId = res.data.feedback.id;
            this.setState({
                institutionName: res.data.institution.nameMk,
                feedbackId: feedbackId,
                feedbackPublicationUser: res.data
            })
            await FeedbackRepository.getFeedbackById(feedbackId).then(res => {
                this.setState({
                    feedbackTitle: res.data.name,
                })
            })
                this.getAdminFeedbackAnswers(feedbackId);
                this.getAllFeedbackAnswers(feedbackPubId);
                this.getFeedbackItemsByFeedbackId(feedbackId); 
        })

    }

    validate = () => {
        return true;

       /* let errors = {}
        let subbmitable = true;

        for (let i = 0; i < this.state.feedItems.length; i++) {
            if(this.state.feedItems[i].type !== "ATTACHMENT" && this.state.feedItems[i].type !== "NOTE"){
                const item = this.state.feedItems[i]
                const answer = this.state.answersAdmin.find(x => x.id == item.id)
                var dependentAnswer = null
                if (item.dependentFeedbackItem !== null) {
                    dependentAnswer = this.state.answersAdmin.find(x => x.id === item.dependentFeedbackItem.id)
                }
                if (item.required === true &&
                    (answer?.value === undefined || answer?.value === null ||
                        (answer?.value.length === 1 && answer.value[0] === '') || answer?.value == '')) {
                    if (dependentAnswer?.value == item.dependentAnswer) {
                        errors[item.id] = true
                        subbmitable = false
                    }
                    else {
                        errors[item.id] = false
                    }
                } 
                else {
                    errors[item.id] = false
                }
        }
            this.setState({
                errors: errors
            })
        }

        return !subbmitable;*/


    }

    checkIfNumber= (event,type) =>{

        if(type == "number"){
        const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
        return !event.key.match(regex) && event.preventDefault();
        }
     }

    getAdminFeedbackAnswers = async (id) => {
        this.setState({spinner: true})
        FeedbackPublicationRepository.findByFeedbackId(id).then(async ans => {
            for (let tmp of ans.data) {
                if (tmp.initialPublication !== null && this.state.feedbackPublicationUser.institution.id === tmp.initialPublication.institution.id) {
                    FeedbackPublicationRepository.getAnswers(tmp.id).then(async res => {
                        let ansObj = []
                        let ansObjPerm = []
                        res.data.forEach(tmpAns => {
                            if (tmpAns.item.type === "ATTACHMENT") {
                                let tmpObj = null
                                ansObj.forEach(item => {
                                    if (item.id == tmpAns.item.id) {
                                        tmpObj = item
                                    }
                                })
                                if (tmpObj == null){
                                    ansObj.push({ id: tmpAns.item.id, value: tmpAns.value!="" ? tmpAns.attachment.id+":"+tmpAns.value : "" })
                                    ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value!="" ? tmpAns.attachment.id+":"+tmpAns.value : "" })
                            }
                            }
                            else if (tmpAns.item.type === "MULTIPLE_CHOICE") {
                                if (tmpAns.value != null) {
                                    ansObj.push({ id: tmpAns.item.id, value: tmpAns.value.split(";") })
                                    ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value.split(";") })
            
                                    tmpAns.value.split(";").forEach(elem => {
                                        if (document.getElementById(elem) != null) {
                                            document.getElementById(elem).checked = "true"
                                        }
            
                                    })
                                }
                            }
                            else {
                                ansObj.push({ id: tmpAns.item.id, value: tmpAns.value })
                                ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value })
                            }
                        })
                        this.setState({
                            allAnswers: ansObj,
                            answersAdmin: ansObjPerm,
                            bfOptions: res.data
                        });

                    }).then(() => {
                        this.state.bfOptions.map(item => {
                            this.setState(prevState => ({
                                optionsAdmin: [...prevState.options, item.item.options]
                            }))
                        })
                        this.setState({spinner: false})
                    }).catch(() => {
                        this.setState({spinner: false})
                        toast.error(strings.failedToLoadAnswers)
                    })
                    break
                }
            }
        })
    }

    submitAnswers = (isFinished) => {
        const formData = new FormData();
        formData.append("textFromAdmin", this.state.textFromAdmin)
        formData.append("attachmentFromAdmin", this.state.fileFromAdmin)
        const data = {}
        for (var item of this.state.answersAdmin) {
            if (Array.isArray(item.value)) {
                data[item.id] = item.value.join(";");
            } else {
                data[item.id] = item.value;
            }
        }
        FeedbackPublicationRepository.submitAnswersFromAdmin(this.state.feedbackPublicationUser.id, isFinished, data)
        .then(() => {
            FeedbackPublicationRepository.sendEmailFromAdmin(this.state.feedbackPublicationUser.id, isFinished, formData)
        }).then(r => {
            toast.success(strings.successfullySavedAnsweres)
            this.props.history.push(`/proactiveFinished/${this.state.feedbackId}`)
        })
    }

    onTextChangeHandler = (e) => {
        this.setState({
            textFromAdmin: e.target.value
        })
    }

    updateFileFromAdmin = (e) => {
        this.setState({
            fileFromAdmin: e.target.files[0]
        })
    }

    render() {

        const questions = this.state.feedItems.map(item => {
            let display = null;
            let answers = []
            if(this.state.answersAdmin.length != 0){
                answers = this.state.answersAdmin
            }
            else{
                answers = this.state.answers
            }
            answers.forEach((key) => {
                if (key.id == item.dependentFeedbackItem?.id) {
                    if (key.value === item.dependentAnswer ||
                        key.value[0] === item.dependentAnswer ||
                        key.value[1] === item.dependentAnswer ||
                        key.value[2] === item.dependentAnswer ||
                        key.value[3] === item.dependentAnswer ||
                        key.value[4] === item.dependentAnswer ||
                        key.value[5] === item.dependentAnswer) {
                        item.showItem = true;

                        if (item.type === "SINGLE_CHOICE") {
                            display = this.singleChoice(item);
                        } else if (item.type === "MULTIPLE_CHOICE") {
                            display = this.multipleChoice(item);
                        } else if (item.type === "NUMERIC_FIELD") {
                            display = this.numericField(item);
                        } else if (item.type === "TEXT_FIELD") {
                            display = this.textField(item);
                        } else if (item.type === "ATTACHMENT") {
                            display = this.textField(item);
                        }
                        else if (item.type === "NOTE") {
                            display = this.noteField(item);
                        }
                        else if (item.type === "DATE"){
                            display = this.dateField(item)
                        }
                    }
                    else {
                        item.showItem=false
                        if(item.dependentAnswer.includes(";")){
                            let arrDependentAnswers = item.dependentAnswer.split(";;")
                            arrDependentAnswers.forEach(arrAnswer => {
                                if (arrAnswer != '' && 
                                    (key.value?.includes(arrAnswer) ||
                                    key.value[0]?.includes(arrAnswer) ||
                                    key.value[1]?.includes(arrAnswer) ||
                                    key.value[2]?.includes(arrAnswer) ||
                                    key.value[3]?.includes(arrAnswer) ||
                                    key.value[4]?.includes(arrAnswer) ||
                                    key.value[5]?.includes(arrAnswer))) {
                                    item.showItem = true;
                                    if (item.type === "SINGLE_CHOICE") {
                                        display = this.singleChoice(item);
                                    } else if (item.type === "MULTIPLE_CHOICE") {
                                        display = this.multipleChoice(item);
                                    } else if (item.type === "NUMERIC_FIELD") {
                                        display = this.numericField(item);
                                    } else if (item.type === "TEXT_FIELD") {
                                        display = this.textField(item);
                                    } else if (item.type === "ATTACHMENT") {
                                        display = this.textField(item);
                                    }
                                    else if (item.type === "NOTE") {
                                        display = this.noteField(item);
                                    }
                                    else if (item.type === "DATE"){
                                        display = this.dateField(item)
                                    }
                                }
                            })
                        }
                        if(!item.showItem)
                        {
                            let index = answers.findIndex(x => x.id == key.id)
                            delete answers[key.id];
                        }
                    }
                }
            })
            if (item.type === "SINGLE_CHOICE") {
                display = this.singleChoice(item);
            } else if (item.type === "MULTIPLE_CHOICE") {
                display = this.multipleChoice(item);
            } else if (item.type === "NUMERIC_FIELD") {
                display = this.numericField(item);
            } else if (item.type === "TEXT_FIELD") {
                display = this.textField(item);
            } else if (item.type === "ATTACHMENT") {
                display = this.attachmentField(item);
            }
            else if (item.type === "NOTE") {
                display = this.noteField(item);
            }
            else if (item.type === "DATE"){
                display = this.dateField(item)
            }

            return display;
        });
        if(this.state.spinner){
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary"/>
            </div>
        }
        else return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{ color: "#1C4857" }}
                            className="mt-4 mb-4 text-center">{strings.previewAnswer}: {this.state.feedbackTitle ? this.state.feedbackTitle : ""}</h2>
                        <h2 style={{ color: "#1C4857" }}
                            className="mt-4 mb-4 text-center">{this.state.institutionName ? this.state.institutionName : ""}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-5">
                        {this.state.answers.map(this.displayChoice)}
                    </div>
                    <div className="col-2"
                        style={{
                            borderLeft: '3px solid black',
                            position: 'sticky',
                            left: '50%'
                        }}
                    />

                    <div className="col-5">
                        {questions}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6" />
                    <div className="col-6 text-right">
                        <textarea
                            id="textFromAdmin"
                            required
                            rows="6"
                            placeholder="Внесете порака за корисникот"
                            name="textFromAdmin"
                            style={{ border: '1px solid gray', borderRadius: '20px' }}
                            onChange={this.onTextChangeHandler}
                            className="form-control mt-3 mb-3"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" />
                    <div className="form-group col-6 w-100 shadow-sm p-3 mb-5 bg-light rounded">
                        <label>Одговор за корисник:</label>
                        <input name="fileFromAdmin"
                               className="form-control-file registerText bg-light"
                               onChange={this.updateFileFromAdmin}
                               value={this.state.fileFromAdmin != null? this.state.fileFromAdmin.value : null}
                               type="file" />
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 mt-3 mb-3 text-right">
                        <NavLink to={`/proactiveFinished/${this.state.feedbackId}`}>
                            <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                    style={{ paddingRight: '4px' }} />{strings.goBack}</button>
                        </NavLink>
                        <button className="btn btn-pill btn-info visualizeFeedbackButtons"
                                onClick={() => this.submitAnswers(true)}>
                                <FontAwesomeIcon icon={faPaperPlane} size="lg"
                                    style={{ paddingRight: '4px' }} />{strings.answer}</button>
                        <button className="btn btn-pill btn-success visualizeFeedbackButtons"
                                onClick={() => this.submitAnswers(false)}>
                                <FontAwesomeIcon icon={faSave} size="lg" style={{ paddingRight: '4px' }} />
                                {strings.save}
                            </button>
                    </div>
                </div>
            </div>
        )
    }


    getAllFeedbackAnswers = (id) => {
        FeedbackPublicationRepository.getAnswers(id).then(res => {
            this.setState({
                answers: res.data,
            });
        }).then(() => {
            this.state.answers.map(item => {
                this.setState(prevState => ({
                    options: [...prevState.options, item.item.options]
                }))
            })
        }).catch(() => {
            toast.error(strings.failedToLoadAnswers)
        })
    }

    getFeedbackItemsByFeedbackId = (feedbackId) => {
        FeedbackItemRepository.getAllFeedbackItemsRest(feedbackId).then((res) => {
            const snapData = res.data;
            for (let i = 0; i < snapData.length; i++) {
                snapData[i]["showItem"] = snapData[i].dependentFeedbackItem === null;
                snapData[i]["required"] = false;
            }
            this.setState({
                spinner:false,
                feedItems: snapData,
            })
        }).catch(() => {
            toast.error(strings.failedToLoadData)
        })
    }

    singleChoice = (item) => {
        if(item.showItem){
        return this.showChoice(item, 'radio');
        }
    }


    multipleChoice = (item) => {
        if(item.showItem){
        return this.showChoice(item, 'checkbox')
        }
    }

    attachmentField = (item) => {
        if(item.showItem){
        return this.displayInput(item, 'file')
        }
    }

    displayChecked = (id, option) => {
        for (let item of this.state.answersAdmin) {
            if (item.id == id && item.value == option)
                return true;
        }
        return false;
    }

    multiCheckbox = (item, option, type) => {
        let checkedAnswer = false;
        let tmpAnswer = this.state.answersAdmin.find(x => x.id == item.id);
        if (tmpAnswer != undefined && tmpAnswer.value.includes(option)) {
            checkedAnswer = true
        }
        return <div className="d-inline-block col-12 pb-2" key={option}>
            <input
                id={option}
                name={item.id}
                className="visualizeFeedbackMulCheckInput align-top mt-1"
                onChange={this.updateAnswer}
                value={option}
                type={type}
                checked={checkedAnswer}
            />
            <label className="form-check-label ml-1 d-inline-block col-11" style={{wordWrap: "break-word"}}>{option}</label>
        </div>

    }

    showChoice = (item, type) => {
        const options = item.options.map(option => (
            type == "radio" ?
                <div key={option}>
                    <input name={item.id}
                        className="visualizeFeedbackCheckInput"
                        onChange={this.updateAnswer}
                        value={option}
                        type={type}
                        checked={this.displayChecked(item.id, option)}
                    />
                    <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{option}</label>
                </div>
                :
                this.multiCheckbox(item, option, type)
        ));
        return (item.subQuestion ?
            (
                <div className="visualizeFeedbackQuestionContainerChild" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {options}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                            <span className="badge badge-pill badge-danger p-2 ">
                                <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                                {strings.required}
                            </span>
                        </div>
                    </div>}
                    {this.state.errors[item.id] === true ?
                        <p style={{ color: "red", fontWeight: "bold" }}>
                            {strings.pleaseFillInThisField}
                        </p>
                        : ""}
                </div>
            )
            :
            (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 form-group kt-checkbox">
                        {options}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
                {this.state.errors[item.id] === true ?
                <p style={{ color: "red", fontWeight: "bold" }}>
                    {strings.pleaseFillInThisField}
                </p>
                : ""}
            </div>)
        )
    }


    textField = (item) => {
        if(item.showItem){
        return this.displayInput(item, 'text');
        }
    }

    numericField = (item) => {
        if(item.showItem){
        return this.displayInput(item, 'number');
        }
    }

    noteField = (item) => {
        if(item.showItem){
        return this.displayNote(item)
        }
    }

    dateField = (item) => {
        if(item.showItem){
        return this.displayInput(item, 'date')
        }
    }

    getData = (id) => {
        for (let item of this.state.answersAdmin) {
            if (item.id == id)
                return item.value;
        }
        return "";
    }

    getFileName = (id) => {
        for (let item of this.state.answers) {
            if (item.item.id == id)
                return item.value;
        }
        return "";
    }
    getFile = (id) => {
        for (let item of this.state.answers) {
            if (item.item.id == id)
                return item.attachment.id;
        }
        return "";
    }

    displayInput = (item, type) => {
        return (item.subQuestion ?
            (<div className=" visualizeFeedbackQuestionContainerChild" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 w-100 form-group">
                        <input name={item.id}
                            className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                            type={type}
                            onChange={this.updateAnswer} 
                            onKeyDown={(event) => this.checkIfNumber(event,type)}
                            />
                        {type === "number" &&
                            <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
                {this.state.errors[item.id] === true ?
                    <p style={{ color: "red", fontWeight: "bold" }}>
                        {strings.pleaseFillInThisField}
                    </p>
                    : ""}
            </div>)
            :
            type != "file" ?
                (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        <div className="col-12">
                            <h3>{item.description}</h3>
                        </div>
                        <div className="col-12 w-100 form-group">
                            <input name={item.id}
                                className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                                type={type}
                                onChange={this.updateAnswer}
                                value={this.getData(item.id)} 
                                onKeyDown={(event) => this.checkIfNumber(event,type)}
                                />
                            {type === "number" &&
                                <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                            <span className="badge badge-pill badge-danger p-2 ">
                                <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                                {strings.required}
                            </span>
                        </div>
                    </div>}
                    {this.state.errors[item.id] === true ?
                        <p style={{ color: "red", fontWeight: "bold" }}>
                            {strings.pleaseFillInThisField}
                        </p>
                        : ""}
                </div>)
                :
                (this.getFile(item.id)!== "" && <div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 w-100 form-group">
                            {this.getFileName(item.id) != null &&
                                <a style={{ color: "blue", marginBottom: "4px" }} className="visualizeFeedbackCheckInput"
                                href={SERVER_ADDRESS + "rest/attachment/download/" + this.getFile(item.id)}
                                target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFilePdf}
                                    color="red"
                                    size="lg"
                                    style={{ paddingRight: '4px' }}
                                />
                                {this.getFileName(item.id)}
                            </a>}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                            <span className="badge badge-pill badge-danger p-2 ">
                                <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                                {strings.required}
                            </span>
                        </div>
                    </div>}
                    {this.state.errors[item.id] === true ?
                <p style={{ color: "red", fontWeight: "bold" }}>
                    {strings.pleaseFillInThisField}
                </p>
                : ""}
                </div>)
        )

    }
    displayNote = (item) => {
        return (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
            <div className="row visualizeFeedbackQuestionHeaderDiv">
                <div className="col-12">
                    <h4 title="Question">{item.name}</h4>
                </div>
            </div>
            <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                <div className="col-12">
                    {item?.options === null || item?.options == "" ? "" : item?.options[0]}
                </div>
            </div>
        </div>)
    }

    updateAnswer = (e) => {
        const checked = e.target.checked;
        const name = e.target.name;
        let value = "";
        const type = e.target.type;
        let file = null;

        if (e.target.type === "file") {
            value = e.target.files[0].name
            file = e.target.files[0]
        } else {
            value = e.target.value;
        }

        this.setState((state) => {
            const allAnswers = state.allAnswers || {};
            let allIndex = allAnswers.findIndex(item => item.id == name);
            let answers = state.answersAdmin || {};
            let index = answers.findIndex(item => item.id == name);
            if (type === 'checkbox') {
                let prev = []
                if (answers[index] != undefined) {
                    prev = answers[index].value
                }

                if (checked) {
                    prev.push(value)
                } else {
                    prev.splice(prev.indexOf(value), 1);
                }

                if (answers[index] == undefined) {
                    answers.push({ id: name, value: prev })
                    index = answers.length - 1
                }
                else {
                    answers[index].value = prev;
                }
                if (allAnswers[allIndex] == undefined) {
                    allAnswers.push({ id: name, value: value })
                }
                else {
                    allAnswers[allIndex].value = prev;
                }
            } else {
                if (answers[index] == undefined) {
                    answers.push({ id: name, value: value })
                    index = answers.length - 1

                }
                else {
                    answers[index].value = value;
                }
                if (allAnswers[allIndex] == undefined) {
                    allAnswers.push({ id: name, value: value })
                }
                else {
                    allAnswers[allIndex].value = value;
                }
            }
            
            //this.checkDependent(state.feedItems,answers,allAnswers,index)
            return {
                answersAdmin: answers
            };
        });
    }

    checkDependent = (feedItems, answers, allAnswers, index) => {
        let itemsToChangeFirst = []
        let itemsToChangeSecond = []
        let idsToChange = []
        feedItems.forEach(item => {
            if (item.dependentFeedbackItem?.id == answers[index].id) {
                itemsToChangeFirst.push({
                    id: item.id,
                    name: item.name,
                    dependentAnswer: item.dependentAnswer,
                    dependentFeedbackItem: item.dependentFeedbackItem
                })
                idsToChange.push(item.id)
            }
        })
        feedItems.forEach(item => {
            if (idsToChange.includes(item.dependentFeedbackItem?.id))
                itemsToChangeSecond.push({
                    id: item.id,
                    name: item.name,
                    dependentAnswer: item.dependentAnswer,
                    dependentFeedbackItem: item.dependentFeedbackItem
                })
        })
        itemsToChangeFirst.forEach(item => {
            if (item.dependentAnswer == answers[index].value || answers[index].value.includes(item.dependentAnswer)) {
                let currentAnswerIndex = answers.findIndex(elem => elem.id == item.id)
                let currentAllAnswerIndex = allAnswers.findIndex(elem => elem.id == item.id)
                if (currentAnswerIndex !== -1 && currentAllAnswerIndex !== -1) {
                    answers[currentAnswerIndex].value = allAnswers[currentAllAnswerIndex].value
                    for (let i = 0; i < itemsToChangeSecond.length; i++) {
                        let indexAnswers = answers.findIndex(elem => elem.id == itemsToChangeSecond[i].id)
                        let allIndexAnswers = allAnswers.findIndex(elem => elem.id == itemsToChangeSecond[i].id)
                        if(answers[currentAnswerIndex].value.constructor === Array && answers[currentAnswerIndex]?.value.includes(itemsToChangeSecond[i].dependentAnswer) && indexAnswers !== -1 && allIndexAnswers!==-1){
                            answers[indexAnswers].value = allAnswers[allIndexAnswers].value
                        }
                        else if (itemsToChangeSecond[i].dependentAnswer == answers[currentAnswerIndex]?.value && indexAnswers !== -1 && allIndexAnswers!==-1)
                            {
                                answers[indexAnswers].value = allAnswers[allIndexAnswers].value
                            }
                    }
                }
            }
            else {
                itemsToChangeSecond.push(item)
                for (let i = 0; i < itemsToChangeSecond.length; i++) {
                    let flag = true
                    for (let j = 0; j < answers.length; j++) {
                        if (itemsToChangeSecond[i].id == answers[j].id) {
                            answers[j].value = ''
                            flag = false
                            break;
                        }
                    }
                    if (flag) {
                        answers.push({ id: itemsToChangeSecond[i].id, value: '' })
                    }
                }

            }
        })
    }

    displayChoice = (items, index) => {
        let finalOptions = []
        if (items.item.options) {
            items.item.options.map((item, index) => {
                if (items.item.type === "SINGLE_CHOICE") {              
                    let e;
                    if (items.value === item) {
                        e = <div key={index}>
                            <input
                                // name={items.item.id}
                                className="visualizeFeedbackCheckInput"
                                readOnly={true}
                                value={items.value}
                                checked={true}
                                disabled={true}
                                type={"radio"} />
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)
                    } else {
                        e = <div key={index}>
                            <input
                                // name={items.item.id}
                                readOnly={true}
                                className="visualizeFeedbackCheckInput"
                                value={items.value}
                                disabled={true}
                                type={"radio"} />
                            <label className="form-check-label ml-1" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)
                    }
                    return e
                } else if (items.item.type === "MULTIPLE_CHOICE") {
                    let values = items.value.split(";"); // c i s
                    let e;
                    let flag;
                    flag = values.includes(item)
                    if (flag) {
                        e = <div className="d-inline-block col-12 pb-2" key={index}>
                            <input
                                className="visualizeFeedbackCheckInput alignt-top mt-1"
                                checked={true}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"} />
                            <label className="form-check-label ml-1 d-inline-block col-11" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)

                    } 
                    
                    else {
                        e = <div className="d-inline-block col-12 pb-2" key={index}>
                            <input
                                className="visualizeFeedbackMulCheckInput align-top mt-1"
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"} />
                            <label className="form-check-label ml-1 d-inline-block col-11" style={{wordWrap: "break-word"}}>{item}</label>
                        </div>
                        finalOptions.push(e)

                    }
                    flag = false;
                    return e
                }
            })
        }
        
        if (items.item.type === "NUMERIC_FIELD") {
            let y = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                    value={items.value}
                    readOnly={true}
                    disabled={true}
                    type={"text"} />
            </div>
            finalOptions.push(y)
        } else if (items.item.type === "TEXT_FIELD") {
            let x = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                    value={items.value}
                    readOnly={true}
                    disabled={true}
                    type={"text"} />
            </div>
            finalOptions.push(x)
        }
        else if (items.item.type === "DATE") {
            let x = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                    value={items.value}
                    readOnly={true}
                    disabled={true}
                    type={"text"} />
            </div>
            finalOptions.push(x)
        }
        else if (items.item.type === "ATTACHMENT") {
            let x = <div key={index}>
                <a style={{ color: "blue", marginBottom: "4px" }} className="visualizeFeedbackCheckInput"
                    href={SERVER_ADDRESS + "rest/attachment/download/" + items.attachment.id}
                    target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFilePdf}
                        color="red"
                        size="lg"
                        style={{ paddingRight: '4px' }}
                    />
                    {items.value}
                </a>
            </div>
            finalOptions.push(x)
        }

        else if (items.item.type === "NOTE") {
            let x = <p>{items.item.options[0]}</p>
            finalOptions.push(x)
        }

        return (items.item.subQuestion ?
            (<div className="visualizeFeedbackQuestionContainerChild" key={index} style={{ marginLeft: '200px' }}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{items.item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{items.item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 form-group kt-checkbox">
                        {finalOptions}
                    </div>
                </div>
            </div>)
            :
            ((items.item.type === "NOTE" || items.value !== "") &&   <div className="container-fluid visualizeFeedbackQuestionContainer" key={index}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{items.item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{items.item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 form-group kt-checkbox">
                        {finalOptions}
                    </div>
                </div>
                </div>)
        )
    }
}

export default PreviewAnsweredAndSendReport;