import React, { Component } from "react";
import FeedbackItemRepository from "../../repository/FeedbackItemRepository";
import { toast } from "react-toastify";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import { strings } from "../../Localization/Localization";
import "./css/VisualizeFeedback.css"
import { faArrowLeft, faExclamation, faPaperPlane, faSave, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import InstitutionRepository from '../../repository/InstitutionRepository';
import { SERVER_ADDRESS } from "../../shared/server-address";

class VisualizeFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedItems: [],
            answers: [],
            feedbackPublication: {},
            feedbackId: 0,
            feedbackTitle: "",
            file: null,
            files: [],
            errors: {},
            institutionName: "",
            checkedMultiple: [],
            allAnswers: []
        }
    }

    componentDidMount() {
        this.markReadFeedbackPublication();
        this.getAllFeedbacksItems();
        this.getInstitutionName();
        this.getAnswers();
    }

    getInstitutionName = async () => {
        let instName = await InstitutionRepository.getInstitutionById(localStorage.getItem("institutionId"));
        if (localStorage.getItem("activeLanguage") === "mk") {
            instName = instName.data.nameMk
        }
        else if (localStorage.getItem("activeLanguage") === "al") {
            instName = instName.data.nameAl
        }
        this.setState({
            institutionName: instName,
        })
    }

    render() {
        const questions = this.state.feedItems.map(item => {
            let display = null;
            let answers = this.state.answers;
            answers.forEach((key) => {
                if (key.id == item.dependentFeedbackItem?.id.toString()) {
                    if (key.value?.includes(item.dependentAnswer) ||
                        key.value[0]?.includes(item.dependentAnswer) ||
                        key.value[1]?.includes(item.dependentAnswer) ||
                        key.value[2]?.includes(item.dependentAnswer) ||
                        key.value[3]?.includes(item.dependentAnswer) ||
                        key.value[4]?.includes(item.dependentAnswer) ||
                        key.value[5]?.includes(item.dependentAnswer)) {
                        item.showItem = true;

                        if (item.type === "SINGLE_CHOICE") {
                            display = this.singleChoice(item);
                        } else if (item.type === "MULTIPLE_CHOICE") {
                            display = this.multipleChoice(item);
                        } else if (item.type === "NUMERIC_FIELD") {
                            display = this.numericField(item);
                        } else if (item.type === "TEXT_FIELD") {
                            display = this.textField(item);
                        } else if (item.type === "ATTACHMENT") {
                            display = this.textField(item);
                        }
                        else if (item.type === "NOTE") {
                            display = this.noteField(item);
                        }
                        else if (item.type === "DATE"){
                            display = this.dateField(item)
                        }
                    }
                    else {
                        item.showItem=false
                        if(item.dependentAnswer.includes(";")){
                            let arrDependentAnswers = item.dependentAnswer.split(";;")
                            arrDependentAnswers.forEach(arrAnswer => {
                                if (arrAnswer != '' && 
                                    (key.value?.includes(arrAnswer) ||
                                    key.value[0]?.includes(arrAnswer) ||
                                    key.value[1]?.includes(arrAnswer) ||
                                    key.value[2]?.includes(arrAnswer) ||
                                    key.value[3]?.includes(arrAnswer) ||
                                    key.value[4]?.includes(arrAnswer) ||
                                    key.value[5]?.includes(arrAnswer))) {
                                    item.showItem = true;
                                    if (item.type === "SINGLE_CHOICE") {
                                        display = this.singleChoice(item);
                                    } else if (item.type === "MULTIPLE_CHOICE") {
                                        display = this.multipleChoice(item);
                                    } else if (item.type === "NUMERIC_FIELD") {
                                        display = this.numericField(item);
                                    } else if (item.type === "TEXT_FIELD") {
                                        display = this.textField(item);
                                    } else if (item.type === "ATTACHMENT") {
                                        display = this.textField(item);
                                    }
                                    else if (item.type === "NOTE") {
                                        display = this.noteField(item);
                                    }
                                    else if (item.type === "DATE"){
                                        display = this.dateField(item)
                                    }
                                }
                            })
                        }
                    }
                }
            })

            if (item.type === "SINGLE_CHOICE") {
                display = this.singleChoice(item);
            } else if (item.type === "MULTIPLE_CHOICE") {
                display = this.multipleChoice(item);
            } else if (item.type === "NUMERIC_FIELD") {
                display = this.numericField(item);
            } else if (item.type === "TEXT_FIELD") {
                display = this.textField(item);
            } else if (item.type === "ATTACHMENT") {
                display = this.attachmentField(item);
            }
            else if (item.type === "NOTE") {
                display = this.noteField(item);
            }
            else if (item.type === "DATE"){
                display = this.dateField(item)
            }

            return display;
        });

        return (
            <div className="container">
                <div className="row mt-4 titleText">
                    <div className="col-12"><h2>{this.state.feedbackTitle}</h2></div>
                </div>
                {questions}
                <div className="col-12 mt-3 mb-3 text-right">
                    {this.state.feedbackPublication &&
                        this.state.feedbackPublication?.feedback?.isFeedback ?
                        <NavLink to="/myfeedbackpublications">
                            <button type="button"
                                className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                <FontAwesomeIcon
                                    icon={faArrowLeft} size="lg"
                                    style={{ paddingRight: '4px' }} />{strings.goBack}
                            </button>
                        </NavLink>
                        :
                        <NavLink to="/myProactivityPublications">
                            <button type="button"
                                className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                <FontAwesomeIcon
                                    icon={faArrowLeft} size="lg"
                                    style={{ paddingRight: '4px' }} />{strings.goBack}
                            </button>
                        </NavLink>

                    }

                    <button className="btn btn-pill btn-info visualizeFeedbackButtons" onClick={this.submitAnswers}>
                        <FontAwesomeIcon icon={faPaperPlane} size="lg"
                            style={{ paddingRight: '4px' }} />{strings.answer}</button>

                    <button className="btn btn-pill btn-success visualizeFeedbackButtons" onClick={this.saveAnswers}>
                        <FontAwesomeIcon icon={faSave} size="lg" style={{ paddingRight: '4px' }} />
                        {strings.save}
                    </button>
                </div>
            </div>
        )
    }

    validate = () => {
        let errors = {}
        let subbmitable = true;

        for (let i = 0; i < this.state.feedItems.length; i++) {
            if(this.state.feedItems[i].type !== "NOTE"){
                const item = this.state.feedItems[i]
                const answer = this.state.answers.find(x => x.id == item.id)
                var dependentAnswer = null
                if (item.dependentFeedbackItem !== null) {
                    dependentAnswer = this.state.answers.find(x => x.id === item.dependentFeedbackItem.id)
                }
                if (item.required === true &&
                    (answer?.value === undefined || answer?.value === null ||
                        (answer?.value.length === 1 && answer.value[0] === '') || answer?.value == '') || 
                         ((item.minValue !== null && parseInt(answer?.value) < item.minValue) || (item.maxValue !== null && parseInt(answer?.value) > item.maxValue))) {
                    if (dependentAnswer?.value == item.dependentAnswer || dependentAnswer?.value.includes(item.dependentAnswer)) {
                        errors[item.id] = true
                        subbmitable = false
                    }
                    else {
                        errors[item.id] = false
                    }
                } else {
                    errors[item.id] = false
                }
        }
            this.setState({
                errors: errors
            })
        }


        return !subbmitable;


    }

    saveAnswers = () => {
        let k = [];
        for (let i = 0; i < this.state.feedItems.length; i++) {
            if(this.state.feedItems[i].name.includes("НАЗИВ НА ИНСТИТУЦИЈА")){
                k.push({id: this.state.feedItems[i].id, value: this.state.institutionName})
                   //this.state.answers.push({id: this.state.feedItems[i].id, value: this.state.institutionName})
                for (let j = 0; j < this.state.feedItems.length; j++) {
                    if(this.state.feedItems[j].name.includes("НИВО НА ДЕЛУВАЊЕ НА ИНСТИТУЦИЈАТА")){
                        if(this.state.feedItems[j].options[0].includes("Централно ниво")){
                            this.state.answers.push(k[0])
                        }else{
                            this.state.answers.push(k[1])
                        }

                    }
                }
            }
        }
        FeedbackPublicationRepository.submitAnswers(this.state.feedbackPublication.id, this.state.answers, false).then(el => {
            for (let b = 0; b < this.state.files.length; b++) {
                const form = new FormData();
                if (this.state.files[b] !== null) {
                    form.append("feedbackPublicationId", this.state.feedbackPublication.id)
                    form.append("feedbackItemId", this.state.files[b].id);
                    form.append("attachment", this.state.files[b].content);

                    FeedbackItemRepository.saveFileForFeedbackAnswer(form).then(res => {
                        this.setState({})
                    })
                }
            }
            toast.success(strings.successfullySavedAnsweres)
        }).catch(error => {
            toast.error(strings.failedToSaveAnswers)
        });
    }

    submitAnswers = () => {

       if (this.validate()) {
            toast.error(strings.failedSavingAnswers)
            return};

        const notes = this.state.feedItems.filter(x => x.type === 'NOTE')
        const answerNote = []

        for (let i = 0; i < notes.length; i++) {
            if(notes[i].dependentFeedbackItem != null)
            {
                let dependantItem = notes[i]
                let item = this.state.answers.find(x => x.id === dependantItem.dependentFeedbackItem.id)
                if(item.value !== dependantItem.dependentAnswer){
                    let newAnswers = this.state.answers.filter(x => x !== item)
                    this.setState({answers: newAnswers})
                }else{
                    answerNote.push({id: notes[i].id, value: notes[i].options[0]})
                }
            }else{
                answerNote.push({id: notes[i].id, value: notes[i].options[0]})
            }

        }

        const tmp = [...this.state.answers, ...answerNote]


              FeedbackPublicationRepository.submitAnswers(this.state.feedbackPublication.id, tmp, true).then((r) => {
                    for (let b = 0; b < this.state.files.length; b++) {
                        const form = new FormData();
                        if (this.state.files[b] !== null) {
                            form.append("feedbackPublicationId", this.state.feedbackPublication.id)
                            form.append("feedbackItemId", this.state.files[b].id);
                            form.append("attachment", this.state.files[b].content);

                            FeedbackItemRepository.saveFileForFeedbackAnswer(form).then(res => {
                                this.setState({})
                            })
                        }
                    }

                    toast.success(strings.successfullySubmittedAnswers)
                        this.props.history.push("/myProactivityPublications");
                }).catch(error => {
                    toast.error(strings.failedTSubmitAnswers)
                });
    }


    getAnswers = async () => {

        FeedbackPublicationRepository.getAnswersPub(this.props.match.params.id).then(preAnswers => {
            preAnswers = preAnswers.data
            let ansObj = []
            let ansObjPerm = []
            for (let tmpAns of preAnswers) {
                if (tmpAns.item.type === "ATTACHMENT") {
                    let tmpObj = null
                    ansObj.forEach(item => {
                        if (item.id == tmpAns.item.id) {
                            tmpObj = item
                        }
                    })
                    if (tmpObj == null) {
                        ansObj.push({ id: tmpAns.item.id, value: tmpAns.value != "" ? tmpAns.value : "", attachmentId: tmpAns.attachment?.id })
                        ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value != "" ? tmpAns.value : "", attachmentId: tmpAns.attachment?.id })
                    }
                }
                else if (tmpAns.item.type === "MULTIPLE_CHOICE") {
                    if (tmpAns.value != null) {
                        ansObj.push({ id: tmpAns.item.id, value: tmpAns.value.split(";") })
                        ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value.split(";") })
                        tmpAns.value.split(";").forEach(elem => {
                            if (document.getElementById(elem) != null) {
                                document.getElementById(elem).checked = "true"
                            }

                        })
                    }
                }
                else {
                    ansObj.push({ id: tmpAns.item.id, value: tmpAns.value })
                    ansObjPerm.push({ id: tmpAns.item.id, value: tmpAns.value })
                }
            }
            this.setState({
                allAnswers: ansObjPerm,
                answers: ansObj,
            })
        })

    }



    markReadFeedbackPublication = () => {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.markReadFeedPub(feedbackPubId);
    }

    getAllFeedbacksItems = async () => {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.getFeedbackPublicationById(feedbackPubId).then(res => {
            this.setState({
                feedbackPublication: res.data,
                feedbackId: res.data.feedback.id,
                feedbackTitle: res.data.feedback.name,
            });
            this.getFeedbackItemsByFeedbackId();
        }).catch(() => {
            toast.error(strings.failedToGetFeedbackId)
        })
    }
    getFeedbackItemsByFeedbackId = () => {
        FeedbackItemRepository.getAllFeedbackItemsRest(this.state.feedbackId).then(res => {
            const snapData = res.data;
            for (let i = 0; i < snapData.length; i++) {
                snapData[i]["showItem"] = snapData[i].dependentFeedbackItem === null;
            }
            this.setState({
                feedItems: snapData,
            })
        }).catch(() => {
            toast.error(strings.failedToLoadData)
        })
    }


    singleChoice = (item) => {
        if (item.showItem) {
            return this.displayChoice(item, 'radio');
        }
    }


    multipleChoice = (item) => {
        if (item.showItem) {
            return this.displayChoice(item, 'checkbox')
        }
    }

    displayChecked = (id, option) => {
        for (let item of this.state.answers) {
            if (item.id == id && item.value == option)
                return true;
        }
        return false;
    }

    multiCheckbox = (item, option, type) => {
        let checkedAnswer = false;
        let tmpAnswer = this.state.answers.find(x => x.id == item.id);
        if (tmpAnswer != undefined && tmpAnswer.value.includes(option)) {
            checkedAnswer = true
        }
        return <div key={option}>
            <input
                id={option}
                name={item.id}
                className="visualizeFeedbackMulCheckInput"
                onChange={this.updateAnswer}
                value={option}
                type={type}
                checked={checkedAnswer}
            />
            <label className="form-check-label ml-1">{option}</label>
        </div>

    }

    checkDependent = (feedItems, answers, allAnswers, index) => {
        let itemsToChangeFirst = []
        let itemsToChangeSecond = []
        let idsToChange = []
        feedItems.forEach(item => {
            if (item.dependentFeedbackItem?.id == answers[index].id) {
                itemsToChangeFirst.push({
                    id: item.id,
                    name: item.name,
                    dependentAnswer: item.dependentAnswer,
                    dependentFeedbackItem: item.dependentFeedbackItem,
                    type: item.type
                })
                idsToChange.push(item.id)
            }
        })
        feedItems.forEach(item => {
            if (idsToChange.includes(item.dependentFeedbackItem?.id))
                itemsToChangeSecond.push({
                    id: item.id,
                    name: item.name,
                    dependentAnswer: item.dependentAnswer,
                    dependentFeedbackItem: item.dependentFeedbackItem,
                    type: item.type
                })
        })
        itemsToChangeFirst.forEach(item => {
            if (item.dependentAnswer == answers[index].value || answers[index].value.includes(item.dependentAnswer)) {
                let currentAnswerIndex = answers.findIndex(elem => elem.id == item.id)
                let currentAllAnswerIndex = allAnswers.findIndex(elem => elem.id == item.id)
                if (currentAnswerIndex !== -1 && currentAllAnswerIndex !== -1) {
                    answers[currentAnswerIndex].value = allAnswers[currentAllAnswerIndex].value
                    for (let i = 0; i < itemsToChangeSecond.length; i++) {
                        let indexAnswers = answers.findIndex(elem => elem.id == itemsToChangeSecond[i].id)
                        let allIndexAnswers = allAnswers.findIndex(elem => elem.id == itemsToChangeSecond[i].id)
                        if(answers[currentAnswerIndex].value.constructor === Array && answers[currentAnswerIndex]?.value.includes(itemsToChangeSecond[i].dependentAnswer) && indexAnswers !== -1 && allIndexAnswers!==-1){
                            answers[indexAnswers].value = allAnswers[allIndexAnswers].value
                        }
                        else if (itemsToChangeSecond[i].dependentAnswer == answers[currentAnswerIndex]?.value && indexAnswers !== -1 && allIndexAnswers!==-1)
                            {
                                answers[indexAnswers].value = allAnswers[allIndexAnswers].value
                            }
                    }
                }
            }
            else {
                itemsToChangeSecond.push(item)
                for (let i = 0; i < itemsToChangeSecond.length; i++) {
                    let flag = true
                    for (let j = 0; j < answers.length; j++) {
                        if (itemsToChangeSecond[i].id == answers[j].id) {
                            if (itemsToChangeSecond[i].type !== "MULTIPLE_CHOICE") {
                                answers[j].value = ''
                                flag = false
                                break;
                            }
                            else {
                                answers[j].value = []
                                flag = false
                                break;
                            }
                        }
                    }
                    if (flag) {
                        if (itemsToChangeSecond[i].type !== "MULTIPLE_CHOICE")
                            answers.push({ id: itemsToChangeSecond[i].id, value: '' })
                        else
                            answers.push({ id: itemsToChangeSecond[i].id, value: [] })
                    }
                }

            }
        })
    }

    displayChoice = (item, type) => {
        const options = item.options.map(option => (
            type == "radio" ?
                <div key={option}>
                    <input name={item.id}
                        className="visualizeFeedbackCheckInput"
                        onChange={this.updateAnswer}
                        value={option}
                        type={type}
                        checked={this.displayChecked(item.id, option)}
                    />
                    <label className="form-check-label ml-1">{option}</label>
                </div>
                :
                this.multiCheckbox(item, option, type)
        ));
        return (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
            <div className="row visualizeFeedbackQuestionHeaderDiv">
                <div className="col-12">
                    <h4 title="Question">{item.name}</h4>
                </div>
            </div>
            <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                <div className="col-12">
                    <h3>{item.description}</h3>
                </div>
                <div className="col-12 form-group kt-checkbox"
                    id={item.id}>
                    {options}
                </div>
            </div>
            {item.required && <div className="row w-100">
                <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                    <span className="badge badge-pill badge-danger p-2 ">
                        <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                        {strings.required}
                    </span>
                </div>
            </div>}

            {this.state.errors[item.id] === true ? 
                <p style={{ color: "red", fontWeight: "bold" }}>
                    {strings.pleaseFillInThisField}
                </p>
                : ""}
        </div>);

    }

    attachmentField = (item) => {
        if (item.showItem) {
            return this.displayInput(item, 'file');
        }
    }

    noteField = (item) => {
        if (item.showItem) {
            return this.displayNote(item)
        }
    }

    dateField = (item) => {
        if (item.showItem) {
        return this.displayInput(item, 'date')
        }
    }


    textField = (item) => {
        if (item.showItem) {
            return this.displayInput(item, 'text');
        }
    }

    numericField = (item) => {
        if (item.showItem) {
            return this.displayInput(item, 'number');
        }
    }

    preventNonNumericalInput = (e) => {
        e = e || window.event;
        const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    }

    displayNote = (item) => {
        return (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
            <div className="row visualizeFeedbackQuestionHeaderDiv">
                <div className="col-12">
                    <h4 title="Question">{item.name}</h4>
                </div>
            </div>
            <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                <div className="col-12">
                    {item?.options === null ? "" : item?.options}
                </div>
            </div>
        </div>)
    }

    getData = (id) => {
        for (let item of this.state.answers) {
            if (item.id == id){
                return item.value;
            }
                
        }
        return "";
    }

    getAttachmentId = (id) => {
        for (let item of this.state.answers) {
            if (item.id == id){
                return item?.attachmentId;
            }
        }
        return "";
    }

    displayInput = (item, type) => {
        // return (<div className="col-12" key={item.id}>
        //     <h4 title="Question">{item.name}</h4>
        //     <p>{item.description}</p>
        //     <div className="col-12">
        //         <input name={item.id}
        //                type={type}
        //                onChange={this.updateAnswer}/>
        //     </div>
        // </div>);

        return (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
            <div className="row visualizeFeedbackQuestionHeaderDiv">
                <div className="col-12">
                    <h4 title="Question">{item.name}</h4>
                </div>
            </div>
            <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                <div className="col-12">
                    <h3>{item.description}</h3>
                </div>
                {type === "number" ?
                    <div className="col-12 w-100 form-group">
                        <input name={item.id}
                            className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"  
                            type={type}
                            max={item.maxValue}
                            min={item.minValue}
                            onKeyPress={this.preventNonNumericalInput}
                            onKeyUp={this.enforceMinMax}
                            onChange={this.updateAnswer}
                            defaultValue={this.getData(item.id)} />
                    {type==="number" && <small id="emailHelp" className="form-text text-muted">{strings.numberOnly + 
                    (item.minValue !== null ? 
                    ` во опсег од ${item?.minValue}` : "" ) + 
                    (item.minValue !== null ?
                    ` до ${item.maxValue}` : "")}</small>}
                    </div>
                    :
                    type === "file" ?
                        <div className="col-12 w-100 form-group" key={item.id}>
                            {this.getData(item.id)!="" ?
                                <a style={{ color: "blue", marginBottom: "4px", boxShadow: "none" }} className="visualizeFeedbackCheckInput"
                                    href={SERVER_ADDRESS + "rest/attachment/download/" + this.getAttachmentId(item.id)}
                                    target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFilePdf}
                                        color="red"
                                        size="lg"
                                        style={{ paddingRight: '4px' }}
                                    />
                                    {this.getData(item.id)}
                                </a> :
                                <></>}
                            <input name={item.id}
                                id={item.id}
                                type={type}
                                onChange={this.updateAnswer}
                                className="w-100 mt-2 pl-2 pr-2 visualizeFeedbackTextInput" />
                        </div>
                        :
                        type === "date" ?
                        <div className="col-12 w-100 form-group">
                        <input name={item.id}
                            className="w-100 pl-2 pr-2 visualizeFeedbackTextInput datepicker"
                            type={type}
                            onChange={this.updateAnswer}
                            required={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? false: item.required}
                            disabled={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? true: false}
                            label={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? this.state.institutionName: ''}
                            defaultValue={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? this.state.institutionName : this.getData(item.id)} />
                    </div>
                        :
                        <div className="col-12 w-100 form-group">
                            <input name={item.id}
                                className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                                type={type}
                                onChange={this.updateAnswer}
                                required={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? false: item.required}
                                disabled={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? true: false}
                                label={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? this.state.institutionName: ''}
                                defaultValue={item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ? this.state.institutionName : this.getData(item.id)} />
                        </div>
                }
            </div>
            {item.required && !item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") &&  <div className="row w-100">
                <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                    <span className="badge badge-pill badge-danger p-2 ">
                        <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                        {strings.required}
                    </span>
                </div>
            </div>}

            {this.state.errors[item.id] === true && !item.name.includes("НАЗИВ НА ИНСТИТУЦИЈА") ?
                <p style={{ color: "red", fontWeight: "bold" }}>
                    {(item.minValue!==null ? `Вредноста треба да е поголема од ${item.minValue}   ` : "") + (item.maxValue!==null ? `Вредноста треба да е помала од ${item.maxValue}   ` : "") + strings.pleaseFillInThisField}
                </p>
                : ""}
        </div>);

    }

    updateAnswer = (e) => {
        const checked = e.target.checked;
        const name = parseInt(e.target.name);
        let value = "";
        let file = null;

        if (e.target.type === "file") {
            value = e.target.files[0].name
            file = e.target.files[0]
            // AttachmentRepository.addAttachment(file).then(r =>
            //     toast.success("data")
            // )
        }
        else{
            value = e.target.value;
        } 
        const type = e.target.type;
        this.setState((state) => {
            const allAnswers = state.allAnswers || {};
            let allIndex = allAnswers.findIndex(item => item.id == name);
            let answers = state.answers || {};
            let index = answers.findIndex(item => item.id == name);
            if (type === 'checkbox') {
                let prev = []
                if (answers[index] != undefined) {
                    prev = answers[index].value
                }
                if (checked) {
                    prev.push(value)
                } else {
                    prev.splice(prev.indexOf(value), 1);
                }

                if (answers[index] == undefined) {
                    answers.push({ id: name, value: prev })
                    index = answers.length - 1
                }
                else {
                    answers[index].value = prev;
                }
                if (allAnswers[allIndex] == undefined) {
                    allAnswers.push({ id: name, value: prev })
                }
                else {
                    allAnswers[allIndex].value = prev;
                }
            } else {
                if (type === "file") {
                    let files = this.state.files
                    files.push({ id: name, content: file });

                    this.setState({
                        files: files
                    })
                }
                if (answers[index] == undefined) {
                    answers.push({ id: name, value: value })
                    index = answers.length - 1
                }
                else {
                    answers[index].value = value;
                }
                if (allAnswers[allIndex] == undefined) {
                    allAnswers.push({ id: name, value: value })
                }
                else {
                    allAnswers[allIndex].value = value;
                }
            }
            //this.checkDependent(state.feedItems, answers, allAnswers, index)
            return {
                answers: [...answers],
            };
        });
    }
}

export default VisualizeFeedback;