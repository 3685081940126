import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {strings} from "../../Localization/Localization";
import AreaOfInterestRepository from "../../repository/AreaOfInterestRepository";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import FeedbackRepository from "../../repository/FeedbackRepository";

const EditProactiveTransparency = (props) => {

    strings.setLanguage(localStorage.getItem("activeLanguage"));
    const [listAreas, setListAreas] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [levels, setLevels] = useState([]);

    console.log("element", levels.find(el => el.value === props.entity.levelOfActivity))

    const getAreas = () => {
        AreaOfInterestRepository.findAreasForUser().then(res => {
            res.data.map(item => {
                listAreas.push({value: item.id, label: item.nameMk, name: "areaOfInterestId"})
            })
        })
    }

    const getLevels = () => {
        FeedbackRepository.getAllLevels()
            .then(({ data }) => {
                data.map(el => {
                    return levels.push({value: el.id, label: el.nameMk, name: "levelOfActivity"});
                })
            })
    }

    const setDate = () => {
        let date = props.entity.dueDate;
        setStartDate(new Date(date));
    }

    const updateDueDate = (v) => {
        setStartDate(v);
        props.handleChange({name: 'dueDate', value: v})
    };

    const setYear = () => {
        let selectedYear = props.entity.selectedYear;
        setSelectedYear(selectedYear);
    }

    const handleDateChange = (e) => {
        const date = moment(e._d).format('YYYY')
        setSelectedYear(date)
        props.handleChange({name: 'selectedYear', value: date})
    }

    useEffect(() => {
        getAreas();
        setDate();
        setYear();
        getLevels();
    }, [])

    const onSelectedAreaChangeHandler = (area) => {
        if (area !== null) {
            props.onChange(area.name, area.value)
        } else {
            props.onChange("areaOfInterestId", null)
        }
    }

    const onSelectedLevelOfActivityChangeHandler = (level) => {
        if (level !== null) {
            props.onChange(level.name, level.value)
        } else {
            props.onChange("levelOfActivity", null)
        }
    }


    return (
        <Row>
            <Col>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            required
                            defaultValue={props.entity.name}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>{strings.description}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            name="description"
                            required
                            defaultValue={props.entity.description}
                            onChange={props.handleChange}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <small style={{color: "red"}}>*</small>{strings.dueDate}
                        </Form.Label>
                        <div>
                            <DatePicker
                                locale="mk"
                                id="dueDate"
                                selected={startDate}
                                onChange={updateDueDate}
                                className="form-control"
                                dateFormat="dd MMM yyyy"
                                popperPlacement="top-start"
                            />
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label><span style={{color: "red", fontSize: '18px'}}
                                          className="mr-1">*</span>{strings.evaluationYear}</Form.Label>
                        <Datetime
                            id="selectedYear"
                            dateFormat="YYYY"
                            timeFormat={false}
                            value={selectedYear}
                            onChange={handleDateChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <small style={{color: "red"}}>*</small>{strings.areasOfInterest}
                        </Form.Label>
                        <div>
                            <Select
                                placeholder={""}
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                options={listAreas}
                                onChange={onSelectedAreaChangeHandler}
                                name={"areaOfInterestId"}
                                defaultValue={props.entity.areaOfInterest !== null && props.entity.areaOfInterest !== undefined ? {
                                    value: props.entity.areaOfInterest.id,
                                    label: props.entity.areaOfInterest.nameMk,
                                    name: "areaOfInterestId"
                                } : ""}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <small style={{color: "red"}}>*</small>{strings.levelOfActivity}
                        </Form.Label>
                        <div>
                            <Select
                                placeholder={""}
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                options={levels}
                                onChange={onSelectedLevelOfActivityChangeHandler}
                                name={"levelOfActivity"}
                                defaultValue={props.entity.levelOfActivity !== null && props.entity.levelOfActivity !== undefined ? {
                                    value: props.entity.levelOfActivity.id,
                                    label: props.entity.levelOfActivity.nameMk,
                                    name: "levelOfActivity"
                                } : ""}
                            />
                        </div>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    )
}

export default EditProactiveTransparency;
