import React, {useState} from "react";
import {strings} from "../../Localization/Localization";


const IndicatorReportRealReturned = (props) => {

    const [validation] = useState(['message'])

    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            props.onChange(name,value);
        }
        else{
            const value = target.value;
            const name = target.name;
            console.log("value", value);
            console.log("name", name);

            if(validation[name] !== undefined){
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        }

    };

    return(
        <div className="container">

                <div className="col-12 mt-4">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <small style={{color: "red"}}>*</small>
                        {strings.emailMessage}
                    </label>
                    <textarea
                        placeholder={""}
                        name={"message"}
                        rows={6}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control mb-3"
                    />
                    <small style={{color: "red"}}>{validation["message"] && validation["message"].error && validation["message"].message}</small>
                </div>

        </div>
    )

}

export default IndicatorReportRealReturned;