import React, {Component} from "react";
import ProposeRepository from "../../../repository/ProposeRepository";
import {toast} from "react-toastify";
import {strings} from "../../../Localization/Localization";
import CrudModal from "../../Crud/CrudModal";
import MeasureAdd from "../Measure/MeasureAdd";
import ActivityAdd from "./ActivityAdd";
import MeasureEdit from "../Measure/MeasureEdit";
import DeleteModal from "../../Crud/DeleteModal";
import ActivityEdit from "./ActivityEdit";

class Activity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    addActivity = (entity) => {
        return ProposeRepository.manageActivityProposal(entity).then(res => {
            toast.success(strings.succAdded);
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    editActivity = (entity) => {
        return ProposeRepository.manageActivityProposal(entity).then(async res => {
            toast.success(strings.succChanged);
            await this.sleep(500);
            window.location.reload();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    deleteActivity = (activityId) => {
        return ProposeRepository.manageActivityProposal({id: activityId, toDelete: true}).then(res => {
            toast.success(strings.deleteModal);
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.PROPOSALS_ACTIVITY}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="defaultBtnAdd btn-primary mt-4"
                            title={strings.ADD_ACTIVITY_PROPOSAL}
                            fields={{
                                proposedIndicators: this.props.proposedIndicators,
                                proposedMeasures: this.props.proposedMeasures,
                                proposedProblems: this.props.proposedProblems,
                            }}
                            showText={true}
                            onSubmit={this.addActivity}
                            body={ActivityAdd}
                        />
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.measure}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.institutions} </th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.deadlineForRealization}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.financialImplications}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.options}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.props.proposedActivities?.map(el => this.renderActivities(el, true))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>

                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.ACTIVITIES}</h2>
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.measure}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.institutions} </th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.deadlineForRealization}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.financialImplications}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.status}</th>
                    </thead>
                    <tbody>
                    {this.props.activities.map(el => this.renderActivities(el, false))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
            </div>
        );
    }

    measureOrRealMeasure = (activity) => {
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        return measure?.nameMk;
    }

    problemOrRealProblem = (activity) => {
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        let problem = measure.problem ? measure.problem : measure.realProblem;
        return problem?.nameMk;
    }

    napOrRealNap = (activity) => {
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        let problem = measure.problem ? measure.problem : measure.realProblem;
        let nap = problem?.nap ? problem?.nap : problem?.realNap;
        return nap?.nameMk;
    }

    renderActivities = (activity, options) => {
        return (
            <tr key={activity.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{this.napOrRealNap(activity)}</td>
                <td className="tableData">{this.problemOrRealProblem(activity)}</td>
                <td className="tableData">{this.measureOrRealMeasure(activity)}</td>
                <td className="tableData">{activity.nameMk}</td>
                <td className="tableData">
                    {strings.competentInstitution}: {activity.competentInstitution?.nameMk}  <br/>
                    {strings.includedInstitutions}:
                    {
                        activity.activityInstitutions?.map(ai => {return <p>-{ai.nameMk}</p>})
                    }
                </td>
                               <td className="tableData">
                    {activity.activityDateType !== "NOTDEFINED" ? (activity.activityDateType === "FIRSTHALF" ? strings.firstHalf : (activity.activityDateType === "SECONDHALF" ? strings.secondHalf : "")) : " "}
                    {activity.yearDate ? " - " + activity.yearDate : ""}
                    {activity.continuously ? strings.continuousInView : ""}
                </td>
                <td className="tableData">{activity.financialImplications    ? strings.has : strings.hasNot}</td>
                {!options && <td className="tableData">{activity.status?.statusMk}</td>}
                {options === true ?
                    <>
                        <td className="tableData">
                            <CrudModal entity={activity}
                                       fixedEntity={activity}
                                       icon="edit"
                                       btnClass="defaultBtnEdit btn-success btn-sm"
                                       title={strings.edit}
                                       fields={{proposedIndicators: this.props.proposedIndicators,
                                           proposedMeasures: this.props.proposedMeasures,
                                           proposedProblems: this.props.proposedProblems,
                                       }}
                                       showText={true}
                                       onSubmit={this.editActivity}
                                       body={ActivityEdit}
                            />
                        </td>
                        <td className="tableData">
                            <DeleteModal btnClass={"defaultBtn ml-1"}
                                         prompt={strings.strategyGoalDeleteQuestion}
                                         showText={true}
                                         doDelete={() => this.deleteActivity(activity.id)}
                            />
                        </td>
                    </> : ""}
            </tr>
        )
    }


}
export default Activity;