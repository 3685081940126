import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {strings} from "../../Localization/Localization";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Css/Rejection.css'
import {toast} from "react-toastify";
import Select from "react-select";

const Rejection = props => {

    const [show, setShow] = useState(false);
    const [areasToReject, setAreasToReject] = useState([])
    const [message,setMessage] = useState("");

    const handleRejection = () => {
        props.handleRejectionUser(message,areasToReject).then(() => {
            setShow(false);
            toast.success(strings.rejectedUser);
        }).catch(() => {
            toast.error(strings.error);
        })
    }
    const handleAreaChanged = (e) => {
        let addedAreas = []
        if (e !== null) {
            e.forEach(item => {
                addedAreas.push(item.value)
            })
        }
        setAreasToReject(addedAreas)
    }

    function handleChange(e){
        setMessage(e.target.value)
    }

    return (
        <Aux>
            <Button className={"btn btn-outline-danger btn-sm ml-2 userRejectionButton"}
                    onClick={() => setShow(true)}><FontAwesomeIcon icon={faMinusCircle} size="lg"
                                                                   style={{paddingRight: '4px'}}/>{strings.rejectUser}</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.rejectUser}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container ml-0"}>
                        {props.prompt}
                    </div>
                    <br/>
                    { props.areasToReject ? (<Select
                        placeholder={""}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isMulti={true}
                        isSearchable={true}
                        options={props.areasToReject}
                        onChange={handleAreaChanged}
                        name={"activityInstitutions"}
                    />) : ''}
                    <br/>
                    <textarea value={message} onChange={handleChange} className="w-100 h-50 mt-3"></textarea>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>{strings.cancel}</Button>
                    <Button className="btn btn-danger" type="submit" onClick={handleRejection}>{strings.rejectUser}</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default Rejection;
