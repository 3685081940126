import React, {Component} from 'react';

class ReportsTable extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default ReportsTable;