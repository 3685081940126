import React from 'react'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPoll,
    faScroll,
    faUserCog,
    faThList,
    faBookmark,
    faPhoneSquare,
    faChartLine,
    faQuestion,
    faFolder, faBuilding
} from "@fortawesome/free-solid-svg-icons";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './Sidebar.css'
import {strings} from "../../Localization/Localization";
import {NavLink} from "react-router-dom";
import NAPRepository from "../../repository/NAPRepository.js"
import InactiveUsersRepository from "../../repository/InactiveUsersRepository";

class SidebarMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: localStorage.getItem('role'),
            userAreas: localStorage.getItem('areas') !== null ? localStorage.getItem('areas').split(';') : null,
            userAreasApproved: localStorage.getItem('areasApproved') !== null ? localStorage.getItem('areasApproved').split(';') : null,
            naps: [],
            inactiveUsers: 0,
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        NAPRepository.getRecentNAPs().then(res => {
            this.setState({
                naps: res.data
            })
        });
        {
            localStorage.getItem('role') && localStorage.getItem('role').includes('ROLE_ADMIN') &&
            InactiveUsersRepository.getAllInactiveSize().then(res => {
                this.setState({
                    inactiveUsers: res.data,
                })
            })
        }
    }

    checkAreas() {
        if(this.state.userAreasApproved.includes("Имотна состојба") || 
        this.state.userAreasApproved.includes("Судир на интереси, забрани и ограничувања") || 
        this.state.userAreasApproved.includes("Лобирање") || 
        this.state.userAreasApproved.includes("Односи со јавност") || 
        this.state.userAreasApproved.includes("Едукација") ||
        this.state.userAreasApproved.includes("Антикорупциска проверка на легислативата") || 
        this.state.userAreasApproved.includes("Јавни дебати") ||
        this.state.userAreasApproved.includes("Интегритет")){
        return true
        }
        return false
    }

    render() {

        let language = localStorage.getItem("activeLanguage");

        let administrationAdmin = [
            {
                label: strings.users,
                imgSrc: 'icon-point-contact.png',
                path: '/users'
            },
            {
                label: strings.institutions,
                imgSrc: 'icon-point-contact.png',
                path: '/institutions'
            },
            {
                label: strings.tags,
                imgSrc: 'icon-profile.png',
                path: '/tags'
            },
            {
                label: strings.institutionCategory,
                imgSrc: 'icon-profile.png',
                path: '/institutioncategory'
            }
        ]

        let announcAdmin = [
            {
                label: strings.mypublications,
                imgSrc: 'icon-documentation.png',
                path: '/mypublications'
            },
            {
                label: strings.allannouncements,
                imgSrc: 'icon-documentation.png',
                path: '/allannouncements'
            },
        ]
        let feedbackAdmin = [
            {
                label: strings.feedbacks,
                imgSrc: 'icon-audit.png',
                path: '/feedbacks'
            }
        ]

        let feedbackUser = [
            {
                label: strings.myfeedbackpublications,
                imgSrc: 'icon-audit.png',
                path: '/myfeedbackpublications'
            }
        ]

        let proactivityAdmin = [
            {
                label: strings.proactiveTransparency,
                imgSrc: 'icon-audit.png',
                path: '/proactivity'
            },
        ]
        let proactivityUser = [
            {
                label: strings.proactiveTransparency,
                imgSrc: 'icon-audit.png',
                path: '/myProactivityPublications'
            },
        ]

        let napsAdmin = []

        napsAdmin.push({
            label: 'Упатство',
            imgSrc: 'icon-audit.png',
            path: '/tutorial'
        })

        napsAdmin.push({
            label: localStorage.getItem('role') === "ROLE_ADMIN" || localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR"
                ? strings.activities : strings.myActivities,
            imgSrc: 'icon-audit.png',
            path: '/myActivities'
        })

        if (localStorage.getItem('role') !== 'ROLE_EVALUATOR' && localStorage.getItem('role') !== 'ROLE_SUPERVISOR'){
            napsAdmin.push({
                label: strings.indicators,
                imgSrc: 'icon-audit.png',
                path: '/indicators'
            })
        }

        if (localStorage.getItem('role') !== 'ROLE_EVALUATOR' && localStorage.getItem('role') !== 'ROLE_SUPERVISOR'){
            napsAdmin.push({
                label: strings.allReports,
                imgSrc: 'icon-audit.png',
                path: '/indicatorReports'
            })
        }

        if (this.state.naps.length > 2 && localStorage.getItem('role') !== 'ROLE_EVALUATOR' && localStorage.getItem('role') !== 'ROLE_SUPERVISOR'){
            napsAdmin.push({
                id: null,
                label: strings.remainingNaps,
                imgSrc: 'icon-audit.png',
                path: '/naps'
            })
        }

        if (localStorage.getItem('role') !== 'ROLE_EVALUATOR'){
            this.state.naps.map((item,index) => {
                napsAdmin.push({
                    id: item.id,
                    label: language === 'mk'? item.nameMk : item.nameAl,
                    imgSrc: 'icon-audit.png',
                    path: `/nap/${item.id}`
                })
            })
        }

        napsAdmin.push({
            label: 'Предлози',
            imgSrc: 'icon-audit.png',
            path: '/propose'
        })

        let crudItems = [
            {
                label: strings.areasOfInterest,
                imgSrc: 'icon-audit.png',
                path: '/areasOfInterest'
            },
            {
                label: strings.statuses,
                imgSrc: 'icon-audit.png',
                path: '/status'
            },
            {
                label: strings.areaTypes,
                imgSrc: 'icon-audit.png',
                path: '/areaType'
            },
            {
                label: strings.napAreas,
                imgSrc: 'icon-audit.png',
                path: '/napArea'
            },
            {
                label: strings.strategyGoals,
                imgSrc: 'icon-audit.png',
                path: '/strategyGoal'
            },
            {
                label: strings.responsibleInstitutions,
                imgSrc: 'icon-audit.png',
                path: '/activityInstitution'
            }
        ]

        let meetingsAdmin = [
            {
                label: strings.allMeetings,
                imgSrc: 'icon-documentation.png',
                path: '/meetings'
            },
        ];

        let meetingsModerator = [
            {
                label: strings.myMeetings,
                imgSrc: 'icon-documentation.png',
                path: '/meetings'
            },
        ];

        let meetingsEvaluator = [
            {
                label: strings.myMeetings,
                imgSrc: 'icon-documentation.png',
                path: '/meetings'
            },
        ];

        let administrationModerator = [
            {
                label: strings.users,
                imgSrc: 'icon-point-contact.png',
                path: '/users'
            },
            {
                label: strings.myInst,
                imgSrc: 'icon-point-contact.png',
                path: '/myInstitution'
            },
        ]

        let administrationEvaluator = [
            {
                label: strings.myInst,
                imgSrc: 'icon-point-contact.png',
                path: '/myInstitution'
            },
        ]

        let announcModerator = [
            {
                label: strings.mypublications,
                imgSrc: 'icon-documentation.png',
                path: '/mypublications'
            },
        ]
        let feedbackModerator = [

            {
                label: strings.myfeedbackpublications,
                imgSrc: 'icon-audit.png',
                path: '/myfeedbackpublications'
            }
        ];

        let activitiesPending = [

            {
                label: strings.activities,
                imgSrc: 'icon-audit.png',
                path: '/'
            }
        ];

        let items = null;

        // TODO Naim - please search for title="Национална стратегија" and translate it

        // Admin menu items
        if (this.state.userRole === "ROLE_ADMIN") {
            items = <>

                <SubMenu className={"submenu"} title={strings.administration} suffix={<Badge pill variant="danger">Admin</Badge>}
                         icon={<FontAwesomeIcon icon={faUserCog}/>}>
                    {administrationAdmin.map((item, index) => {
                        return (<MenuItem key={index}
                                          suffix={item.label === "Корисници" && this.state.inactiveUsers > 0 ?
                                              <span style={{color: 'red', fontWeight: '600'}}>{this.state.inactiveUsers}</span>
                                              : item.label === "Корисници" && this.state.inactiveUsers === 0 ?
                                                  <span style={{color: 'green', fontWeight: '600'}}>{this.state.inactiveUsers}</span> : ''}>
                                        <NavLink to={item.path}>
                                            {item.label}
                                        </NavLink>
                                    </MenuItem>)
                                })}
                            </SubMenu>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.proactiveTransparency} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {proactivityAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title="Национална стратегија" icon={<FontAwesomeIcon icon={faThList}/>}>
                    {napsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu className={"submenu"} title={strings.nomenclatures} icon={<FontAwesomeIcon icon={faBookmark}/>}>
                    {crudItems.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>
                    {meetingsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion}/>}
                    to='/Question'
                >
                    {strings.chatRoom}
                    <NavLink
                        to="/Question"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faFolder}/>}
                    to='/usefulDocuments'
                >
                    {strings.usefulDocuments}
                    <NavLink
                        to="/usefulDocuments"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faBuilding}/>}
                    to='/crmEntries'
                >
                    {strings.crmSynchronization}
                    <NavLink
                        to="/crmEntries"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

            </>
        }

        if (this.state.userRole === "ROLE_ADMINISTRATIVE_OBSERVER") {
            items = <>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.proactiveTransparency} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {proactivityAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title="Национална стратегија" icon={<FontAwesomeIcon icon={faThList}/>}>
                    {napsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu className={"submenu"} title={strings.nomenclatures} icon={<FontAwesomeIcon icon={faBookmark}/>}>
                    {crudItems.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>
                    {meetingsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faFolder}/>}
                    to='/usefulDocuments'
                >
                    {strings.usefulDocuments}
                    <NavLink
                        to="/usefulDocuments"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

            </>
        }
        if (this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR"){
            items=<>
                <SubMenu className={"submenu"} title={strings.administration} icon={<FontAwesomeIcon icon={faUserCog}/>}
                suffix={<Badge pill variant="warning">Moderator</Badge>}>
                    {administrationModerator.map((item, index) => {
                       return (<MenuItem key={index}>
                           <NavLink to={item.path}>
                                {item.label}
                           </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                {this.state.userAreasApproved.includes("Национална стратегија") &&
                <>
                <SubMenu title="Национална стратегија" icon={<FontAwesomeIcon icon={faThList}/>}>
                    {napsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                </>
                }
                {this.checkAreas() &&
                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                }
                {this.state.userAreasApproved.includes("Интегритет") &&
                <SubMenu title={strings.proactiveTransparency} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {proactivityUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                }

                <SubMenu className={"submenu"} title={strings.nomenclatures} icon={<FontAwesomeIcon icon={faBookmark}/>}>
                    {crudItems.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>
                    {meetingsModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion}/>}
                    to='/Question'
                >
                    {strings.chatRoom}
                    <NavLink
                        to="/Question"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faFolder}/>}
                    to='/usefulDocuments'
                >
                    {strings.usefulDocuments}
                    <NavLink
                        to="/usefulDocuments"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

            </>
        }

        if ((this.state.userRole === "ROLE_EVALUATOR")) {
            items = <>

<           SubMenu title={strings.administration} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {administrationEvaluator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                {this.state.userAreasApproved.includes("Национална стратегија") &&
                <>
                <SubMenu title="Национална стратегија" icon={<FontAwesomeIcon icon={faThList}/>}>
                    {napsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                </>
                }
                {this.checkAreas() &&
                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                }
                {this.state.userAreasApproved.includes("Интегритет") &&
                <SubMenu title={strings.proactiveTransparency} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {proactivityUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                }


                <SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>
                    {meetingsEvaluator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion}/>}
                    to='/Question'
                >
                    {strings.chatRoom}
                    <NavLink
                        to="/Question"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faFolder}/>}
                    to='/usefulDocuments'
                >
                    {strings.usefulDocuments}
                    <NavLink
                        to="/usefulDocuments"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
            </>
        }
        
        if (this.state.userRole === "ROLE_SUPERVISOR") {
            items = <>

                <SubMenu title="Национална стратегија" icon={<FontAwesomeIcon icon={faThList}/>}>
                    {napsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}>
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion}/>}
                    to='/Question'
                >
                    {strings.questions}
                    <NavLink
                        to="/Question"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                <MenuItem
                    icon={<FontAwesomeIcon icon={faFolder}/>}
                    to='/usefulDocuments'
                >
                    {strings.usefulDocuments}
                    <NavLink
                        to="/usefulDocuments"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

                {/*<SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>*/}
                {/*    {meetingsEvaluator.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}>*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}
            </>
        }

        if (this.state.userRole === "ROLE_USER_PENDING") {
            items = <>
            <SubMenu className={"submenu"} title={strings.activities} icon={<FontAwesomeIcon icon={faChartLine}/>}>
                {activitiesPending.map((item, index) => {
                    return (<MenuItem key={index}>
                        <NavLink to={item.path}>
                            {item.label}
                        </NavLink>
                    </MenuItem>)
                })}
            </SubMenu>
            <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                {announcModerator.map((item, index) => {
                    return (<MenuItem key={index}>
                        <NavLink to={item.path}>
                            {item.label}
                        </NavLink>
                    </MenuItem>)
                })}
            </SubMenu>

            <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                {feedbackModerator.map((item, index) => {
                    return (<MenuItem key={index}>
                        <NavLink to={item.path}>
                            {item.label}
                        </NavLink>
                    </MenuItem>)
                })}
            </SubMenu>

            <SubMenu className={"submenu"} title={strings.meetings} icon={<FontAwesomeIcon icon={faPhoneSquare}/>}>
                {meetingsEvaluator.map((item, index) => {
                    return (<MenuItem key={index}>
                        <NavLink to={item.path}>
                            {item.label}
                        </NavLink>
                    </MenuItem>)
                })}
            </SubMenu>
        </>
        }


        return (
            <ProSidebar collapsed={this.props.openSidebar}>
                <SidebarContent>
                    <Menu iconShape="circle">
                        {items}
                    </Menu>
                </SidebarContent>

                <SidebarFooter style={{ textAlign: 'center' }}>


                    <div className="sidebar-btn-wrapper" >
                        <NavLink
                            className="sidebar-btn"
                            to={'/FAQ'}
                            >
                            <div style={{background: "#67C4EF", borderRadius: "100%",
                                paddingTop: "20px", paddingBottom: "20px",
                                paddingLeft: "40px", paddingRight: "40px"}}>
                                <div style={{background: "#67C4EF", borderRadius: '14px'}}>
                                    <span style={{color: "black", fontWeight: "bold"}} className="mt-2 mb-2">{strings.faq}</span>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                </SidebarFooter>

            </ProSidebar>
        );
    }
}
export default SidebarMenu;
