import React, {Component} from "react";
import {strings} from "../../Localization/Localization";
import './Propose.css';
import NapArea from "./NapArea";
import ProposeRepository from "../../repository/ProposeRepository";
import StrategyGoal from "./StrategyGoal";
import Problem from "./Problem/Problem";
import Measure from "./Measure/Measure";
import Activity from "./Activity/Activity";
import Indicator from "./Indicator/Indicator";

const tabOptions = {
    napArea: 'PROPOSE_NAP_AREA',
    problem: 'PROPOSE_PROBLEM',
    measure: 'PROPOSE_MEASURE',
    activity: 'PROPOSE_ACTIVITY',
    indicator: 'PROPOSE_INDICATOR'
}

class ProposePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: tabOptions.napArea,
            proposals: {
                napAreas: [],
                strategyGoals: [],
                problems: [],
                proposedProblem: [],
                measures: [],
                proposedMeasures: [],
                activities: [],
                proposedActivities: [],
                indicators: [],
                proposedIndicators: [],
            }
        }
    }

    componentDidMount() {
        ProposeRepository.getUserProposals().then(res => {
            this.setState({
                proposals: {
                    napAreas: res.data?.napAreas,
                    strategyGoals: res.data?.strategyGoals,
                    problems: res.data?.problems,
                    proposedProblems: res.data?.proposedProblems,
                    measures: res.data?.measures,
                    proposedMeasures: res.data?.proposeMeasures,
                    activities: res.data?.activities,
                    proposedActivities: res.data?.proposeActivities,
                    indicators: res.data?.indicators,
                    proposedIndicators: res.data?.proposeIndicators,
                }
            })
        })
    }

    getTab (tab) {
        return (
            <li className="nav-item">
                <a className={`nav-link ${this.state.selectedTab === tab ? 'active' : ''}`}
                   aria-current="page"
                   href="#"
                   onClick={() => this.setState({
                       selectedTab: tab
                   })}>
                    {strings[tab]}
                </a>
            </li>
        )
    }

    render() {
        return (
            <div style={{marginTop: '15px'}}>
                <h2 style={{textAlign: "center", color: "#1C4857"}}
                    className="mt-4 mb-3">{strings.yourProposals}</h2>
                <ul className="nav nav-tabs nav-fill nav-justified">
                    {this.getTab(tabOptions.napArea)}
                    {this.getTab(tabOptions.problem)}
                    {this.getTab(tabOptions.measure)}
                    {this.getTab(tabOptions.activity)}
                    {this.getTab(tabOptions.indicator)}
                </ul>
                <div>
                    {this.state.selectedTab === tabOptions.napArea ?
                        <NapArea napAreas={this.state.proposals?.napAreas}/> : ''}

                    {this.state.selectedTab === tabOptions.problem ? <Problem problems={this.state.proposals?.problems}
                                                                              proposedProblems={this.state.proposals?.proposedProblems}
                                                                              proposedNapAreas={this.state.proposals?.napAreas}
                                                                              proposedStrategyGoals={this.state.proposals?.strategyGoals}/> : ''}
                    {this.state.selectedTab === tabOptions.measure ? <Measure measures={this.state.proposals?.measures}
                                                                              proposedMeasures={this.state.proposals?.proposedMeasures}
                                                                              proposedActivities={this.state.proposals?.proposedActivities}
                                                                              proposedProblems={this.state.proposals?.proposedProblems}/> : ''}
                    {this.state.selectedTab === tabOptions.activity ? <Activity activities={this.state.proposals?.activities}
                                                                                proposedActivities={this.state.proposals?.proposedActivities}
                                                                                proposedIndicators={this.state.proposals?.proposedIndicators}
                                                                                proposedMeasures={this.state.proposals?.proposedMeasures}
                                                                                proposedProblems={this.state.proposals?.proposedProblems}
                    /> : ''}
                    {this.state.selectedTab === tabOptions.indicator ? <Indicator indicators={this.state.proposals?.indicators}
                                                                                  proposedIndicators={this.state.proposals?.proposedIndicators}
                                                                                  proposedActivities={this.state.proposals?.proposedActivities}
                                                                                  activities={this.state.proposals?.activities}
                                                                                  proposedMeasures={this.state.proposals?.proposedMeasures}
                                                                                  proposedProblems={this.state.proposals?.proposedProblems}
                    /> : ''}
                </div>
            </div>
        )
    }
}

export default ProposePage;