import React, { Component } from 'react';
import '../../assets/css/theme/base.css';
import agencyFullLogo from "../../assets/images/dksk_full_logo.png";
import PendingUserModal from '../Crud/PendingUserModal';
import UserCheckModal from '../Crud/UserCheckModal';
import UserRepository from '../../repository/UserRepository';
import { toast } from 'react-toastify'
import { strings } from '../../Localization/Localization';

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pendingUserData: [],
            usersToCheck: []
        }
    }

    componentDidMount() {
        if (localStorage.getItem('role') === 'ROLE_USER_PENDING' || localStorage.getItem('role') === 'ROLE_EVALUATOR') {
            UserRepository.registrationData().then(data => {
                let areasForPending = data.data.areaOfInterestShortDtoList.filter(item => {
                    return item.name !== "Администратор"
                })
                this.setState({
                    pendingUserData: areasForPending
                })
            })
        }
        if (localStorage.getItem("firstLogin") == "true" && localStorage.getItem('role') === 'ROLE_EVALUATOR' && localStorage.getItem("areasApproved") != "") {
            UserRepository.getUsersForChecking().then(data => {
                this.setState({
                    usersToCheck: data.data
                })
            })
        }
    }

    areasChanged = (areas, file,embg) => {
        const form = new FormData();
        form.append("email", localStorage.getItem("email"))
        form.append("area", areas)
        embg !== "" && form.append("embg", embg)
        if (file !== null)
            form.append("file", file)
        UserRepository.setAreas(form).then(resp => {
            toast.success(strings.success)
        }).catch(error => {
            toast.error(toast.error)
        })
    }

    checkUsers = (users) => {
        UserRepository.checkUsers(users).then(resp => {
            toast.success(strings.success)
            localStorage.setItem("firstLogin", false)
        }).catch(error => {
            toast.error(toast.error)
        })
    }

    renderCheckbox = () => {
        const options = this.state.usersToCheck.map(item => (
            <div key={item.id}>
                <input name={item.name}
                    value={item.id}
                    type="checkbox"
                    checked={false}
                />
                <label className="form-check-label ml-1">{item.firstName + " " + item.lastName}</label>
            </div>
        ))
        return options
    }

    render() {
        return (
            <div>
                <div className='col-md-2 float-right'>
                    {(localStorage.getItem('role') === 'ROLE_USER_PENDING' || localStorage.getItem('role') === 'ROLE_EVALUATOR') &&
                        <PendingUserModal
                            userData={this.state.pendingUserData}
                            onSubmit={this.areasChanged}
                        />
                    }
                    {(localStorage.getItem("firstLogin") == "true" && localStorage.getItem('role') === 'ROLE_EVALUATOR' && localStorage.getItem("areasApproved") != "") &&
                        <UserCheckModal
                            onSubmit={this.checkUsers}
                            userData={this.state.usersToCheck}
                        ></UserCheckModal>
                    }
                </div>
                <br />
                <img className='d-block pb-3' src={agencyFullLogo} style={{ width: "30%", height: "50%", marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }} />
                <div className='col-12 text-center'>
                    <span style={{ fontSize: "2rem" }}>Добредојдовте на порталот на <br />Државна комисија за спречување на корупцијата</span>
                </div>
            </div>
        );
    }
}

export default Home;