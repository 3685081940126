import axios from '../axios/app';

const UsefulDocumentRepository = {

    getAll:()=>{
        return axios.get(`/rest/usefulDocument/all`)
    },
    download:(id)=> {
        return axios.get(`/rest/usefulDocument/download/${id}`)
    },
    upload:(form)=>{
        return axios.post("/rest/usefulDocument",form)
    },
    delete:(id)=>{
        return axios.delete(`/rest/usefulDocument/${id}`)
    }

};

export default UsefulDocumentRepository;