import React, { Component } from 'react';
import '../../assets/css/theme/base.css'
import UserRepository from '../../repository/UserRepository';
import { toast } from "react-toastify";
import { strings } from '../../Localization/Localization';

class ApprovedDirector extends Component {

    constructor(props){
        super(props)
    }

    respondUser = (toApprove) => {
        if(toApprove){
            UserRepository.approveDirector(this.props.match.params.userId, this.props.match.params.areaId).then(res => {
                toast.success(strings.successActivation, {
                    duration:2000
                })
                this.props.history.push(`/login}`);
            }).catch( () =>
                toast.error(strings.failedActivation, {
                    duration:2000
                })
            )
        }
        else{
            UserRepository.rejectDirector(this.props.match.params.userId, this.props.match.params.areaId, "").then(res => {
                toast.success(strings.successRejection, {
                    duration:2000
                })
                this.props.history.push(`/login}`);
            }).catch( () =>
                toast.error(strings.failedRejection, {
                    duration:2000
                })
            )
        }
    }

    render(){
        return(
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"100%"}}>
                <label style={{fontSize:"2em"}}>Одберете опција:</label>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"20%", width:"30%"}}>
                    <button type="button" className='btnDirector btn btn-brand btn-outline-success btn-pill btn-elevate' onClick={() => (this.respondUser(true))}>Прифати</button>
                    <button type="button" className='btnDirector btn btn-brand btn-outline-danger btn-pill btn-elevate' onClick={() => (this.respondUser(false))}>Одбиј</button>
                </div>

            </div>
        )
    }

}

export default ApprovedDirector;
