import React from "react";
import {strings} from "../../Localization/Localization";

const EvaluationOpenClose = (props) => {
    return(
        <div>
            <h5 style={{color: "black"}}>
                {strings.doYouWantToCloseEvaluation}
            </h5>
        </div>
    )
}
export default EvaluationOpenClose;