import React, {Component} from "react";
import {strings} from "../../Localization/Localization";
import IndicatorReportRealRepository from "../../repository/IndicatoReportRealRepository";
import CrudModal from "../Crud/CrudModal";
import IndicatorReportRealUpdate from "./IndicatorReportRealUpdate";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import IndicatorReportRealReturned from "./IndicatorReportRealReturned";

class IndicatorReportRealTable extends Component{

    constructor(props) {
        super(props);

        this.state = {
            indicatorReportsReal: [],
            statuses: [],
            statusUpdated: false,
        }
    }

    componentDidMount() {
        this.fetchIndicatorReportsReal(this.props.indicator.id);
        this.fetchIndicatorReportsRealStatuses();
    }

    fetchIndicatorReportsReal = id => {
        IndicatorReportRealRepository.getAllIndicatorReportRealByIndicator(id).then( res => {
            this.setState({
                indicatorReportsReal: res.data.sort(function(a,b) {
                    return b.id - a.id
                }),
            })
        }).catch(err => console.log(err));
    }

    fetchIndicatorReportsRealStatuses = () => {
        IndicatorReportRealRepository.getAllIndicatorReportsRealStatuses().then( res => {
            this.setState({
                statuses: res.data,
            })
        }).catch(err => console.log(err));
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    changeStatus = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        IndicatorReportRealRepository.changeIndicatorReportRealStatus(value, id).then( res => {
            this.fetchIndicatorReportsReal(this.props.indicator.id);
            toast.success(strings.editModal)
        }).catch( err => {
            console.log(err)
            toast.error(strings.editAnnouncementFailed);
        })
    }

    onAddIndicatorReportReal = entity => {
        IndicatorReportRealRepository.updateIndicatorReportReal(entity.counter ? entity.counter : 0, entity.finished, entity.id).then( async res => {
            toast.success(strings.editModal)
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            console.log(err);
            toast.error(strings.editAnnouncementFailed);
            await this.sleep(500);
            window.location.reload();
        })
    }

    updateReadStatus = id => {
        IndicatorReportRealRepository.updateReadStatus(id).then( () => {
            this.fetchIndicatorReportsReal(this.props.indicator.id);
            this.setState({
                statusUpdated: true,
            })
        }).catch( err => {
            console.log(err);
        });
    }

    submitReturnedReported = entity => {
        return IndicatorReportRealRepository.indicatorReturnedForCompletion(entity.id, entity.message).then( () => {
            toast.success(strings.success);
            this.fetchIndicatorReportsReal(this.props.indicator.id);
            this.setState({
                statusUpdated: true,
            })
        }).catch( err => {
            if(err.response.data.message === "Email not sent"){
                toast.error(strings.failedToSendMail)
            }
            else toast.error(strings.failure);
            console.log(err);
        });
    }

    render(){

        return(
            <table className="table-hover newTable mt-2" style={{width: "100%", marginLeft: "13px"}}>
                <thead className="tableHead">
                <tr>
                    <th className="tableHeading firstHeading" style={{width: "15%"}}>{strings.indicatorType}</th>
                    {/*<th className="tableHeading" style={{width: "20%"}}>{strings.nameOfReportAl}</th>*/}
                    <th className="tableHeading" style={{width: "15%"}}>{strings.report}</th>
                    <th className="tableHeading" style={{width: "15%"}}>{strings.dateUpdated}</th>
                    <th className="tableHeading" style={{width: "15%"}}>{strings.reportStatus}</th>
                    <th className="tableHeading" style={{width: "15%"}}>{strings.evaluation}</th>
                    <th className="tableHeading" style={{width: "15%"}}>{strings.readByEvaluator}</th>
                    <th className="tableHeading" style={{width: "15%"}}>{strings.readByModerator}</th>
                    <th className="tableHeading lastHeading" style={{width: "30%"}}>{strings.actions}</th>
                </tr>
                </thead>
                <tbody>
                {this.state.indicatorReportsReal.map((item,index) => {

                    if (item.evaluation.open && item.evaluation.id === this.props.evaluation.id && this.state.statusUpdated === false){
                        this.updateReadStatus(item.id);
                    }

                    return(
                        <tr key={item.id} style={{border: "1px solid lightgray"}}>
                            <td className="tableData firstData">
                                {item.indicator.indicatorType === 'BOOLEAN' ? 'Завршен/незавршен' : 'Нумерички'}
                            </td>
                            <td className="tableData ml-2">
                                {item.counter !== null ? item.counter :
                                    (item.finished ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                        : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>)
                                }
                            </td>
                            <td className="tableData">
                                {item.dateUpdated? item.dateUpdated.slice(0,10) : ''}
                            </td>
                            <td className="tableData tableStatus">
                                {item.status ? item.status.statusMk : ''}
                            </td>
                            <td className="tableData">
                                {item.evaluation.descriptionMk}
                            </td>
                            <td className="tableData">
                                {item.readByEvaluator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                    : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                            </td>
                            <td className="tableData">
                                {item.readByModerator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                    : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                            </td>
                            <td className="tableData">
                                {(localStorage.getItem('role') === 'ROLE_ADMIN' || localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR')
                                    && item.evaluation.open && item.evaluation.id === this.props.evaluation.id ?
                                        (this.state.statuses.map((status,index) => {
                                            if (status.statusMk === 'Вратен на доработка') {
                                                return (
                                                    <CrudModal
                                                        entity={{id: item.id}}
                                                        icon={''}
                                                        btnClass={'btn-primary m-1'}
                                                        title={status.statusMk}
                                                        showText={true}
                                                        onSubmit={this.submitReturnedReported}
                                                        body={IndicatorReportRealReturned}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <button id={item.id} value={status.id} onClick={this.changeStatus}
                                                            type="button" className="btn btn-primary m-1" style={{borderRadius: '10px'}}>
                                                        {status.statusMk}
                                                    </button>
                                                )
                                            }
                                        }))
                                    : ''
                                }
                                {localStorage.getItem('role') === 'ROLE_EVALUATOR'
                                && item.evaluation.open
                                && item.evaluation.id === this.props.evaluation.id
                                && item.status?.statusMk !== "Поднесен"
                                && item.status?.statusMk !== "Прифатен"
                                && localStorage.getItem('institutionId') === item.indicator?.activity?.competentInstitution?.institution?.id.toString()
                                    ?
                                    <CrudModal entity={{id: item.id, finished: item.finished, counter: item.counter,
                                                        indicator: item.indicator }}
                                               contentClass={'smallEditModal'}
                                               icon={'edit'}
                                               btnClass={'editIndicatorBtn'}
                                               title={strings.editIndicatorReport}
                                               showText={true}
                                               onSubmit={this.onAddIndicatorReportReal}
                                               body={IndicatorReportRealUpdate}
                                               confirmModal
                                               confirmMessage={"По поднесување на индикаторот истиот не можете да го измените. Дали сте сигурни дека сакате да го поднесете индикаторот?"}
                                    />
                                    : ''
                                }
                            </td>
                        </tr>
                    )
                })}
                <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                    <td style={{borderRadius: "0 0 0 15px"}}/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td style={{borderRadius: "0 0 15px 0"}}/>
                </tr>
                </tbody>

            </table>
        )
    }
}

export default IndicatorReportRealTable;