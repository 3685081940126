import React from "react";

export default function IndicatorReportEvaluatorStatusView(props) {

    return (
        <table className="mt-2 mb-5 table" style={{border: "1px solid black"}}>
            <thead>
            <tr>
            <th>Статуси на активноста за тековната евалуација</th>
            <th>
                2020
            </th>
            <th>
                2021
            </th>
            <th>
                2022
            </th>
            <th>
                2023
            </th>
            <th>
                2024
            </th>
            <th>
                2025
            </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    Неподнесени
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.koordinatorNepodneseni || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.koordinatorNepodneseni || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.koordinatorNepodneseni || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.koordinatorNepodneseni || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.koordinatorNepodneseni || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.koordinatorNepodneseni || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Во тек
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.koordinatorVoTek || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.koordinatorVoTek || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.koordinatorVoTek || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.koordinatorVoTek || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.koordinatorVoTek || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.koordinatorVoTek || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Нереализирани
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.koordinatorNerealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.koordinatorNerealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.koordinatorNerealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.koordinatorNerealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.koordinatorNerealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.koordinatorNerealiziran || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Реализирани
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.koordinatorRealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.koordinatorRealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.koordinatorRealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.koordinatorRealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.koordinatorRealiziran || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.koordinatorRealiziran || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Вкупно
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.totalReports || 0}
                </td>
            </tr>
            </tbody>
        </table>
    )
}