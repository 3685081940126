import React, { Component } from "react";
import { strings } from "../../Localization/Localization";
import Activation from "./Activation";
import Rejection from "./Rejection"
import { toast } from "react-toastify";
import './Css/InactiveUsers.css'
import {Button, FormControl, InputGroup} from "react-bootstrap";
import UserRepository from "../../repository/UserRepository";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import UserAreaOfInterestRepository from "../../repository/UserAreaOfInterestRepository";
import AreaOfInterestRepository from "../../repository/AreaOfInterestRepository";


class PendingUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listUsers: [],
            instName: "",
            pageCount: 1,
            searchName: "",
            searchInstitution: "",
            searchFirstName: "",
            searchLastName: "",
            usersAreaOfInterests: [],
            moderatorAreaOfInterest: localStorage.getItem("areas").trim(),
            allAreasOfInterest: [],
            isAdmin: localStorage.getItem("role") === 'ROLE_ADMIN' ? true : false,
            areasOfLoggedInUser: localStorage.getItem("areas")
        }
    }

    getAllAreasOfInterest = () => {
        AreaOfInterestRepository.findAllActive().then((res) => {
            this.setState({allAreasOfInterest: res.data})
        })
    }
    getAllPendingUsers = (selectedPage = 0, size=5) => {
        const areas = !this.state.isAdmin ? this.state.areasOfLoggedInUser : null
        UserRepository.findUsersPending(selectedPage, size, areas, this.state.searchFirstName, this.state.searchLastName, this.state.searchInstitution).then(res => {
            this.setState({
                listUsers: res.data.content,
                pageCount: res.data.totalPages,
            })
        }).catch((err) => {
            console.log(err)
        })

    }

    handlePageClick = data => {
        this.getAllPendingUsers(data.selected);
    };

    handleInstitutionName = (e) => {
        this.setState({
            searchInstitution: e.target.value
        }, () => {
            this.getAllPendingUsers();
        })
    }

    handleFirstAndLastName = (e) => {
        let firstAndLastName = e.target.value.split(" ");
        let firstName = firstAndLastName[0];
        let lastName = firstAndLastName[1];
        if (lastName === undefined) {
            lastName = firstName;
        }

        this.setState({
            searchFirstName: firstName,
            searchLastName: lastName
        }, () => {
            this.getAllPendingUsers()
        })
    }

    getAreasOfInterest = user => {
        let aoi = ""
        this.state.listUsers.forEach(item => {
            if(item.user.id === user.id){
                aoi+=item.areaOfInterest.nameMk+", "
            }
        })
        if(aoi !== undefined){
            return aoi.substring(0, aoi.length-2)
        }
    }


    componentDidMount() {
        this.getAllPendingUsers();
        this.getAllAreasOfInterest();
    }

    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{ textAlign: "left", color: "#1C4857" }}
                            className="mt-4 mb-4">{strings.unapprovedUsers}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4" style={{paddingLeft: "1rem"}}>

                    <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.firstlastname}
                                onChange={this.handleFirstAndLastName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>


                    </div>
                    <div className="col-4">

                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchInstitution}
                                disabled={false}
                                placeholder={strings.institution}
                                onChange={this.handleInstitutionName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} 
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-4">
                    <Link to={"/users"}
                            className="defaultBtn btn btn-dark mb-2 btn btn-dark mr-1 float-right"><FontAwesomeIcon
                                icon={faArrowLeft}
                                size="lg"
                                style={{ paddingRight: '4px' }} />{strings.goBack}
                        </Link>
                    </div>

                </div>



                <table className="table-hover newTable" style={{ width: "100%" }}>
                    <thead className="tableHead">
                        <tr>
                        <th className="tableHeading firstHeading" style={{ width: '10%' }}>{strings.firstName}</th>
                        <th className="tableHeading" style={{ width: '10%' }}>{strings.lastName}</th>
                        <th className="tableHeading" style={{ width: '15%' }}>{strings.institution}</th>
                        <th className="tableHeading" style={{ width: '15%' }}>{strings.areasOfInterest}</th>
                        <th className="tableHeading" style={{ width: '10%' }}>{strings.documentSolution}</th>
                        <th className="tableHeading" style={{ width: '15%' }}>{strings.dateCreated}</th>
                        <th className="tableHeading lastHeading text-center" style={{ width: '25%' }}>{strings.actions}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.listUsers.map(this.renderUsers)}
                    <tr style={{ width: "100%", height: "5px", background: "#67C4EF" }}>
                        <td style={{ borderRadius: "0 0 0 15px" }} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td style={{ borderRadius: "0 0 15px 0" }} />
                    </tr>
                    </tbody>
                    
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        )
    }

    renderUsers = userItem => {
        let user = userItem.user;
        let instNameMk = user.institution ? user.institution.nameMk : "";
        let instNameAl = user.institution ? user.institution.nameAl : "";
        let areaNameMk = userItem.listAreasOfInterest.map(item => ({name : item.nameMk}));
        let areaNameAl = userItem.listAreasOfInterest.map(item => ({name : item.nameAl}));
        let areasToActivate = []
        let areasToReject = []
        if(localStorage.getItem("role") == "ROLE_ADMIN"){
            areasToActivate = userItem.listAreasOfInterest.map(item =>({name:item.nameMk, label:item.nameMk, value: item.id}))
            areasToReject = areasToActivate
        }
        else{
            userItem.listAreasOfInterest.forEach(item => {
                if(localStorage.getItem("areas").includes(item.nameMk) && item.nameMk !== "Директор")
                {
                    areasToActivate.push({name:item.nameMk, label:item.nameMk, value: item.id})
                    areasToReject.push({name:item.nameMk, label:item.nameMk, value: item.id})
                }
            })
        }

        return (
            <tr key={user.id} style={{ border: "1px solid lightgray" }}>
                <td className="tableData" style={{ color: 'black', fontSize: '15px' }}>{user.firstName}</td>
                <td className="tableData" style={{ color: 'black', fontSize: '15px' }}>{user.lastName}</td>
                <td className="tableData"
                    style={{
                        color: 'black',
                        fontSize: '15px'
                    }}>
                    {localStorage.getItem("activeLanguage") === "mk" ? instNameMk : instNameAl}
                </td>
                <td className="tableData" style={{ color: 'black', fontSize: '15px' }}>
                    {areaNameMk.length > 0 ? (localStorage.getItem("activeLanguage") === "mk" ?
                        areaNameMk.map(item => {return <p>{item.name}</p>}) :
                        areaNameAl.map(item => {return <p>{item.name}</p>})) : '/'}</td>
                <td className="tableData" >
                    {user.attachment ?
                        <a href={process.env.REACT_APP_HOST_ENV + "rest/attachment/download/" + user.attachment.id} // TODO path should be changed later.
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}>
                            {user.attachment.name}
                        </a>
                        : strings.noDocumentAttached}
                </td>

                <td className="tableData" style={{ color: 'black', fontStyle: 'bold' }}>{user.dateCreated}</td>
                <td className="tableData" style={{ color: 'black', fontWeight: 'bold',  display: "container" }}>
                    <Activation
                        handleActivationUser={(areasToActivate) => this.handleActivationUser(user.id,areasToActivate)}
                        areasToActivate={areasToActivate}
                        prompt={strings.userActivate}
                    />
                    
                    <Rejection
                        handleRejectionUser={(message,areasToReject) => this.handleRejectionUser(user.id,areasToReject,message)}
                        areasToReject={areasToReject}
                        prompt={strings.userReject}
                    />
                </td>
            </tr>
        )
    }

    handleActivationUser = (userId,areasToActivate) => {
        return UserRepository.approveUser(userId,areasToActivate).then(() => {
            this.getAllPendingUsers();
            toast.success(strings.activatedUser, {
                duration:2000
            });
            window.location.reload();
        }).catch(() => {
            toast.error(strings.deactivatedUser);
        })
    }

    handleRejectionUser = (userId,areaToReject,message) => {
        return UserRepository.rejectUser(userId,areaToReject,message).then(() => {
            toast.success(strings.rejectUser, {
                duration:2000
            });
            window.location.reload();
        }).catch((err) => {
            toast.error(strings.error);
        })
    }

}

export default PendingUsers;
