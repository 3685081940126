import axios from '../axios/app';

const UserAreaOfInterestRepository = {

    findAreaOfInterestPending: (userIds) =>{
        return axios.get(`/rest/user/findAreaOfInterestPending?listUsers=${userIds}`)
    },

    findAllByUserId: (userId) => {
        return axios.get(`/rest/userAreaOfInterest/getByUserId/${userId}`)
    }

};

export default UserAreaOfInterestRepository;