import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarPlus, faEdit, faReply, faUser} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../Crud/DeleteModal";
import {strings} from "../../Localization/Localization";
import UnDeleteModal from "../Crud/UnDeleteModal";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

export default function QuestionMessage({
                                            message,
                                            username,
                                            isAdmin,
                                            isUser,
                                            deleteMessage,
                                            unDeleteMessage,
                                            showCommentSection,
                                            handleMessageChange,
                                            addReplyMessage,
                                            childMessage,
                                            messageTexts,
                                            number
                                        }) {


    return (
        <div id={message.id} className={`${!childMessage ? 'container' : ''} openedAnnouncementAddCommentContainer mt-3 mb-3`}
             style={{width: '100%'}}>
            <div className="col-12 mt-3">
                <div className="row" key={message.id}>
                    <div className="col-12 mt-3">
                        <h5 style={{wordWrap: "break-word"}}>{message.body}</h5>
                        <hr/>
                        <p className="mr-3 openedAnnouncementInfo"
                           style={{float: 'left', color: '#343a40'}}>
                            <FontAwesomeIcon icon={faUser} size="lg"
                                             style={{paddingRight: '4px'}}/>
                            {message.author?.email}
                        </p>
                        <p className="mr-3 openedAnnouncementInfo"
                           style={{float: 'left', color: '#343a40'}}>
                            <FontAwesomeIcon icon={faCalendarPlus} size="lg"
                                             style={{paddingRight: '4px'}}/>
                            {message.dateUpdated}
                        </p>
                        {(message.active) && ((message.author.email === username) || (isAdmin || isUser)) &&
                            <DeleteModal btnClass={"ml-1 QuestionAdminActionButton"}
                                         showText={true}
                                         prompt={strings.deleteMessageForMessage}
                                         doDelete={() => deleteMessage(message.id)}/>}
                        {(!message.active) && ((message.author.email === username) || (isAdmin || isUser)) &&
                            <UnDeleteModal btnClass={"ml-1 QuestionAdminActionButton"}
                                           showText={true}
                                           prompt={strings.shareMessageForMessage}
                                           doDelete={() => unDeleteMessage(message.id)}/>}

                        {(message.active) && ((message.author.email === username) || (isAdmin || isUser)) &&
                            <Link to={{
                                pathname: `/EditMessage`,
                                state: {
                                    message: message
                                }
                            }}
                            >
                                <button type="button"
                                        className="btn btn-info btn-sm QuestionAdminActionButton">
                                    <FontAwesomeIcon icon={faEdit} size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {strings.edit}
                                </button>
                            </Link>}
                        <button className="btn btn-success btn-sm mb-3"
                                style={{float: 'right', width: '100px', height: '35px'}}
                                onClick={() => showCommentSection(message.id)}
                        >
                            <FontAwesomeIcon
                                icon={faReply} size="lg"
                                style={{paddingRight: '4px'}}/>
                            Одговори
                        </button>

                        <div className="container showComment"
                             style={{display: 'none'}}>
                            <div className="row w-100 mr-3 ml-3 mt-2 mb-2">
                                <div className="input-group">
                                    <input type="text"
                                           key="reply"
                                           className="form-control openedAnnouncementAddCommentTextInput"
                                           placeholder={strings.commentPlaceholder}
                                           name={"body"}
                                           onChange={(e) => handleMessageChange(e, "reply"+number)}
                                           value={messageTexts["reply"+number] ?? ""}
                                    />
                                    <span className="input-group-btn">
                                                                        <button
                                                                            className="btn btn-primary openedAnnouncementAddCommentButton"
                                                                            type="button"
                                                                            onClick={() => addReplyMessage("reply"+number)}
                                                                        >
                                                                            {strings.comment}</button>
                                                                    </span>
                                </div>
                            </div>
                        </div>
                        {message.childMessages?.map((childMessage,index) =>
                            <QuestionMessage message={childMessage}
                                             username={username}
                                             isAdmin={isAdmin}
                                             isUser={isUser}
                                             deleteMessage={deleteMessage}
                                             unDeleteMessage={unDeleteMessage}
                                             showCommentSection={showCommentSection}
                                             handleMessageChange={handleMessageChange}
                                             addReplyMessage={addReplyMessage}
                                             childMessage
                                             messageTexts={messageTexts}
                                             number={index}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}