import React, {useEffect, useState} from "react";
import "./css/ActivityView.css"
import {strings} from "../../Localization/Localization";
import IndicatorReportRepository from "../../repository/IndicatorReportRepository";
import {toast} from "react-toastify";
import IndicatorReportRealRepository from "../../repository/IndicatoReportRealRepository";
import ActivityRepository from "../../repository/ActivityRepository";

function ActivityView(props) {

    const [indicators, setIndicators] = useState([])
    const [activity, setActivity] = useState({})
    const [indicatorReports, setIndicatorReports] = useState([])
    const [moderator, setModerator] = useState({})
    const [canOpenReports, setCanOpenReports] = useState(false)
    const [statusReports, setStatusReports] = useState({})

    useEffect(() => {
        if (props.fromMeasure) {
            IndicatorReportRepository.getAllByActivity(props.activity.id).then(res => {
                setIndicatorReports(res.data)
            });
            IndicatorReportRealRepository.getStatusReports(props.activity.id).then(res => {
                setStatusReports(res.data)
            });
        } else {
            setIndicators(props.activity?.indicators)
            setActivity(props.activity)
            setIndicatorReports(props.activity?.indicatorReports)
            setModerator(props.activity?.moderators)
            setCanOpenReports(props.activity?.canOpenReports)
            setStatusReports(props.activity?.indicatorReportRealStatusReportDto)
        }

    })


    const getStatusForEvaluation = () => {
        const indicatorReport = indicatorReports?.find(i => i.evaluation.open);
        return indicatorReport ? indicatorReport.status.statusMk : '/';
    }

    const getSupervisorStatus = () => {
        return indicatorReports?.find(i => i.evaluation.open)?.statusSupervisor?.statusMk;
    }

    const getEvaluatorStatus = () => {
        return indicatorReports?.find(i => i.evaluation.open)?.evaluatorStatus?.statusMk;
    }

    const saveActivityNote = id => {
        const note = document.getElementById(`activityNote${id}`).value
        ActivityRepository.saveActivityNote(id, note).then( () => {
            toast.success(strings.success)
        }).catch( () => {
            toast.error(strings.failure)
        })
    }

    if (props.activity !== null) {
        let statusUnderlineColor = null;

        let reportStatusRealized = 0;
        let reportStatusUnrealized = 0;
        let reportStatusInPreparation = 0;

        if (props.activity.status.statusMk === "Реализиран") {
            statusUnderlineColor = '#5BB385';
        }
        if (props.activity.status.statusMk === "Во тек") {
            statusUnderlineColor = '#ECC673';
        }
        if (props.activity.status.statusMk === "Нереализиран") {
            statusUnderlineColor = '#EB5658';
        }

        for (let i = 0; i < indicatorReports?.length; i++) {
            if (indicatorReports[i].status.statusMk === "Реализиран")
                reportStatusRealized++;
            if (indicatorReports[i].status.statusMk === "Во подготовка")
                reportStatusInPreparation++;
            if (indicatorReports[i].status.statusMk === "Нереализиран")
                reportStatusUnrealized++;
        }

        let statusColor = 'black'
        const statusForEvaluation = getStatusForEvaluation()
        switch (statusForEvaluation) {
            case ('Вратен на доработка'):
                statusColor = '#cb9300';
                break;
            case ('Неподнесен'):
                statusColor = '#d50000';
                break;
            case ('Поднесен'):
                statusColor = '#0077d0';
                break;
            case ('Прифатен'):
                statusColor = '#00c4c4';
                break;
        }

        let supervisorColor = 'black';
        const supervisorStatus = getSupervisorStatus();
        const evaluatorStatus = getEvaluatorStatus();

        switch (supervisorStatus) {
            case ('За проверка'):
                supervisorColor = '#FFA500';
                break;
            case ('Проверено / финален извештај'):
                supervisorColor = '#45c400';
                break;
            case ('Во изработка'):
                supervisorColor = '#0077d0';
                break;
        }

        const borderColor = activity.status?.statusMk === 'Реализирана' ? 'green' :
          (supervisorStatus === 'Во изработка' || supervisorStatus === undefined ? statusColor : supervisorColor);
        const evaluatorStatusBorder = activity.status?.statusMk === 'Реализирана' ? 'green' : borderColor;
        const activityFinished = activity.status?.statusMk === 'Реализирана';

        return (
            <div>
                <div className="row activity" style={{borderColor: localStorage.getItem('role') === 'ROLE_EVALUATOR' ? evaluatorStatusBorder : borderColor}}>
                    <div className="col-md-6" style={{color: "black", padding: "10px"}}>
                        <div className="activityLeft">
                            <a href={`/activity/${props.activity.id}?measureId=${props.activity.measure.id}&napId=${props.activity.measure.problem.nap.id}&problemId=${props.activity.measure.problem.id}`}
                               style={{color: "black", align: "left"}}>
                                {localStorage.getItem("activeLanguage") === 'mk'
                                    ?
                                    <b style={{fontSize: "20px", fontWeight: "700"}}>{props.activity.nameMk}</b>
                                    :
                                    <b style={{fontSize: "20px", fontWeight: "700"}}>{props.activity.nameAl}</b>
                                }
                            </a>
                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.deadlineForRealization}: &nbsp;
                                {props.activity.activityDateType !== "NOTDEFINED" ? (props.activity.activityDateType === "FIRSTHALF" ? strings.firstHalf : (props.activity.activityDateType === "SECONDHALF" ? strings.secondHalf : "")) : " "}
                                {props.activity.yearDate ? " - " + props.activity.yearDate : ""}
                                {props.activity.continuously ? strings.continuousInView : ""}</p>
                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.financialImplications}:&nbsp;
                                <span style={{textDecoration: "underline solid black 2px"}}>
                                    {props.activity.financialImplications ? strings.has : strings.hasNot}
                                </span>
                            </p>
                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.status}:&nbsp;
                                <span style={{textDecoration: "underline solid " + statusUnderlineColor + " 3px"}}>
                                        {localStorage.getItem("activeLanguage") === 'mk'
                                            ?
                                            props.activity.status.statusMk
                                            :
                                            props.activity.status.statusAl}

                                </span>
                            </p>
                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.competentInstitution}: <br/>
                                &nbsp; &nbsp; &nbsp; &nbsp; -
                                <a href={`/?institution=${props.activity.competentInstitution.id}`}
                                   style={{fontWeight: "bold", color: 'blue'}}>

                                    {localStorage.getItem("activeLanguage") === 'mk'
                                        ?
                                        (props.activity.competentInstitution ? props.activity.competentInstitution.nameMk : "")
                                        :
                                        (props.activity.competentInstitution ? props.activity.competentInstitution.nameAl : "")
                                    }
                                </a>
                                {props.checkModerator ? moderator ? ''
                                    : <span style={{color: 'red'}}> - Нема одговорно лице</span> : ''}

                            </p>
                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.includedInstitutions}:
                                {props.activity.activityInstitutions.map((item, index) => {
                                    return (
                                        <span key={item.id}>
                                                <br/>
                                            &nbsp; &nbsp; &nbsp; - {localStorage.getItem("activeLanguage") === 'mk'
                                            ?
                                            item.nameMk
                                            :
                                            item.nameAl}
                                            </span>
                                    )
                                })}
                            </p>

                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - {strings.napArea}:&nbsp;
                                <span>
                                        {localStorage.getItem("activeLanguage") === 'mk'
                                            ?
                                            activity.napArea?.nameMk
                                            :
                                            activity.napArea?.nameAl}

                                </span>
                            </p>

                            <p style={{fontSize: "14px", paddingTop: "3px"}}>
                                - <a href={props.activity?.measure?.problem?.linkToPdf} target="_blank"
                                     style={{color: "blue"}}> Линк до PDF</a>
                            </p>

                            {activityFinished ?
                              <p style={{color: "green", fontSize: "14px", paddingTop: "3px"}}>
                                  Активноста е реализирана!
                              </p> :
                              ""
                            }

                            <a href={`/activity/${props.activity.id}?measureId=${props.activity.measure.id}&napId=${props.activity.measure.problem.nap.id}&problemId=${props.activity.measure.problem.id}`}
                               className="defaultBtn btn btn-sm btn-light justify-content-end text-right"
                               style={{
                                   float: "right",
                                   marginTop: "10px",
                                   marginRight: "10px",
                                   backgroundColor: "#E2E6EA"
                               }}>
                                {strings.more}
                            </a>
                        </div>
                    </div>
                    <div className="col-md-3" style={{padding: "10px", position: "relative"}}>
                        <div className="activityLeft">
                            <b style={{
                                fontSize: "20px",
                                fontWeight: "700",
                                color: "black"
                            }}><span>{strings.indicators}</span></b>
                            <p style={{fontSize: "14px", color: "black"}}>- Неподнесени:
                                <span style={{
                                    fontWeight: "bold",
                                    color: "#d50000"
                                }}>&nbsp;{statusReports?.nepodneseniIndikatori}</span>
                            </p>
                            <p style={{fontSize: "14px", color: "black"}}>- Поднесени:
                                <span style={{
                                    fontWeight: "bold",
                                    color: "#0077d0"
                                }}>&nbsp;{statusReports?.podneseniIndikatori}</span>
                            </p>
                            <p style={{fontSize: "14px", color: "black"}}>- Вратени на доработка:
                                <span style={{
                                    fontWeight: "bold",
                                    color: "#cb9300"
                                }}>&nbsp;{statusReports?.vrateniNaDorabotkaIndikatori}</span>
                            </p>
                            <p style={{fontSize: "14px", color: "black"}}>- Прифатени:
                                <span style={{
                                    fontWeight: "bold",
                                    color: "#45c400"
                                }}>&nbsp;{statusReports?.prifateniIndikatori}</span>
                            </p>
                            <a href={`/indicators/${props.activity.id}?measureId=${props.activity.measure.id}&napId=${props.activity.measure.problem.nap.id}&problemId=${props.activity.measure.problem.id}`}
                                className="defaultBtn btn btn-sm btn-primary justify-content-end text-right"
                                style={{float: "right", position: "absolute", bottom: "10px", right: "20px"}}>
                                  {strings.indicators}
                            </a>
                            {localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' &&
                                <div>
                                    <textarea
                                        defaultValue={props.activity?.activityNote}
                                        placeholder={""}
                                        name={"activityNote"}
                                        id={`activityNote${props.activity?.id}`}
                                        rows={4}
                                        // onBlur={(e) => this.onNoteChangeHandler(e)}
                                        // onChange={(e) => this.onNoteChangeHandler(e)}
                                        className="form-control mr-2 mt-2"
                                        style={{background: '#fff9bc', borderRadius: '5px', padding: '3px', fontSize: '14px', whiteSpace: 'pre-wrap'}}
                                    />
                                    <button className="btn btn-sm addActivityBtn justify-content-end text-right mb-0"
                                            onClick={() => saveActivityNote(props.activity?.id)}
                                    >
                                        Зачувај забелешка
                                    </button>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="col-md-3" style={{padding: "10px", position: "relative"}}>
                        <div>
                            <b style={{fontSize: "20px", fontWeight: "700", color: "black"}}>
                                {strings.statusOfCurrentReport}:
                            </b>
                            <p style={{
                                color: statusColor,
                                fontSize: '16px'
                            }}>
                                {statusForEvaluation}
                            </p>

                            <br/>

                            <b style={{fontSize: "20px", fontWeight: "700", color: "black"}}>
                                Статус на супервизор:
                            </b>
                            <p style={{
                                color: supervisorColor,
                                fontSize: '16px'
                            }}>
                                {supervisorStatus || '/'}
                            </p>

                            <br/>

                            <b style={{fontSize: "20px", fontWeight: "700", color: "black"}}>
                                Статусот на активноста за тековната евалуација:
                            </b>
                            <p style={{
                                fontSize: '16px'
                            }}>
                                {evaluatorStatus || '/'}
                            </p>

                            <a href={`/indicatorReports/${props.activity.id}?measureId=${props.activity.measure.id}&napId=${props.activity.measure.problem.nap.id}&problemId=${props.activity.measure.problem.id}`}
                                className="defaultBtn btn btn-sm btn-info justify-content-end text-right"
                                style={{float: "right", position: "absolute", bottom: "10px", right: "10px"}}>
                                {strings.allReports}
                            </a>

                        </div>
                    </div>

                </div>

            </div>
        )
    } else
        return null;
}

export default ActivityView;
