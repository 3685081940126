import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { strings } from "../../Localization/Localization";
import './css/DesignFeedbackItem.css';
import Select from "react-select";
import FeedbackRepository from "../../repository/FeedbackRepository";
import { toast } from "react-toastify";

const EditFeedbackItemNew = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const [generatedOptionsNumber, setGeneratedOptionsNumber] = useState();
    const [optionsDisplay, setOptionsDisplay] = useState([])
    const [editFlag, setEditFlag] = useState(true)
    const [feedbackItems, setFeedbackItems] = useState([]);
    const [valuesDisplay, setValuesDisplay] = useState([])
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null)

    let feedbackId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    useEffect(() => {
        checkIfEditable();
        getListOfFeedbackItemsForFeedback();
    }, [])

    const checkIfEditable = async () => {
        if (props.entity["options"] !== undefined && editFlag === true) {
            var tmpNum = props.entity["options"].length;
            await setGeneratedOptionsNumber(tmpNum)
            await showNumberOptions(tmpNum);
            await setEditableOptions();
            await setDependent();
            setEditFlag(false);
        }
    }

    const setDependent = async () => {
        if (props.entity.type !== "NOTE" && props.entity.dependentFeedbackItem != null) {
            props.onChange("dependentFeedbackItemId", props.entity.dependentFeedbackItem.id)
            props.onChange("dependentAnswer", props.entity.dependentAnswer)
            await getOptionsForSelectedItem(props.entity.dependentFeedbackItem.id);
        }
    }

    const setEditableOptions = () => {
        for (let i = 0; i < props.entity["options"].length; i++) {
            props.onChange(`option${i}`, props.entity["options"][i])
        }
        for (let i = 0; i < props.entity["options"].length; i++) {
            props.onChange(`v${i}`, props.entity["values"][i])
        }
    }

    const onOptionsChangeHandler = (option) => {

        if (option !== undefined) {
            let name = option.target.name;
            let value = option.target.value;

            props.onChange(name, value)
        }
    }

    const onValuesChangeHandler = (v) => {
        if (v !== undefined) {
            let name = v.target.name;
            let value = v.target.value;

            props.onChange(name, value)
        }
    }

    const showNumberOptions = async (number) => {
        let optionsTmp = []
        let valuesTmp = []
        var tmpNum = generatedOptionsNumber;
        if (!isNaN(number)) {
            tmpNum = number
        }
        for (let i = 0; i < tmpNum; i++) {

            if (props.entity["options"] !== undefined) {
                await onOptionsChangeHandler({ target: { name: `option${i}`, value: props.entity["options"][i] } })
                await onValuesChangeHandler({ target: { name: `v${i}`, value: props.entity["values"][i] } })
            } else {
                await onOptionsChangeHandler({ target: { name: `option${i}`, value: "" } })
                await onValuesChangeHandler({ target: { name: `v${i}`, value: null } })
            }
            optionsTmp.push(
                <div style={{ marginBottom: "7px" }} className="mt-2">
                    <input name={`option${i}`}
                        onChange={onOptionsChangeHandler}
                        defaultValue={props.entity[`option${i}`]}
                        style={{ width: '100%', border: '1px solid gray', borderRadius: '10px' }}
                        className="form-control" />
                </div>
            )
            valuesTmp.push(
                <div style={{ marginBottom: "7px" }} className="mt-2">
                    <input name={`v${i}`}
                        type={"number"}
                        onChange={onValuesChangeHandler}
                        defaultValue={props.entity[`v${i}`]}
                        style={{ width: '100%' }}
                        className="form-control" />
                </div>
            )
        }
        setOptionsDisplay(optionsTmp);
        setValuesDisplay(valuesTmp)
        props.onChange("optionsLength", generatedOptionsNumber);
    }

    const changeNumberOfOptions = async (number) => {
        if (parseInt(number.target.value)) {
            await setGeneratedOptionsNumber(parseInt(number.target.value))
        }
    }

    const getListOfFeedbackItemsForFeedback = () => {
        FeedbackRepository.getAllQuestionsForFeedback(feedbackId).then(res => {
            const data = []
            data.push(...res.data);
            feedbackItems.push({ value: 0, label: '/', name: "dependentFeedbackItemId" })
            data.map(item => {
                if (item.type !== "NOTE")
                    feedbackItems.push({ value: item.id, label: item.name, name: "dependentFeedbackItemId" })
            })
        }).catch(() => toast.error(strings.failedToLoadData));
    }

    const handleNoteChange = (noteText) => {
        props.onChange("note", noteText.target.value)
    }

    const handleQuestionChange = async (selectedItem) => {
        if (selectedItem != null) {
            props.onChange(selectedItem.name, selectedItem.value);
            if (selectedItem.value != null) {
                await getOptionsForSelectedItem(selectedItem.value);
            } else {
                setAnswers([])
            }
        }
        setSelectedAnswer(null)
    }

    const handleAnswerChange = (selectedAnswer) => {
        let stringAnswer = ""
        if (selectedAnswer !== null) {
            selectedAnswer.forEach(item => {
                stringAnswer += item.value + ";;"
            })
        }
        if (selectedAnswer != null) {
            props.onChange(selectedAnswer[0].name, stringAnswer);
        }
        setSelectedAnswer(selectedAnswer)
    }


    const getOptionsForSelectedItem = async (feedbackItemId) => {
        let feedbackItem = {}

        setAnswers([])
        await FeedbackRepository.getItemByFeedbackItemId(feedbackItemId).then(async res => {
            feedbackItem = res.data;
            let data = []
            data.push({ value: null, label: '/', name: "dependentAnswer" })
            if (feedbackItem.type === "SINGLE_CHOICE" || feedbackItem.type === "MULTIPLE_CHOICE") {
                feedbackItem["options"].map(item => {
                    data.push({ value: item, label: item, name: "dependentAnswer" })
                })
                setAnswers(data)
            }
            else {
                setAnswers([])
            }
        })
    }

    let optionsNumberDisplay = null;

    if (props.entity.type && props.entity.type.indexOf('CHOICE') > 0) {
        optionsNumberDisplay = (<Form.Group controlId="options">
            <Form.Label><span style={{ color: "red", fontSize: '18px' }} className="mr-1">*</span>Полиња
            </Form.Label>
            <div className="row">
                <div className="col-2">
                    <input type="number" className="form-control" min="0" defaultValue={generatedOptionsNumber}
                        onChange={(e) => changeNumberOfOptions(e)}
                    />
                </div>
                <div className="col-4">
                    <button type="button"
                        className="btn defaultBtn btn-lg ml-2"
                        style={{ background: '#daa608', color: 'black' }}
                        onClick={(e) => showNumberOptions(e)}>
                        Генерирај полиња
                    </button>
                </div>
            </div>
        </Form.Group>);
    } else if (props.entity.type == "NOTE") {
        optionsNumberDisplay = (
            <Form.Group controlId="options">
                <Form.Label><span style={{ color: "red", fontSize: '18px' }} className="mr-1"></span>Внеси забелешка
                </Form.Label>
                <div className="row">
                    <div className="container-fluid">
                        <textarea style={{
                            width: "100%",
                            height: "20vh"
                        }} onChange={(e) => handleNoteChange(e)}></textarea>
                    </div>
                </div>
            </Form.Group >
        );
    }
    else {
        optionsNumberDisplay = null;
    }


    return (
        <Row>
            <Col sm={12}>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: "red", fontSize: '18px' }}
                            className="mr-1">*</span>{strings.nameQuestion}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            required
                            defaultValue={props.entity.name}
                            onChange={props.handleChange} />
                    </Form.Group>
                    {/*<Form.Group controlId="description">*/}
                    {/*    <Form.Label><span style={{color: "red", fontSize: '18px'}}*/}
                    {/*                      className="mr-1">*</span>{strings.descQuestion}</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        as="textarea"*/}
                    {/*        rows={3}*/}
                    {/*        name="description"*/}
                    {/*        required*/}
                    {/*        defaultValue={props.entity.description}*/}
                    {/*        onChange={props.handleChange}/>*/}
                    {/*</Form.Group>*/}
                    {props.entity.type !== "NOTE" ? <Form.Group controlId="required">
                        <Form.Label>{strings.required}</Form.Label>
                        <input
                            type="checkbox"
                            name="required"
                            className="ml-1"
                            checked={props.entity.required}
                            onChange={e => props.handleChange({ name: 'required', value: !props.entity.required })} />
                        <span className="ml-3" style={{ color: 'red' }}>{props.entity.required ? strings.mustAnswer :
                            <div />}</span>
                    </Form.Group> : ""
                    }
                    {props.entity.type !== "NOTE" &&
                    <>
                        <Form.Group controlId="dependentFeedbackItemId" className="mt-4">
                            <Form.Label>Зависно прашање</Form.Label>
                            <div>
                                <Select
                                    placeholder={props.entity.dependentFeedbackItem != null ? props.entity.dependentFeedbackItem.name : "Зависно прашање"}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={feedbackItems}
                                    onChange={handleQuestionChange}
                                    name={"dependentFeedbackItemId"}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="dependentAnswer" className="mt-4">
                            <Form.Label>Зависен одговор</Form.Label>
                            <div>
                                <Select
                                    placeholder={props.entity.dependentFeedbackItem != null ? props.entity.dependentAnswer.split(";;").join(" ") : "Зависен одговор"}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={answers}
                                    isMulti
                                    onChange={handleAnswerChange}
                                    name={"dependentAnswer"}
                                />
                            </div>
                        </Form.Group>
                        </>
                    }

                    <Form.Group controlId="type">
                        <Form.Label><span style={{ color: "red", fontSize: '18px' }}
                            className="mr-1">*</span>{strings.type}</Form.Label>
                        <div>
                            <select onChange={props.handleChange} className="form-control custom-select"
                                value={props.entity.type} required name="type">
                                <option />
                                <option value="SINGLE_CHOICE">{strings.SINGLE_CHOICE}</option>
                                <option value="MULTIPLE_CHOICE">{strings.MULTIPLE_CHOICE}</option>
                                <option value="NUMERIC_FIELD">{strings.NUMERIC_FIELD}</option>
                                <option value="TEXT_FIELD">{strings.TEXT_FIELD}</option>
                                <option value="ATTACHMENT">{strings.file}</option>
                                <option value="NOTE">{strings.NOTE}</option>
                                <option value="DATE">{strings.date}</option>
                            </select>
                        </div>
                    </Form.Group>

                    {props.entity.type === "NUMERIC_FIELD" && 
                    <>
                    <Form.Group controlId="minValue">
                        <Form.Label>{strings.minimumValue}</Form.Label>
                        <Form.Control
                            type="text"
                            name="minValue"
                            required
                            defaultValue={props.entity.minValue}
                            onChange={props.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="minValue">
                        <Form.Label>{strings.maximumValue}</Form.Label>
                        <Form.Control
                            type="text"
                            name="maxValue"
                            required
                            defaultValue={props.entity.maxValue}
                            onChange={props.handleChange} />
                    </Form.Group>
                    </>
                    }

                    {optionsNumberDisplay}
                    {optionsNumberDisplay !== null && optionsDisplay !== null && optionsDisplay.length > 0 && props.entity.type !== "NOTE" ?
                        <Form.Group controlId="options">
                            <Form.Label className="dev">
                                <span style={{ color: "red", fontSize: '18px' }} className="mr-1">
                                    *
                                </span>
                                {strings.options}
                            </Form.Label>

                            <div className="row">
                                <div className="col-8">
                                    {optionsDisplay}
                                </div>
                                <div className="col-4">
                                    {valuesDisplay}
                                </div>
                            </div>

                        </Form.Group> : ""
                    }

                </Form>
            </Col>
        </Row>
    )
}

export default EditFeedbackItemNew;
