import React, { Component } from "react";
import UsefulDocumentRepository from "../../repository/UsefulDocumentRepository";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import { strings } from "../../Localization/Localization";
import { Button } from "react-bootstrap";
import UsefulDocumentModal from "../Crud/UsefulDocumentModal";



class UsefulDocument extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            fileToUpload: null,
            description: ""
        }
    }

    componentDidMount() {
        UsefulDocumentRepository.getAll().then(res => {
            this.setState({
                files: res.data
            })
        })
    }

    downloadFile(item) {
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/usefulDocument/download/${item.id}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, `${item.fileName}`))
    }

    uploadFile(file,description){
        let form =  new FormData()
        form.append("description",description)
        form.append("attachment",file)
        UsefulDocumentRepository.upload(form).then(res => {
            window.location.reload()
        })
    }

    deleteFile(file){
        UsefulDocumentRepository.delete(file.id).then(res => {
            window.location.reload()
        })
    }

    render() {
        return <div>
            {localStorage.getItem('role') === 'ROLE_ADMIN' &&
                <div className="row container-fluid">
                    <UsefulDocumentModal 
                    onSubmit={this.uploadFile}
                    />
                </div>
            }
            <div className="row">
                <div className="card-columns mt-5 ml-5" style={{ columnCount: "4" }}>
                    {this.state.files.map(item => {
                        return <div className="card" style={{ width: "18vw", minHeight: "25vh" }}>
                            <div className="card-body">
                                <h5 className="card-title">{item.fileName}</h5>
                                <p className="card-text">{item.description}</p>
                                <Button
                                    onClick={() => this.downloadFile(item)}
                                    className="btn btn-primary mt-3 defaultBtn"
                                    style={{position:"absolute",bottom:"10px"}}
                                    >
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                        style={{ paddingRight: '4px', color: "white" }}
                                    />
                                    <span style={{ color: "white" }}>{strings.download}</span>
                                </Button>
                                {localStorage.getItem('role') === 'ROLE_ADMIN' &&
                                    <Button
                                        onClick={() => this.deleteFile(item)}
                                        className="btn btn-danger mt-3 defaultBtn"
                                        style={{position:"absolute",bottom:"10px", left:"140px"}}
                                        >
                                        <FontAwesomeIcon icon={faDownload} size="lg"
                                            style={{ paddingRight: '4px', color: "white" }}
                                        />
                                        <span style={{ color: "white" }}>{strings.delete}</span>
                                    </Button>
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}

export default UsefulDocument;