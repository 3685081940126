import axios from '../axios/app';

const CrmEntryRepository = {

    getAllPaged: (page, size) => {
        return axios.get(`/rest/crm/all-paged?size=${size}&page=${page}`)
    },

}

export default CrmEntryRepository