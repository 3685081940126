import React, { Component, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "../../Localization/Localization";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class PendingUserModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedAreas: localStorage.getItem("areas") != "" ? localStorage.getItem("areas").split(";").filter(element => element) : [],
            fileNomination: null,
            show: false,
            embg: ""
        }
    }


    updateAnswer = (e) => {
        const name = e.target.name
        const value = e.target.value
        if (!this.state.selectedAreas.includes(name)) {
            this.setState(prevState => ({
                selectedAreas: [...prevState.selectedAreas, name]
            }))
        }
        else {
            let tmpArr = this.state.selectedAreas;
            tmpArr.splice(tmpArr.indexOf(name), 1)
            this.setState({
                selectedAreas: tmpArr 
            })
        }
    }

    validate = () => {
        let obj = this.state
        console.log(this.state)
        if (obj.selectedAreas.length===0 && obj.fileNomination === null) {
            return strings.selectAreaAttachNomination;
        }
        else if (obj.selectedAreas.length===0 && obj.fileNomination !== null) {
            return strings.pleaseFillInAtLeastOneAreaOfInterest;
        }
        else if (obj.selectedAreas.length!==0 && obj.fileNomination === null) {
            return strings.pleaseAttachNomination
        }
        else if (obj.selectedAreas.includes("Регистар на именувани лица") && obj.embg.length !== 13) {
            return strings.pleaseEnterValidEmbg
        }
        return ""
    }

    changeAreas = () => {
        let msg = this.validate()
        if (msg === "") {
            localStorage.setItem("areas", this.state.selectedAreas)
            var idArray = []
            this.props.userData.forEach(item => {
                if (this.state.selectedAreas.includes(item.name))
                    idArray.push(item.id + ":" + true)
                else
                    idArray.push(item.id + ":" + false)
            })
            this.props.onSubmit(idArray, this.state.fileNomination, this.state.embg)
            this.setState({
                show: false,
                fileNomination: null,
                selectedAreas: [],
                embg: ""
            })
        }
        else{
            toast.error(msg)
        }
    }

    renderCheckbox = () => {
        const options = this.props.userData.map(item => (
            <div key={item.id}>
                <input name={item.name}
                    className="visualizeFeedbackMulCheckInput"
                    onChange={this.updateAnswer}
                    value={item.id}
                    type="checkbox"
                    checked={this.state.selectedAreas.includes(item.name) ? true : false}
                />
                <label className="form-check-label ml-1">{item.name}</label>
            </div>
        ))
        return options
    }

    embgChange = (e) => {
        this.setState({
            embg: e.target.value
        })
    }

    setShow = (checkShow) => {
        this.setState({
            show: checkShow
        })
    }
    onNominationChange = (e) => {
        this.setState({ fileNomination: e.target.files[0] })
    }

    render() {
        return (
            <Aux>
                <Button
                    onClick={() => this.setShow(true)}
                    style={{ float: 'right', borderRadius: "10px" }}
                    className="pendingButton container-fluid"
                >
                    <FontAwesomeIcon icon={faChartArea} />
                    {strings.area}
                </Button>
                <Modal contentClassName="area-modal" show={this.state.show} onHide={() => this.setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{strings.area}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-8 form-group kt-checkbox mb-3 visualizeFeedbackQuestionBodyDiv">
                            {this.renderCheckbox()}
                        </div>
                        <input type="file"
                            id="fileNomination"
                            name="fileNomination"
                            className='form-control-file registerText bg-light'
                            onChange={this.onNominationChange} />
                        {this.state.selectedAreas.includes("Регистар на именувани лица") &&
                            <input type="text" maxLength="13" className="form-control mt-3" name="embg" placeholder={strings.embg} onChange={this.embgChange}></input>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setShow(false)}>{strings.cancel}</Button>
                        <Button className="btn btn-outline-warning" type="submit" onClick={this.changeAreas}> {strings.confirm}</Button>
                    </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default PendingUserModal;