import React, {Component} from "react";
import ProposeRepository from "../../../repository/ProposeRepository";
import {toast} from "react-toastify";
import {strings} from "../../../Localization/Localization";
import CrudModal from "../../Crud/CrudModal";
import ActivityAdd from "../Activity/ActivityAdd";
import ActivityEdit from "../Activity/ActivityEdit";
import DeleteModal from "../../Crud/DeleteModal";
import IndicatorEdit from "./IndicatorEdit";
import IndicatorAdd from "./IndicatorAdd";

class Indicator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    addIndicator = (entity) => {
        return ProposeRepository.manageIndicatorProposal(entity).then(res => {
            toast.success(strings.succAdded);
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    editIndicator = (entity) => {
        return ProposeRepository.manageIndicatorProposal(entity).then(async res => {
            toast.success(strings.succChanged);
            await this.sleep(500);
            window.location.reload();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    deleteIndicator = (indicatorId) => {
        return ProposeRepository.manageIndicatorProposal({id: indicatorId, toDelete: true}).then(res => {
            toast.success(strings.deleteModal);
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.PROPOSALS_INDICATOR}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="defaultBtnAdd btn-primary mt-4"
                            title={strings.ADD_INDICATOR_PROPOSAL}
                            fields={{proposedActivities: this.props.proposedActivities, activities: this.props.activities, proposedProblems: this.props.proposedProblems, proposedMeasures: this.props.proposedMeasures}}
                            showText={true}
                            onSubmit={this.addIndicator}
                            body={IndicatorAdd}
                        />
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.measure}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.activity}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.indicatorType}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.startDateColumn}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.endDateColumn}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.options}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.props.proposedIndicators?.map(el => this.renderIndicators(el, true))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>

                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.INDICATORS}</h2>
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.measure}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.activity}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.indicatorType}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.startDateColumn}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.endDateColumn}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.status}</th>
                    </thead>
                    <tbody>
                    {this.props.indicators.map(el => this.renderIndicators(el, false))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
            </div>
        );
    }


    measureOrRealMeasure = (indicator) => {
        let activity = indicator.activity ? indicator.activity : indicator.realActivity;
        let measure = activity?.measure ? activity?.measure : activity?.realMeasure;
        return measure?.nameMk;
    }

    problemOrRealProblem = (indicator) => {
        let activity = indicator.activity ? indicator.activity : indicator.realActivity;
        let measure = activity?.measure ? activity?.measure : activity?.realMeasure;
        let problem = measure?.problem ? measure?.problem : measure?.realProblem;
        return problem?.nameMk;
    }

    napOrRealNap = (indicator) => {
        let activity = indicator.activity ? indicator.activity : indicator.realActivity;
        let measure = activity?.measure ? activity?.measure : activity?.realMeasure;
        let problem = measure?.problem ? measure?.problem : measure?.realProblem;
        let nap = problem?.nap ? problem?.nap : problem?.realNap;
        return nap?.nameMk;
    }

    activityOrRealActivity = (indicator) => {
        return indicator.activity ? indicator.activity?.nameMk : indicator.realActivity?.nameMk;
    }


    renderIndicators = (indicator, options) => {
        return (
            <tr key={indicator.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{this.napOrRealNap(indicator)}</td>
                <td className="tableData">{this.problemOrRealProblem(indicator)}</td>
                <td className="tableData">{this.measureOrRealMeasure(indicator)}</td>
                <td className="tableData">{this.activityOrRealActivity(indicator)}</td>
                <td className="tableData">{indicator.nameMk}</td>
                <td className="tableData">{indicator.indicatorType === 'BOOLEAN' ? 'Завршен/незавршен' : 'Нумерички'}</td>
                <td className="tableData">{indicator.startDate}</td>
                <td className="tableData">{indicator.endDate}</td>
                {!options && <td className="tableData">{indicator.status?.statusMk}</td>}
                {options === true ?
                    <>
                        <td className="tableData">
                            <CrudModal entity={indicator}
                                       fixedEntity={indicator}
                                       icon="edit"
                                       btnClass="defaultBtnEdit btn-success btn-sm"
                                       title={strings.edit}
                                       fields={{proposedActivities: this.props.proposedActivities, activities: this.props.activities, proposedProblems: this.props.proposedProblems, proposedMeasures: this.props.proposedMeasures}}
                                       showText={true}
                                       onSubmit={this.editIndicator}
                                       body={IndicatorEdit}
                            />
                        </td>
                        <td className="tableData">
                            <DeleteModal btnClass={"defaultBtn ml-1"}
                                         prompt={strings.strategyGoalDeleteQuestion}
                                         showText={true}
                                         doDelete={() => this.deleteIndicator(indicator.id)}
                            />
                        </td>
                    </> : ""}
            </tr>
        )
    }



}
export default Indicator;