import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {strings} from "../../Localization/Localization";

const EvaluationEdit = (props) => {

    const [validation] = useState([])
    const [yearDate, setYearDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [firstReminderDate, setFirstReminderDate] = useState(null)
    const [secondReminderDate, setSecondReminderDate] = useState(null)

    const onChangeHandler = (e) => {
        const target = e.target;

        const value = target.value;
        const name = target.name;

        if(validation[name] !== undefined){
            validation[name].error = value === null || value === '';
        }

        props.onChange(name, value);
    };

    const onDateChangeHandler = (name, date) => {
        if(date !== null){
            props.onChange(name, date.toISOString())
            switch (name){
                case 'yearDate': setYearDate(date); break;
                case 'endDate': setEndDate(date); break;
                case 'firstReminderDate': setFirstReminderDate(date); break;
                case 'secondReminderDate': setSecondReminderDate(date); break;
            }
        }
    }

    const setValidationErrors = () => {
        validation["descriptionMk"]=({error: false, name: 'descriptionMk', message: strings.fillDescMk})
        validation["evaluationEmailMessage"]=({error: false, name: 'evaluationEmailMessage', message: strings.emailMessage})
    }

    const setDates = () => {
        const yearDateSplit = props.entity.yearDate?.split("-")
        const endDateSplit = props.entity.endDate?.split("-")
        const firstReminderDateSplit = props.entity.firstReminderDate?.split("-")
        const secondReminderDateSplit = props.entity.firstReminderDate?.split("-")

        if (yearDateSplit) {
            setYearDate(new Date(yearDateSplit[1] + "/" + yearDateSplit[0] + "/" + yearDateSplit[2]))
            props.onChange("yearDate", new Date(yearDateSplit[1] + "/" +
                yearDateSplit[0] + "/" +
                yearDateSplit[2]))
        }
        if (endDateSplit) {
            setEndDate(new Date(endDateSplit[1] + "/" + endDateSplit[0] + "/" + endDateSplit[2]))
            props.onChange("endDate", new Date(endDateSplit[1] + "/" +
                endDateSplit[0] + "/" +
                endDateSplit[2]))
        }
        if (firstReminderDateSplit) {
            setFirstReminderDate(new Date(firstReminderDateSplit[1] + "/" + firstReminderDateSplit[0] + "/" + firstReminderDateSplit[2] ))
            props.onChange("firstReminderDate", new Date(firstReminderDateSplit[1] + "/" +
                firstReminderDateSplit[0] + "/" +
                firstReminderDateSplit[2]))
        }
        if (secondReminderDateSplit) {
            setSecondReminderDate(new Date(secondReminderDateSplit[1] + "/" + secondReminderDateSplit[0] + "/" + secondReminderDateSplit[2] ))
            props.onChange("secondReminderDate", new Date(secondReminderDateSplit[1] + "/" +
                secondReminderDateSplit[0] + "/" +
                secondReminderDateSplit[2]))
        }
    }

    useEffect(() => {
        setValidationErrors();
        props.onChange("evaluationEmailMessage", props.entity.nap?.evaluationEmailMessage);
        props.onChange("descriptionMk", props.entity.descriptionMk)

        setDates()
    }, []);

    return(
        <div className="container">
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.evaluationDescriptionMk}
                </label>
                <input
                    defaultValue={props.entity.descriptionMk}
                    required={true}
                    placeholder={""}
                    name={"descriptionMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
                <small style={{color: "red"}}>{validation["descriptionMk"] && validation["descriptionMk"].error && validation["descriptionMk"].message}</small>
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.evaluationDescriptionAl}
                </label>

                <input
                    defaultValue={props.entity.descriptionAl}
                    placeholder={""}
                    name={"descriptionAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
            </div>

            {/*<div className="col-6">*/}
            {/*    <label*/}
            {/*        className="weight400 text-upper control-label control-label-xl margin-top-10">*/}
            {/*        /!*<small style={{color: "red"}}>*</small>*!/*/}
            {/*        {strings.deadlineForRealization}*/}
            {/*    </label>*/}

            {/*    <select*/}
            {/*        name="evaluationDateType"*/}
            {/*        className="form-control custom-select mb-3"*/}
            {/*        onChange={(e) => onChangeHandler(e)}>*/}

            {/*        <option value="NOTDEFINED">{strings.notDefined}</option>*/}
            {/*        <option value="FIRSTHALF">{strings.firstHalf}</option>*/}
            {/*        <option value="SECONDHALF">{strings.secondHalf}</option>*/}

            {/*    </select>*/}
            {/*</div>*/}

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.selectEvaluationStartDate}
                </label>
                <br/>

                <DatePicker
                    selected={yearDate}
                    name="yearDate"
                    dateFormat="dd.MM.yyyy"
                    onChange={date => onDateChangeHandler('yearDate', date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.selectEvaluationEndDate}
                </label>
                <br/>

                <DatePicker
                    selected={endDate}
                    name="endDate"
                    dateFormat="dd.MM.yyyy"
                    onChange={date => onDateChangeHandler('endDate', date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.selectEvaluationFirstReminder}
                </label>
                <br/>

                <DatePicker
                    selected={firstReminderDate}
                    name="firstReminderDate"
                    dateFormat="dd.MM.yyyy"
                    onChange={date => onDateChangeHandler('firstReminderDate', date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.message}
                </label>
                <br/>

                <textarea className="form-control mb-3"
                          defaultValue={props.entity.firstReminderMail}
                          rows={3}
                          id="firstReminderMail"
                          name="firstReminderMail"
                          onChange={onChangeHandler}
                />
            </div>

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.selectEvaluationSecondReminder}
                </label>
                <br/>

                <DatePicker
                    selected={secondReminderDate}
                    name="secondReminderDate"
                    dateFormat="dd.MM.yyyy"
                    onChange={date => onDateChangeHandler('secondReminderDate', date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {/*<small style={{color: "red"}}>*</small>*/}
                    {strings.message}
                </label>
                <br/>

                <textarea className="form-control mb-3"
                          rows={3}
                          defaultValue={props.entity.secondReminderMail}
                          id="secondReminderMail"
                          name="secondReminderMail"
                          onChange={onChangeHandler}
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.emailMessage}
                </label>

                <textarea
                    defaultValue={props.entity.nap?.evaluationEmailMessage}
                    placeholder={""}
                    name={"evaluationEmailMessage"}
                    rows={6}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
                <small style={{color: "red"}}>{validation["evaluationEmailMessage"] && validation["evaluationEmailMessage"].error && validation["evaluationEmailMessage"].message}</small>
            </div>

            {/*<div className="col-12">*/}
            {/*    <label*/}
            {/*        className="weight400 text-upper control-label control-label-xl margin-top-10">*/}
            {/*        <small style={{color: "red"}}>*</small>*/}
            {/*        {strings.evaluationDescriptionEn}*/}
            {/*    </label>*/}

            {/*    <input*/}
            {/*        placeholder={""}*/}
            {/*        name={"descriptionEn"}*/}
            {/*        type={"text"}*/}
            {/*        onBlur={(e) => onChangeHandler(e)}*/}
            {/*        onChange={(e) => onChangeHandler(e)}*/}
            {/*        className="form-control mb-3"*/}
            {/*    />*/}
            {/*    <small style={{color: "red"}}>{validation["descriptionEn"] && validation["descriptionEn"].error && validation["descriptionEn"].message}</small>*/}
            {/*</div>*/}

        </div>
    )

}
export default EvaluationEdit;