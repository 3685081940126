import React, {Component} from "react";
import {strings} from "../../../Localization/Localization";
import {toast} from "react-toastify";
import SearchBar from "../../Search/SearchBar";
import CrudModal from "../../Crud/CrudModal";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../Crud/DeleteModal";
import ProposeRepository from "../../../repository/ProposeRepository";
import StrategyGoalRepository from "../../../repository/StrategyGoalRepository";
import StrategyGoalEdit from "../../StrategyGoal/StrategyGoalEdit";
import StrategyGoalAdd from "../../StrategyGoal/StrategyGoalAdd";
import ProblemAdd from "./ProblemAdd";
import ProblemEdit from "./ProblemEdit";

class Problem extends Component{

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    addProblem = (entity) => {
        return ProposeRepository.manageProblemProposal(entity).then(res => {
            toast.success(strings.succAdded);
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    editProblem = (entity) => {
        return ProposeRepository.manageProblemProposal(entity).then(async res => {
            toast.success(strings.succChanged);
            await this.sleep(500);
            window.location.reload();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    deleteProblem = (problemId) => {
        return ProposeRepository.manageProblemProposal({id: problemId, toDelete: true}).then(res => {
            toast.success(strings.deleteModal);
        }).catch(err => {
            if (err.message === "Request failed with status code 405") {
                toast.error(strings.noDeleteNapArea)
            }
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.PROPOSALS_PROBLEM}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="defaultBtnAdd btn-primary mt-4"
                            title={strings.ADD_PROBLEM_PROPOSAL}
                            fields={{proposedNapAreas: this.props.proposedNapAreas,
                                     proposedStrategyGoals: this.props.proposedStrategyGoals}}
                            showText={true}
                            onSubmit={this.addProblem}
                            body={ProblemAdd}
                        />
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.NATIONAL_STRATEGY}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.napArea}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.firstName}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.descriptionOfProblemMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.STRATEGY_GOALS}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.options}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.props.proposedProblems?.map(el => this.renderProblems(el, true))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>

                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.PROBLEMS}</h2>
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.NATIONAL_STRATEGY}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.napArea}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.firstName}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.descriptionOfProblemMk}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.STRATEGY_GOALS}</th>
                    </thead>
                    <tbody>
                    {this.props.problems.map(el => this.renderProblems(el, false))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
            </div>
        );
    }

    renderProblems = (problem, options) => {
        return (
            <tr key={problem.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{problem.nap?.nameMk}</td>
                <td className="tableData">
                    Предложена: {problem.napArea?.nameMk}
                    <br/>
                    Постоечка: {problem.realNapArea?.nameMk}
                </td>
                <td className="tableData">{problem.nameMk}</td>
                <td className="tableData">{problem.descriptionMk}</td>
                <td className="tableData">
                    Предложени: {problem.strategyGoals?.map(sg => {return <p>- {sg.nameMk}</p>;})}
                    Постоечки: {problem.realStrategyGoals?.map(sg => {return <p>- {sg.nameMk}</p>;})}
                </td>
                {options === true ?
                    <>
                        <td className="tableData">
                            <CrudModal entity={problem}
                                       fixedEntity={problem}
                                       icon="edit"
                                       btnClass="defaultBtnEdit btn-success btn-sm"
                                       title={strings.edit}
                                       fields={{proposedNapAreas: this.props.proposedNapAreas,
                                                proposedStrategyGoals: this.props.proposedStrategyGoals}}
                                       showText={true}
                                       onSubmit={this.editProblem}
                                       body={ProblemEdit}
                            />
                        </td>
                        <td className="tableData">
                            <DeleteModal btnClass={"defaultBtn ml-1"}
                                         prompt={strings.strategyGoalDeleteQuestion}
                                         showText={true}
                                         doDelete={() => this.deleteProblem(problem.id)}
                            />
                        </td>
                    </> : ""}
            </tr>
        )
    }

}

export default Problem;