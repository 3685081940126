import React, {Component} from "react";
import IndicatorReportRepository from "../../repository/IndicatorReportRepository";
import {strings} from "../../Localization/Localization";
import Select from "react-select";
import {Spinner} from "react-bootstrap";
import StatusRepository from "../../repository/StatusRepository";
import AuxWrapper from "../../hoc/AuxWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

class MyIndicatorReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            indicatorReports: [],
            statuses: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.fetchIndicatorReports(-1);
        this.fetchStatuses();
    }

    fetchStatuses = () => {
        StatusRepository.getStatusesByType('ИЗВЕШТАЈ').then(res => {
            let tmp = [];
            tmp = tmp.concat({value: -1, label: 'Сите извештаи', name: 'status'})
            res.data.forEach(snapshot => {
                tmp = tmp.concat({value: snapshot.id, label: snapshot.statusMk, name: "status"});
            })
            this.setState({
                statuses: tmp
            })
        })
    }

    fetchIndicatorReports = async (id) => {
        this.setState({
            isLoading: true,
        })
        if (id === -1) {
            await IndicatorReportRepository.getAllByActiveNap().then(res => {

                let newSet = new Set();
                let activities = [];

                res.data.map(item => {
                    newSet.add(item.activity.id)
                })
                newSet.forEach(set => {
                    activities.push({id: set, indicatorReports: []})
                })
                res.data.map(item => {
                    let activity = activities.find(a => a.id === item.activity.id);
                    activity.indicatorReports = activity.indicatorReports.concat(item);
                })

                this.setState({
                    indicatorReports: res.data,
                    isLoading: false,
                    activities: activities,
                })

            }).catch(err => {
                console.log(err)
            })
        } else {
            await IndicatorReportRepository.getAllIndicatorReportsByStatus(id).then(res => {
                let newSet = new Set();
                let activities = [];

                res.data.map(item => {
                    newSet.add(item.activity.id)
                })
                newSet.forEach(set => {
                    activities.push({id: set, indicatorReports: []})
                })
                res.data.map(item => {
                    let activity = activities.find(a => a.id === item.activity.id);
                    activity.indicatorReports = activity.indicatorReports.concat(item);
                })

                this.setState({
                    indicatorReports: res.data,
                    isLoading: false,
                    activities: activities,
                })
            }).catch(err => {
                console.log(err)
            })
        }
    }

    handleStatusChange = (e) => {
        const value = e.value;
        this.fetchIndicatorReports(value);
    }

    render() {
        return (
            <div className="container-fluid">

                <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-3">
                    {strings.allReports}
                </h2>

                <div className="row">
                    <div className="col-md-6 mt-4 mb-3">
                        <label style={{fontSize: "12pt"}}>
                            {strings.status}:
                        </label>

                        <div className="row">
                            <div className="col-md-10">
                                <Select
                                    placeholder={strings.status + "..."}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    // isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.statuses}
                                    onChange={this.handleStatusChange}
                                    name={"status"}
                                />
                            </div>
                            <div className="col">
                                {
                                    this.state.isLoading ?
                                        <Spinner animation="border"/>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.activities.map((item,index0) => {
                    return item.indicatorReports.map((report, index) => {
                            return <AuxWrapper key={report.id}>
                                    {index === 0 ?
                                        <div style={{color: "black"}}>
                                            <h3>НС Област: {report.activity.measure.problem.napArea.nameMk}</h3>
                                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;Проблем: {report.activity.measure.problem.nameMk}</h3>
                                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мерка: {report.activity.measure.nameMk}</h3>
                                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Активност: {report.activity.nameMk}</h3>
                                            <h3>Надлежна институција: {report.activity.competentInstitution.nameMk}</h3>
                                        </div>
                                        : ''}
                                    <div className="mt-2 mb-2 p-2" style={{
                                        background: 'white',
                                        color: 'black',
                                        fontSize: '14px',
                                        borderRadius: '10px'
                                    }}>
                                        <table>
                                            <thead>
                                            <tr key={report.id} style={{borderBottom: '1px solid gray'}}>
                                                <th style={{width: "40%"}}>{strings.nameOfReportMk}</th>
                                                {/*<ng" style={{width: "20%"}}>{strings.nameOfReportAl}</th>*/}
                                                <th style={{width: "20%"}}>{strings.comm}</th>
                                                <th style={{width: "10%"}}>{strings.reportStatus}</th>
                                                <th style={{width: "10%"}}>{strings.evaluation}</th>
                                                <th style={{width: "10%"}}>{strings.readByEvaluator}</th>
                                                <th style={{width: "10%"}}>{strings.readByModerator}</th>
                                                <th style={{width: "10%"}}>{strings.actions}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr key={report.id}>
                                                <td style={{whiteSpace: 'pre-wrap'}}>{report.reportMk}</td>
                                                <td style={{whiteSpace: 'pre-wrap'}}>{report.reportEn}</td>
                                                <td>
                                                    {report.activity.measure.problem.nap.openForEvaluation === true ?
                                                        <span>
                                                        {report.status !== null ?
                                                            <span>
                                                                {localStorage.getItem("activeLanguage") === 'mk' ? report.status.statusMk : report.status.statusAl}
                                                            </span> :
                                                            ""
                                                        }
                                                    </span> : strings.noOpenEvaluation
                                                    }
                                                </td>
                                                <td>
                                                    {report.evaluation.descriptionMk}
                                                </td>
                                                <td className="tableData">
                                                    {report.readByEvaluator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                                        : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                                                </td>
                                                <td className="tableData">
                                                    {report.readByModerator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                                        : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                                                </td>
                                                <td>
                                                    <a href={`/indicatorReports/${report.activity.id}?measureId=${report.activity.measure.id}&napId=${report.activity.measure.problem.nap.id}&problemId=${report.activity.measure.problem.id}`}
                                                       className="defaultBtn btn btn-sm btn-info justify-content-end text-right mt-2 mb-2">
                                                        {strings.allReports}
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </AuxWrapper>
                        })
                })}

                {this.state.activities.length === 0 ? <h5>Не постојат активности за избраниот статус.</h5> : ''}
            </div>
        );
    }

}

export default MyIndicatorReports;