import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import { strings } from "../../Localization/Localization";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Css/Activation.css'
import Select from "react-select";


const Activation = props => {

    const [show, setShow] = useState(false);
    const [areasToActivate, setAreasToActivate] = useState([])

    const handleActivation = () => {
        if(props.areasToActivate ? ( props.handleActivationUser(areasToActivate)) : (props.handleActivationUser()))

        setShow(false)
    }

    const handleAreaChanged = (e) => {
        let addedAreas = []
        if (e !== null) {
            e.forEach(item => {
                addedAreas.push(item.value)
            })
        }
        setAreasToActivate(addedAreas)
    }

    return (
        <Aux>
            <Button className={"btn btn-outline-success btn-sm ml-2 userActivationButton"}
                onClick={() => setShow(true)}><FontAwesomeIcon icon={faCheck} size="lg"
                    style={{ paddingRight: '4px' }} />{strings.activate}</Button>
            <Modal show={show} onHide={() => setShow(false)} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>{strings.activateUser}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container ml-0 mb-4"}>
                        {props.prompt}
                    </div>
                    { props.areasToActivate ? (<Select
                        placeholder={""}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isMulti={true}
                        isSearchable={true}
                        options={props.areasToActivate}
                        onChange={handleAreaChanged}
                        name={"activityInstitutions"}
                    />) : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>{strings.cancel}</Button>
                    <Button className="btn btn-success" type="submit" onClick={handleActivation}>{strings.activate}</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default Activation;
