import axios from '../axios/app';

const ProposeRepository = {

    getUserProposals: () => {
        return axios.get('/rest/propose/user-proposals')
    },

    manageNapAreaProposal: (entity) => {
        return axios.post('/rest/propose/manage/nap-area', entity)
    },

    manageStrategyGoalProposal: (entity) => {
        return axios.post('/rest/propose/manage/strategy-goal', entity)
    },

    manageProblemProposal: (entity) => {
        return axios.post('/rest/propose/manage/problem', entity)
    },

    manageMeasureProposal: (entity) => {
        return axios.post('/rest/propose/manage/measure', entity)
    },

    manageActivityProposal: (entity) => {
        return axios.post('/rest/propose/manage/activity', entity)
    },

    manageIndicatorProposal: (entity) => {
        return axios.post('/rest/propose/manage/indicator', entity)
    },

}

export default ProposeRepository;