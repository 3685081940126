import React from "react";

export default function IndicatorReportStatusView(props) {

    return (
        <table className="mt-2 mb-5 table" style={{border: "1px solid black"}}>
            <thead>
                <tr>
            <th>Статуси на извештаи по години на активностите</th>
            <th>
                2020
            </th>
            <th>
                2021
            </th>
            <th>
                2022
            </th>
            <th>
                2023
            </th>
            <th>
                2024
            </th>
            <th>
                2025
            </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    Поднесени
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.podneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.podneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.podneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.podneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.podneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.podneseniIzvestai || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Неподнесени
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.nepodneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.nepodneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.nepodneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.nepodneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.nepodneseniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.nepodneseniIzvestai || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Вратени на доработка
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.izvestaiVrateniNaDorabotka || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Прифатени
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.prifateniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.prifateniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.prifateniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.prifateniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.prifateniIzvestai || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.prifateniIzvestai || 0}
                </td>
            </tr>
            <tr>
                <td>
                    Вкупно
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2020)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2021)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2022)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2023)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2024)[0]?.totalReports || 0}
                </td>
                <td>
                    {props.statuses?.filter(a => a.year === 2025)[0]?.totalReports || 0}
                </td>
            </tr>
            </tbody>
        </table>
    )
}