import React, {Component} from "react";
import QuestionRepository from "../../repository/QuestionRepository.js"
import "./css/Question.css"
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import AreaOfInterestRepository from "../../repository/AreaOfInterestRepository";
import MultiSelect from "react-multi-select-component";
import TabInstitutions from "../Feedback/Tab/TabInstitutions";
import TabTags from "../Feedback/Tab/TabTags";
import TabCategories from "../Feedback/Tab/TabCategories";


class EditQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.location.state.item.id,
            question: {},
            optionsAreas: [],
            areaName: null,
            area: {},
            title: "",
            tagsIds: [],
            selectedTabInstitutions: true,
            selectedTabTags: false,
            selectedTabCategories: false,
            institutions: [],
            tags: [],
            categories: [],
        }
    }

    changeTabToPublish = (name) => {
        if(name === "institutions"){
            this.setState({
                selectedTabInstitutions: true,
                selectedTabTags: false,
                selectedTabCategories: false
            })
        } else if(name === "tags"){
            this.setState({
                selectedTabInstitutions: false,
                selectedTabTags: true,
                selectedTabCategories: false
            })
        } else if(name === "categories"){
            this.setState({
                selectedTabInstitutions: false,
                selectedTabTags: false,
                selectedTabCategories: true
            })
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));

        QuestionRepository.getQuestionById(this.state.id).then(res => {
            this.setState({
                question: res.data,
                title: res.data.title,
                selectedAreasOfInterest: res.data.areasOfInterest?.map(area => {
                    return {
                        'value': area.id,
                        'label': area.nameMk,
                    }
                }),
            });
        });

        this.getAreasOfInterest();
        this.changeTabToPublish("institutions")
    }

    getInstitutions = (institutions) => {
        //debugger
        this.setState({institutions: institutions})
    }

    onSelectedAreaChangeHandler = (areaOfInterest) => {
        if (areaOfInterest !== null) {
            this.setState({
                selectedAreasOfInterest: areaOfInterest,
            })
        } else {
            this.setState({
                selectedAreasOfInterest: [],
            })
        }
    }

    getAreasOfInterest = () => {
        AreaOfInterestRepository.findAreasForUser().then((res) => {
            let optionsAreas = [];
            for (let i = 0; i < res.data?.length; i++) {
                optionsAreas.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            //optionsAreas.push({'value': '', 'label': '', 'disabled': true})
            this.setState({
                optionsAreas: optionsAreas
            })
        }).catch(err => toast.error(strings.failedToLoadData));
    }

    EditQuestion = (e) => {
        e.preventDefault();

        const form = {
            id: this.state.question.id,
            title: this.state.title,
            areasOfInterestIds: this.state.selectedAreasOfInterest?.map(area => area.value),
            institutionIds: this.state.institutions?.map(inst => inst.value)
        }

        QuestionRepository.updateQuestion(form).then(res => {
            toast.success(strings.succEditQuestion);
            this.props.history.push("/Question")
        }).catch(err => {
            toast.error(strings.unSucEditQuestion)
        })
    }

    onChangeHandler = (e) => {
        const target = e.target || e;
        let update = this.state.question;
        update[target.name] = target.value;
        this.setState({entity: update});
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    };

    render() {
        return (
            <div>

                <div className="col-12 mt-5 centerDiv">
                    <h2>{strings.edit}</h2>
                </div>
                <div className="row" style={{color: "black", marginTop: "50px", marginBottom: "50px"}}>
                    <div className="md-9">
                        <button className="tabButton" onClick={() => this.changeTabToPublish('institutions')}
                                style={{
                                    backgroundColor: this.state.selectedTabInstitutions ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabInstitutions ? "underline" : ""
                                }}>
                            <h6 style={{display: "inline", fontWeight: "600"}}>
                                {strings.publishToInstitution}:
                            </h6>
                        </button>

                        <button className="tabButton" onClick={() => this.changeTabToPublish('tags')}
                                style={{
                                    backgroundColor: this.state.selectedTabTags ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabTags ? "underline" : ""
                                }}>
                            <h6 style={{display: "inline", fontWeight: "600"}}>
                                {strings.publishToTags}:
                            </h6>
                        </button>

                        <button className="tabButton" onClick={() => this.changeTabToPublish('categories')}
                                style={{
                                    backgroundColor: this.state.selectedTabCategories ? "#DFF6FF" : "#BCDFEF",
                                    padding: "20px", margin: "0px 20px 0px 20px", border: 0,
                                    textDecoration: this.state.selectedTabCategories ? "underline" : ""
                                }}>
                            <h6 style={{display: "inline", fontWeight: "600"}}>
                                {strings.publishToCategories}:
                            </h6>
                        </button>
                    </div>
                    {this.state.selectedTabInstitutions ?
                        <TabInstitutions hidePublish getInstitutions={this.getInstitutions}/>
                        : ""
                    }
                    {this.state.selectedTabTags ?
                        <TabTags hidePublish getInstitutions={this.getInstitutions}/>
                        : ""
                    }
                    {this.state.selectedTabCategories ?
                        <TabCategories hidePublish getInstitutions={this.getInstitutions}/>
                        : ""
                    }

                </div>
                <div className="container mt-5">
                    <form onSubmit={this.EditQuestion}>
                        <div className="form-group mt-4">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.questionn}</label>

                            <textarea
                                required
                                placeholder=""
                                name={"title"}
                                rows={5}
                                onChange={this.handleTitleChange}
                                className="form-control"
                                defaultValue={this.state.title}
                            />
                        </div>

                        <div className="form-group mt-4">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.areasOfInterest}</label>
                            <MultiSelect
                                placeholder={this.state.areaName ? this.state.areaName : " "}
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                options={this.state.optionsAreas || []}
                                onChange={this.onSelectedAreaChangeHandler}
                                name="areaOfInterestId"
                                multiple={true}
                                value={this.state.selectedAreasOfInterest || []}
                                valueRenderer={(selected, _options) => {
                                    return selected?.map(({ label }) =>   "✔️"+ label + " ")}}
                            />
                        </div>

                        <div className="col-12 mt-4 mb-4">
                            <button type="submit"
                                    className="btn btn-info btn-sm QuestionAdminActionButton">
                                <FontAwesomeIcon icon={faSave}
                                                 size="lg"
                                                 style={{paddingRight: '4px'}}/>
                                {strings.save}
                            </button>

                            <NavLink to="/Question">
                                <button type="button"
                                        className="btn btn-dark btn-sm QuestionAdminActionButton">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}
                                                     size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {strings.goBack}
                                </button>
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


export default (EditQuestion);