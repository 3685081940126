import React, { Component } from "react";
import FeedbackItemRepository from "../../repository/FeedbackItemRepository";
import { toast } from "react-toastify";
import { strings } from "../../Localization/Localization";
import "./css/VisualizeFeedback.css"
import { faArrowLeft, faExclamation, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import FeedbackRepository from "../../repository/FeedbackRepository";
import {Spinner} from "reactstrap";


class PreviewProactiveTransparency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedItems: [],
            feedbackTitle: null,
            spinner: false,
        }
    }

    componentDidMount() {
        this.setState({spinner: true});
        this.getFeedbackItemsByFeedbackId(this.props.match.params.id);

        FeedbackRepository.getFeedbackById(this.props.match.params.id).then(res => {
            this.setState({
                feedbackTitle: res.data.name,
                spinner: false,
            })
        })
    }

    render() {

        const questions = this.state.feedItems.map(item => {
            let display = null;
            if (item.type === "SINGLE_CHOICE") {
                display = this.singleChoice(item);
            } else if (item.type === "MULTIPLE_CHOICE") {
                display = this.multipleChoice(item);
            } else if (item.type === "NUMERIC_FIELD") {
                display = this.numericField(item);
            } else if (item.type === "TEXT_FIELD") {
                display = this.textField(item);
            }
            else if (item.type === "ATTACHMENT") {
                display = this.attachmentField(item);
            }
            else if (item.type === "NOTE") {
                display = this.noteField(item)
            }
            else if (item.type === "DATE"){
                display = this.dateField(item)
            }
            return display;
        });

        if(this.state.spinner){
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner border="animation" variant="primary"/>
            </div>
        }
        else return (
            <div>
                <div className="row">
                    <div className="col-1" />
                    <div className="col-10">
                        <div className="row mt-4 titleText">
                            <div className="col-12 text-center"><h2>{this.state.feedbackTitle}</h2></div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {questions}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-3 mb-3 text-right">
                                <NavLink to={"/designTransparency/" + this.props.match.params.id}>
                                    <button type="button"
                                        className="btn btn-dark mr-3 visualizeFeedbackButtons defaultBtn">
                                        <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                            style={{ paddingRight: '4px' }} />{strings.goBack}</button>
                                </NavLink>
                                <NavLink to={"/publishProactivity/" + this.props.match.params.id}>
                                    <button type="button" className="btn mr-3 visualizeFeedbackButtons defaultBtn"
                                        style={{ background: 'rgb(64 117 140)', color: 'white' }}>
                                        <FontAwesomeIcon icon={faShare} size="lg"
                                            style={{ paddingRight: '4px' }} />{strings.publish}
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    getFeedbackItemsByFeedbackId = (feedbackId) => {
        FeedbackItemRepository.getAllFeedbackItemsRest(feedbackId).then((res) => {
            this.setState({ feedItems: res.data })
        }).catch(() => {
            toast.error(strings.failedToLoadData)
        })
    }


    singleChoice = (item) => {
        return this.displayChoice(item, 'radio');
    }


    multipleChoice = (item) => {
        return this.displayChoice(item, 'checkbox')
    }

    displayChoice = (item, type) => {
        const options = item.options.map(option => (<div className="d-inline-block col-12 pb-2" key={option}>
             <input name={item.id}
                disabled={true}
                className="visualizeFeedbackCheckInput align-top mt-1"
                value={option}
                type={type} />
            <label className="form-check-label ml-1 d-inline-block col-11">{option}</label>
        </div>));
        return (item.subQuestion ?
            (<div className="container-fluid visualizeFeedbackQuestionContainerChild" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 form-group kt-checkbox">
                        {options}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
            </div>)
            :
            (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 form-group kt-checkbox">
                        {options}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
            </div>)
        )

    }


    textField = (item) => {
        return this.displayInput(item, 'text');
    }

    numericField = (item) => {
        return this.displayInput(item, 'number');
    }

    attachmentField = (item) => {
        return this.displayInput(item, 'file')
    }

    noteField = (item) => {
        return this.displayNote(item)
    }

    dateField = (item) => {
        return this.displayInput(item, 'date')
    }

    displayNote = (item) => {
        return (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
            <div className="row visualizeFeedbackQuestionHeaderDiv">
                <div className="col-12">
                    <h4 title="Question">{item.name}</h4>
                </div>
            </div>
            <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                <div className="col-12">
                    {item.options[0]}
                </div>
            </div>
        </div>)
    }

    displayInput = (item, type) => {
        return (item.subQuestion ?
            (<div className="container-fluid visualizeFeedbackQuestionContainerChild" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 w-100 form-group">
                        <input name={item.id}
                            disabled={true}
                            className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                            type={type}
                        />
                        {type === "number" &&
                            <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
            </div>)
            :
            (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                <div className="row visualizeFeedbackQuestionHeaderDiv">
                    <div className="col-12">
                        <h4 title="Question">{item.name}</h4>
                    </div>
                </div>
                <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                    {/*<div className="col-12">*/}
                    {/*    <h3>{item.description}</h3>*/}
                    {/*</div>*/}
                    <div className="col-12 w-100 form-group">
                        <input name={item.id}
                            disabled={true}
                            className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                            type={type}
                        />
                        {type === "number" &&
                            <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                    </div>
                </div>
                {item.required && <div className="row w-100">
                    <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                        <span className="badge badge-pill badge-danger p-2 ">
                            <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1" />
                            {strings.required}
                        </span>
                    </div>
                </div>}
            </div>)
        )

    }
}

export default PreviewProactiveTransparency;

