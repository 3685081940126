import React, {Component} from "react";
import {Box, Card, CardHeader, Grid, IconButton, InputAdornment, TextField} from "@material-ui/core";
import "../Login/Login.css";
import {CardBody} from "reactstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "../../repository/service/AuthenticationService";
import './ResetPassNewPage.css'
import {strings} from "../../Localization/Localization";


class ResetPassNewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            token: '',
            password: '',
            btnDisabled: true,
            errorPassword: 'Лозинката треба да биде најмалку 5 карактери',
            errorPasswordEquals: 'Лозинките не се совпаѓаат',
            errorPasswordVisible: false,
            errorPasswordVisibleEquals: false,
            repeatPassword: '',
        }
    }

    componentDidMount() {
        let language = localStorage.getItem("activeLanguage")
        localStorage.clear();
        localStorage.setItem("activeLanguage",language);
        AuthenticationService.getNewPasswordPage(this.props.match.params.token).then(res => {
            this.setState({
                email: res.data,
                token: this.props.match.params.token
            })
        })

        if (localStorage.getItem("activeLanguage") != null) {
            strings.setLanguage(localStorage.getItem("activeLanguage"));
            this.setState({});
        } else {
            localStorage.setItem("activeLanguage", "mk");
            strings.setLanguage(localStorage.getItem("activeLanguage"));
            this.setState({});
        }
    }


    handlePasswordChange = async (e) => {
        await this.setState({
            password: e.target.value
        })
        await this.validatePassword();
    }

    handleRepeatPasswordChange = async (e) => {
        await this.setState({
            repeatPassword: e.target.value
        })
        await this.validatePassword();
    }

    validatePassword = async () => {
        if ((this.state.password.length < 5 || this.state.repeatPassword.length < 5)) {
            document.getElementById("passwordErrorId").style.color = 'red';
            document.getElementById("idBtnReset").style.opacity = '0.2';
            await this.setState({
                btnDisabled: true,
                errorPasswordVisible: true,
                errorPasswordVisibleEquals: false,
            })
        } else {
            if (this.state.password !== this.state.repeatPassword) {
                document.getElementById("passwordErrorId").style.color = '#e0eaed';
                document.getElementById("passwordErrorSecondId").style.color = 'red';
                document.getElementById("idBtnReset").style.opacity = '0.2';
                await this.setState({
                    btnDisabled: true,
                    errorPasswordVisible: false,
                    errorPasswordVisibleEquals: true,
                })
            } else {
                document.getElementById("passwordErrorId").style.color = '#e0eaed';
                document.getElementById("passwordErrorSecondId").style.color = '#e0eaed';
                document.getElementById("idBtnReset").style.opacity = '1';
                await this.setState({
                    btnDisabled: false,
                    errorPasswordVisible: false,
                    errorPasswordVisibleEquals: false,
                })
            }
        }
    }


    setLanguageToMacedonian = async () => {
        strings.setLanguage('mk');

        localStorage.setItem("activeLanguage", "mk")

        this.setState({});
    }

    setLanguageToAlbanian = async () => {
        strings.setLanguage('al');

        localStorage.setItem("activeLanguage", "al")

        this.setState({});
    }

    showPassword = () => {
        let pass = document.getElementById("passFieldId");
        pass.setAttribute("type", "text");
    }

    hidePassword = () => {
        let pass = document.getElementById("passFieldId");
        pass.setAttribute("type", "password");
    }

    showRepeatPassword = () => {
        let pass = document.getElementById("passSecondFieldId");
        pass.setAttribute("type", "text");
    }

    hideRepeatPassword = () => {
        let pass = document.getElementById("passSecondFieldId");
        pass.setAttribute("type", "password");
    }

    setNewPassword = () => {
        AuthenticationService.setNewPassword(this.state.token, this.state.password).then(() => {
            alert("Вашата лозинка е успешно сменета!");
            this.props.history.push("/login");
        }).catch(err => {
            if (err.message === "Request failed with status code 404") {
                alert("Токенот за промена на лозинка повеќе не е активен. Ве молиме извршете го процесот за промена на лозинка повторно.")
            }
        })
    }


    render() {
        if (this.state.email === '' && this.state.token === '') {
            return (
                <h1 style={{
                    color: 'black',
                    marginTop: '30px',
                    marginLeft: '20px'
                }}>{strings.userResetOrTokenExpired}</h1>
            )
        } else {
            return (
                <Box sx={{bgcolor: '', minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
                    <Grid container
                          style={{background: '', marginTop: '200px'}}>
                        <Grid container
                              item
                              xs={12}
                              md={6}>
                            <Grid item
                                  xs={0}
                                  md={3}/>
                            <Grid item
                                  xs={12}
                                  md={6}
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      justifyContent: 'center',
                                      margin: '10px'
                                  }}>
                                <Card id="cardResetId" style={{backgroundColor: '#e0eaed'}}>
                                    <CardHeader title={"Нова лозинка"}
                                                className={"text-center"}
                                    />
                                    <CardBody className={"mt-2"}>
                                        <div className="row">
                                            <div className="col-1"/>
                                            <div className="col-10">

                                                <TextField
                                                    name="password"
                                                    type="password"
                                                    fullWidth
                                                    className=""
                                                    id="passFieldId"
                                                    label={strings.password}
                                                    error={this.state.errorPasswordVisible}
                                                    onChange={this.handlePasswordChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position={"end"}
                                                                            onClick={this.showPassword}
                                                                            onMouseOut={this.hidePassword}>
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faEye} size="sm"/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <small style={{color: '#e0eaed'}}
                                                       id="passwordErrorId">
                                                    {this.state.errorPassword}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-1"/>
                                            <div className="col-10">

                                                <TextField
                                                    name="repeatPassword"
                                                    type="password"
                                                    fullWidth
                                                    className=""
                                                    id="passSecondFieldId"
                                                    label={strings.repeatPassword}
                                                    error={this.state.errorPasswordVisibleEquals}
                                                    onChange={this.handleRepeatPasswordChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position={"end"}
                                                                            onClick={this.showRepeatPassword}
                                                                            onMouseOut={this.hideRepeatPassword}>
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faEye} size="sm"/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <small style={{color: '#e0eaed'}}
                                                       id="passwordErrorSecondId">
                                                    {this.state.errorPasswordEquals}
                                                </small>
                                            </div>
                                        </div>


                                        <div className="row mt-2">
                                            <div className="col-1"/>
                                            <div className="col-10">
                                                <button id="idBtnReset"
                                                        className="btn"
                                                        disabled={this.state.btnDisabled}
                                                        onClick={this.setNewPassword}>
                                                    {strings.save}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-1"/>
                                            <div className="col-10">
                                                <NavLink to="/login">
                                                    <button id="idBtnLogin"
                                                            className="btn">
                                                        {strings.login}
                                                    </button>
                                                </NavLink>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-1"/>
                                            <div className="col-10 text-right">
                                            <span>
                                              <button type="button"
                                                      id="idHrefMK"
                                                      className="btn btn-link"
                                                      onClick={this.setLanguageToMacedonian}>
                                                MK
                                              </button>

                                              <span style={{fontSize: '12px'}}>
                                                  /
                                              </span>

                                              <button type="button"
                                                      id="idHrefSQ"
                                                      className="btn btn-link"
                                                      onClick={this.setLanguageToAlbanian}>
                                                SQ
                                              </button>
                                            </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
        }

    }
}

export default ResetPassNewPage;
