import axios from "../axios/app";

const FeedbackItemRepository = {

    getFeedbackItemPage: (feedbackId, page = 0, size = 5) => {
        return axios.get(`/rest/allFeedItemsOrderedByQuestionOrder/${feedbackId}/paged?page=${page}&size=${size}`)
    },
    addFeedbackItem: (feedbackId, obj) => {
        return axios.post(`/rest/addfeeditem?feedbackId=${feedbackId}`, obj)
    },
    deleteFeedbackItem: (id) => {
        return axios.delete(`/rest/deletefeeditem/${id}`)
    },
    updateFeedbackItem: (obj, dependentFeedbackItemId) => {
        return axios.patch(`/rest/updatefeeditem/${dependentFeedbackItemId}`, obj)
    },
    getAllFeedbackItemsRest: (feedbackId) => {
        return axios.get(`/rest/allFeedItemsOrderedByQuestionOrder/${feedbackId}`)
    },
    saveFileForFeedbackAnswer: (form) => {
        return axios({
            method: 'post',
            url: `/rest/answer/addAttachment`,
            data: form,
            headers: {
                "content-type":`multipart/form-data`
            },
        })
    },
    getAttachmentsForFeedbackItem: (feedbackItemId) => {
        return axios.get(`/rest/feedbackitem/answer/${feedbackItemId}`)
    },
    getAllAnswersForFeedbackItemId: (feedbackItemId) => {
        return axios.get(`/rest/feedback-item/all-answers-for-feedback-item-id?feedbackItemId=${feedbackItemId}`)
    },
    findFeedbackItemById: (id) => {
        return axios.get(`/rest/feedback-item/${id}`)
    },
    changeQuestionOrder: (stringOrder,feedbackId) => {
        return axios.post(`/rest/changeQuestionOrder?orderedItems=${stringOrder}&feedbackId=${feedbackId}`)
    }

}
export default FeedbackItemRepository;