import React, {Component} from "react";
import {toast} from "react-toastify";
import ProposeRepository from "../../../repository/ProposeRepository";
import {strings} from "../../../Localization/Localization";
import CrudModal from "../../Crud/CrudModal";
import MeasureAdd from "./MeasureAdd";
import ProblemEdit from "../Problem/ProblemEdit";
import DeleteModal from "../../Crud/DeleteModal";
import MeasureEdit from "./MeasureEdit";

class Measure extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    addMeasure = (entity) => {
        return ProposeRepository.manageMeasureProposal(entity).then(res => {
            toast.success(strings.succAdded)
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    editMeasure = (entity) => {
        return ProposeRepository.manageMeasureProposal(entity).then(async res => {
            toast.success(strings.succChanged)
            await (this.sleep(500))
            window.location.reload()
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    deleteMeasure = (measureId) => {
        return ProposeRepository.manageMeasureProposal({id: measureId, toDelete: true}).then(res => {
            toast.success(strings.deleteModal)
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.PROPOSALS_MEASURE}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="defaultBtnAdd btn-primary mt-4"
                            title={strings.ADD_MEASURE_PROPOSAL}
                            fields={{proposedProblems: this.props.proposedProblems,
                                proposedActivities: this.props.proposedActivities
                            }}
                            showText={true}
                            onSubmit={this.addMeasure}
                            body={MeasureAdd}
                        />
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.startDateColumn}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.endDateColumn}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.options}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.props.proposedMeasures?.map(el => this.renderMeasures(el, true))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>

                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.MEASURES}</h2>
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading  firstHeading" style={{width: '10%'}}> {strings.nap}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.problem}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.nameMk}</th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.startDateColumn}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}> {strings.endDateColumn}</th>
                    </thead>
                    <tbody>
                    {this.props.measures.map(el => this.renderMeasures(el, false))}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
            </div>
        );
    }


    napOrRealNap = (measure) => {
        let problem = measure?.problem ? measure?.problem : measure?.realProblem;
        let nap = problem?.nap ? problem?.nap : problem?.realNap;
        return nap?.nameMk;
    }

    problemOrRealProblem = (measure) => {
        let problem = measure?.problem ? measure?.problem : measure?.realProblem;
        return problem?.nameMk;
    }


    renderMeasures = (measure, options) => {
        return (
            <tr key={measure.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{this.napOrRealNap(measure)}</td>
                <td className="tableData">{this.problemOrRealProblem(measure)}</td>
                <td className="tableData">{measure.nameMk}</td>
                <td className="tableData">{measure.startDate}</td>
                <td className="tableData">{measure.endDate}</td>
                {options === true ?
                    <>
                        <td className="tableData">
                            <CrudModal entity={measure}
                                       fixedEntity={measure}
                                       icon="edit"
                                       btnClass="defaultBtnEdit btn-success btn-sm"
                                       title={strings.edit}
                                       fields={{proposedProblems: this.props.proposedProblems,
                                           proposedActivities: this.props.proposedActivities}}
                                       showText={true}
                                       onSubmit={this.editMeasure}
                                       body={MeasureEdit}
                            />
                        </td>
                        <td className="tableData">
                            <DeleteModal btnClass={"defaultBtn ml-1"}
                                         prompt={strings.strategyGoalDeleteQuestion}
                                         showText={true}
                                         doDelete={() => this.deleteMeasure(measure.id)}
                            />
                        </td>
                    </> : ""}
            </tr>
        )
    }

}


export default Measure;


