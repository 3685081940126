import React, { Component } from "react";
import { strings } from "../../Localization/Localization";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import Select from "react-select";
import "./css/ProactivityFilter.css"
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

class ProactivtyFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            form:{
            typeOfInstitutionCentralId: 0,
            typeOfInstitutionLocalId: 0,
            typeOfInstitutionValue: null,
            areaOfInstitutionCentralId: 0,
            areaOfInstitutionLocalId: 0,
            areaOfInstitutionValue: null,
            levelOfAssignmentCentralId: 0,
            levelOfAssignmentLocalId: 0,
            levelOfAssignmentValue: null,
            founderOfInstitutionCentralId: 0,
            founderOfInstitutionLocalId: 0,
            founderOfInstitutionValue: null,
            numOfEmployeeCentralId: 0,
            numOfEmployeeLocalId: 0,
            numOfEmployeeFrom: 0,
            numOfEmployeeTo: 10000000,
            worthOfBudgetCentralId: 0,
            worthOfBudgetLocalId: 0,
            worthOfBudgetFrom: 0,
            worthOfBudgetTo: 2147483647,
            },
            typeOfInstitutionOptions: null,
            areaOfInstitutionOptions: null,
            levelOfAssignmentsOptions: null,
            founderOfInstitutionOptions: null,
            publications: []

        }
    }

    componentDidMount() {
        FeedbackPublicationRepository.getFeedbackPublication(this.props.match.params.id).then(res => {
            let pubsToShow = []
            res.data.content.forEach(item => {
                item.toShow = true
                if (item.initialPublication === null) {
                    pubsToShow.push(item)
                }
            })
            this.setState({
                publications: pubsToShow
            })
            //ke treba da se postavi fiksno id zasto moze da bide null pubsToShow
            FeedbackPublicationRepository.getAnswers(pubsToShow[0].id).then(res => {
                console.log(res)
                res.data.forEach(el => {
                    let tmpList = []
                    let stateToChange = ""
                    let itemIdToChange = null

                    if (el.item.name.includes("ВИД НА ИНСТИТУЦИЈА")) {
                        let optionsToShow = []

                        if(el.item.dependentAnswer==="Централно ниво"){
                            itemIdToChange = { name: "typeOfInstitutionCentralId", stateName: "typeOfInstitutionValue", value: el.item.id }
                            stateToChange = "typeOfInstitutionOptions"

                        }
                        else{
                            itemIdToChange = { name: "typeOfInstitutionLocalId", stateName: "typeOfInstitutionValue", value: el.item.id }
                            stateToChange = "typeOfInstitutionOptions"

                        }

                        if(this.state.typeOfInstitutionOptions !== null)
                        {
                            optionsToShow = [...this.state.typeOfInstitutionOptions, ...el.item.options]
                            stateToChange = "typeOfInstitutionOptions"
                            let temp = [... new Set(optionsToShow)]
                            temp.forEach(option => {
                                tmpList.push({ value: option.trim(), label: option.trim() })
                            })
                            this.setState({typeOfInstitutionOptions: [... new Set(optionsToShow)]})

                        }
                        else{
                            stateToChange = "typeOfInstitutionOptions"
                            this.setState({typeOfInstitutionOptions: el.item.options})
                        }

                    }
                    else if (el.item.name.includes("ОСНОВАЧ НА ИНСТИТУЦИЈАТА")) {
                        el.item.options.forEach(option => {
                            tmpList.push({ value: option.trim(), label: option.trim() })
                        })
                        if(el.item.dependentAnswer==="Централно ниво"){
                            itemIdToChange = { name: "founderOfInstitutionCentralId", stateName: "founderOfInstitutionValue", value: el.item.id }
                        }
                        else{
                            itemIdToChange = { name: "founderOfInstitutionLocalId", stateName: "founderOfInstitutionValue", value: el.item.id }
                        }
                        stateToChange = "founderOfInstitutionOptions"
                        this.setState({founderOfInstitutionOptions: el.item.options})

                    }
                    else if (el.item.name.includes("ДЕЈНОСТА НА ИНСТИТУЦИЈАТА")){
                        let optionsToShow = []

                        if(el.item.dependentAnswer==="Централно ниво"){
                            itemIdToChange = { name: "areaOfInstitutionCentralId", stateName: "areaOfInstitutionValue", value: el.item.id }
                        }
                        else{
                            itemIdToChange = { name: "areaOfInstitutionLocalId", stateName: "areaOfInstitutionValue", value: el.item.id }
                        }

                        if(this.state.areaOfInstitutionOptions !== null)
                        {
                            optionsToShow = [...this.state.areaOfInstitutionOptions, ...el.item.options]
                            stateToChange = "areaOfInstitutionOptions"
                            let temp = [... new Set(optionsToShow)]
                            temp.forEach(option => {
                                tmpList.push({ value: option.trim(), label: option.trim() })
                            })
                            this.setState({areaOfInstitutionOptions: [... new Set(optionsToShow)]})

                        }
                        else{
                            stateToChange = "areaOfInstitutionOptions"
                            this.setState({areaOfInstitutionOptions: el.item.options})
                        }
                    }
                    else if (el.item.name.includes("НИВО НА ДЕЛУВАЊЕ НА ИНСТИТУЦИЈАТА")){
                        el.item.options.forEach(option => {
                            tmpList.push({ value: option.trim(), label: option.trim() })
                        })
                        if(el.item.dependentAnswer==="Централно ниво"){
                            itemIdToChange = { name: "levelOfAssignmentCentralId", stateName: "levelOfAssignmentValue", value: el.item.id }
                        }
                        else{
                            itemIdToChange = { name: "levelOfAssignmentLocalId", stateName: "levelOfAssignmentValue", value: el.item.id }
                        }
                        stateToChange = "levelOfAssignmentsOptions"
                        this.setState({levelOfAssignmentsOptions: el.item.options})
                    }
                    else if (el.item.name.includes("БРОЈ НА ВРАБОТЕНИ ВО ПЕРИОДОТ НА ИЗВЕСТУВАЊЕ") && el.item.value!=="") {
                        if(el.item.dependentAnswer==="Централно ниво"){
                        itemIdToChange = { name: "numOfEmployeeCentralId", value: el.item.id }
                        }
                        else{
                            itemIdToChange = { name: "numOfEmployeeLocalId", value: el.item.id }
                        }
                    }
                    else if (el.item.name.includes("ВРЕДНОСТ НА КОНСОЛИДИРАН БУЏЕТ НА ИНСТИТУЦИЈАТА") && el.item.value!==""){
                        console.log("vrednost",el)
                        if(el.item.dependentAnswer==="Централно ниво"){
                            itemIdToChange = { name: "worthOfBudgetCentralId", value: el.item.id }
                        }
                        else{
                            itemIdToChange = { name: "worthOfBudgetLocalId", value: el.item.id }
                        }
                    }

                    if (stateToChange !== "") {
                        this.setState(prevState => ({
                            form:{
                                ...prevState.form,
                            [stateToChange]: tmpList,
                            [itemIdToChange.name]: itemIdToChange.value
                            }
                        }))
                    }
                    else if (itemIdToChange !== null) {
                        this.setState(prevState => ({
                            form:{
                                ...prevState.form,
                            [itemIdToChange.name]: itemIdToChange.value
                            }
                        }))
                    }
                })
            })
        })

    }

    renderPublications = (feedbackPub) => {
        if (feedbackPub.finished && feedbackPub.toShow) {
            return (
                <tr style={{ width: "100%", height: "5px", border: "1px solid lightgray" }}>
                    <td className="tableData">{feedbackPub.institution.nameMk}</td>
                    <td className="tableData">{feedbackPub?.selectedYear}</td>
                    <td className="tableData">{feedbackPub.dateCreated.split(" ")[0]}</td>
                    <td className="tableData">{feedbackPub.dateUpdated.split(" ")[0]}</td>
                    <td className="tableData d-flex justify-content-center">
                        <a className='btn btn-info btn-sm defaultBtn' href={`/visualizeAnswers/${feedbackPub.id}?proactivity=16007`}>
                            {strings.report}</a>
                    </td>
                </tr>
            )
        }
    }

    handleChange = (e) => {
        let ent = this.state.form
        let form = {}
        Object.keys(ent).forEach(item => {
            if(item!==e.target.name){
                form[item]=ent[item]
            }
            else{
                form[item]=parseInt(e?.target.value)
            }
        })
        this.setState({
            form:form
        })
        form["feedbackId"]=parseInt(this.props.match.params.id)
            FeedbackPublicationRepository.filterFeedbackPublications(form).then(res => {
                let pubsToShow = []
                res.data.content.forEach(item => {
                    item.toShow = true
                    if (item.initialPublication === null) {
                        pubsToShow.push(item)
                    }
                })
                this.setState(prevState => ({
                    publications: pubsToShow
                }))
            })
    }

    handleSelectChange = (e, name, id) => {

        let ent = this.state.form
        let form = {}
        Object.keys(ent).forEach(item => {
            if(item!==name){
                form[item]=ent[item]
            }
            else{
                form[item]=e?.value
            }
        })
        form["feedbackId"]=parseInt(this.props.match.params.id)
            FeedbackPublicationRepository.filterFeedbackPublications(form).then(res => {
                console.log(res)
                let pubsToShow = []
                res.data.content.forEach(item => {
                    item.toShow = true
                    if (item.initialPublication === null) {
                        pubsToShow.push(item)
                    }
                })
                this.setState(prevState => ({
                    form: {
                        ...prevState.form,
                        [name]: e?.value
                    },
                    publications: pubsToShow
                }))
            })
        
    }


    render() {
        return (
            <>
                <div className="row">
                    <div className="col-11"></div>
                    <div className="col-1">
                        <NavLink to={`/proactiveFinished/${this.props.match.params.id}`}>
                            <button type="button" className="btn btn-light btn-sm defaultBtn ml-3 mt-3 float-right">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                 style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                        </NavLink>
                    </div>
                </div>
                <div className="row mt-3 filterRow">

                    <div className="col-12">
                        <div className="col-12 align-bottom pb-4" style={{ borderBottom: "solid", borderColor: "#f2f3f8" }}>

                            <div className="col-4 d-inline-block" >
                                <Select
                                    isClearable={true}
                                    escapeClearsValue={true}
                                    id={this.state.form.typeOfInstitutionId}
                                    placeholder={strings.typeOfInstitution}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="typeOfInstitutionValue"
                                    options={this.state.form.typeOfInstitutionOptions}
                                    onChange={(e) => this.handleSelectChange(e, "typeOfInstitutionValue", this.state.form.typeOfInstitutionId)}
                                ></Select>
                            </div>
                            <div className="col-4 d-inline-block">
                                <input
                                    id={this.state.form.numOfEmployeeId}
                                    type="text"
                                    className="filterInput"
                                    placeholder={strings.fromNumEmployees}
                                    name="numOfEmployeeFrom"
                                    onChange={this.handleChange}
                                ></input>
                                <input
                                    id={this.state.form.numOfEmployeeId}
                                    type="text"
                                    className="filterInput"
                                    placeholder={strings.toNumEmployees}
                                    style={{ float: "right" }}
                                    name="numOfEmployeeTo"
                                    onChange={this.handleChange}></input>
                            </div>
                            <div className="col-4 d-inline d-inline-block">
                                <input
                                    id={this.state.form.worthOfBudgetId}
                                    type="text"
                                    className="filterInput"
                                    placeholder={strings.fromWorthBudget}
                                    name="worthOfBudgetFrom"
                                    onChange={this.handleChange}></input>
                                <input
                                    id={this.state.form.worthOfBudgetId}
                                    type="text"
                                    className="filterInput"
                                    style={{ float: "right" }}
                                    placeholder={strings.toWorthBudget}
                                    name="worthOfBudgetTo"
                                    onChange={this.handleChange}></input>
                            </div>
                        </div>
                        <br />
                        <div className="col-12 pt-2">
                            <div className="col-4 d-inline-block">
                                <Select
                                    isClearable={true}
                                    escapeClearsValue={true}
                                    id={this.state.form.areaOfInstitutionId}
                                    placeholder={strings.areaInstitution}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="areaOfInstitutionValue"
                                    options={this.state.form.areaOfInstitutionOptions}
                                    onChange={(e) => this.handleSelectChange(e, "areaOfInstitutionValue", this.state.form.areaOfInstitutionId)}
                                ></Select>
                            </div>
                            <div className="col-4 d-inline-block">
                                <Select
                                    isClearable={true}
                                    escapeClearsValue={true}
                                    id={this.state.form.levelOfAssignmentId}
                                    placeholder={strings.levelAssigment}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="levelOfAssignmentValue"
                                    options={this.state.form.levelOfAssignmentsOptions}
                                    onChange={(e) => this.handleSelectChange(e, "levelOfAssignmentValue", this.state.form.levelOfAssignmentId)}
                                ></Select>
                            </div>
                            <div className="col-4 d-inline-block">
                                <Select
                                    isClearable={true}
                                    escapeClearsValue={true}
                                    id={this.state.form.founderOfInstitutionId}
                                    placeholder={strings.founderInstitution}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="founderOfInstitutionValue"
                                    options={this.state.form.founderOfInstitutionOptions}
                                    onChange={(e) => this.handleSelectChange(e, "founderOfInstitutionValue", this.state.form.founderOfInstitutionId)}
                                ></Select>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table-hover newTable mt-3" style={{ width: "100%" }}>
                    <thead className="tableHead">
                        <tr>
                            <th className="tableHeading firstHeading" style={{ width: "20%" }}> {strings.institution} </th>
                            <th className="tableHeading" style={{ width: "10%" }}>{strings.evaluationYear}</th>
                            <th className="tableHeading" style={{ width: "15%" }}>{strings.dateCreated}</th>
                            <th className="tableHeading" style={{ width: "15%" }}>{strings.dateAnswered}</th>
                            <th className="tableHeading lastHeading" style={{ width: "40%", textAlign: "center" }}>{strings.actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.publications.map(this.renderPublications)}
                        <tr style={{ width: "100%", height: "5px", background: "#67C4EF" }}>
                            <td style={{ borderRadius: "0 0 0 15px" }} />
                            <td />
                            <td />
                            <td />
                            <td style={{ borderRadius: "0 0 15px 0" }} />
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

}

export default ProactivtyFilter;
