import React, {Component} from "react";
import MessageRepository from "../../repository/MessageRepository";
import QuestionRepository from "../../repository/QuestionRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./css/Question.css"
import "./css/QuestionDetails.css"
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarPlus, faEdit, faReply, faUser} from "@fortawesome/free-solid-svg-icons";
import {Link, NavLink} from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeleteModal from "../Crud/DeleteModal";
import UnDeleteModal from "../Crud/UnDeleteModal";
import QuestionMessage from "./QuestionMessage";


class QuestionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            messageInput: "",
            messages: [],
            pageCount: 1,
            title: "",
            author: "",
            dateCreated: "",
            dateUpdated: "",
            message: {},
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            isUser: localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR",
            username: localStorage.getItem('email'),
            parentMessageId: null,
            messageTexts: {},
        }
    }

    getQuestion = () => {
        let id = this.props.match.params.id;
        return QuestionRepository.getQuestionById(id).then(res => {
            this.setState({
                data: res.data,
                title: res.data.title,
                author: res.data.author.email,
                dateCreated: res.data.dateCreated,
                dateUpdated: res.data.dateUpdated,
            });
            console.log(this.state.data);
        }).catch(err => {
            toast.error(`${strings.errorOpeningAnnouncementPub}`);
        });
    }

    getMessages = (selectedPage = 0) => {
        let id = this.props.match.params.id;
        if(this.state.isAdmin) {
            return MessageRepository.getMessagesForAdmin(id, selectedPage).then(res => {
                this.setState({
                    pageCount: res.data.totalPages,
                    messages: res.data,
                })
            }).catch(err => console.log(err))
        }
        if(this.state.isUser) {
            return MessageRepository.getMessagesForActive(id, selectedPage).then(res => {
                this.setState({
                    pageCount: res.data.totalPages,
                    messages: res.data,
                })
            }).catch(err => console.log(err))
        }
    }

    componentDidMount() {
        this.getMessages();
        this.getQuestion();
    }


    handleMessageChange = (e, key) => {
        const target = e.target || e;
        let update = this.state.message;
        update[target.name] = target.value;
        this.setState({entity: update});
        let messageTexts = {...this.state.messageTexts};
        messageTexts[key] = target.value
        this.setState({messageTexts: messageTexts})
    }

    handlePageClick = data => {
        this.getMessages(data.selected);
    };


    addMessage = (key) => {
        let email = localStorage.getItem('email');
        let questionId = this.props.match.params.id;
        // if (this.state.message.body !== "")
        return MessageRepository.createMessageInQuestion(email, questionId, this.state.message).then(() => {
            toast.success(strings.successfullComent)
            this.setState({message: {}});
            this.getQuestion();
            this.getMessages();
            let messageTexts = {...this.state.messageTexts};
            messageTexts[key] = ""
            this.setState({messageTexts: messageTexts})
        }).catch(() => toast.error(strings.failedToComment))
    }

    addReplyMessage = (key) => {
        let email = localStorage.getItem('email');
        let questionId = this.props.match.params.id;
        return MessageRepository.createReplyMessage(this.state.parentMessageId, questionId, email, this.state.message).then(() => {
            toast.success(strings.successfullComent);
            this.setState({message: {}});
            this.getQuestion();
            this.getMessages();
            let messageTexts = {...this.state.messageTexts};
            messageTexts[key] = ""
            this.setState({messageTexts: messageTexts})
        }).catch(() => toast.error(strings.failedToComment))
    }

    deleteMessage = (id) => {
        return MessageRepository.deleteMessage(id).then(() => {
            toast.success(strings.messageSuccDeleted);
            this.getQuestion();
            this.getMessages();
        }).catch(() => {
            toast.error(strings.messageUnSuccDeleted)
        })
    }

    unDeleteMessage = (id) => {
        return MessageRepository.unDeleteMessage(id).then(() => {
            toast.success(strings.messageSuccSharedAgain)
            this.getQuestion();
            this.getMessages();
        }).catch(() => {
            toast.error(strings.messageUnSuccSharedAgain)
        })
    }


    showCommentSection = (messageId) => {
        this.setState({
            parentMessageId: messageId
        }, function () {
            var showComment = document.getElementById(this.state.parentMessageId).getElementsByClassName('showComment')[0];
            if (showComment.style.display === 'none') {
                showComment.style.display = 'inline-block';
            } else if (showComment.style.display === 'inline-block') {
                showComment.style.display = 'none'
            }
        });

    }


    render() {

        if (!this.state.messages) {
            return (<div></div>)
        }
        return (
            <div>
                {/*this is the question */}
                <div className="container openedAnnouncementContainer" style={{minHeight: '80px !important'}}>

                    <div className="row">
                        <div className="col-12">
                            <h2 className="mt-3 ml-3 openedAnnouncementTitle">{this.state.title}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-2 openedAnnouncementInfoCol">
                            <div className="openedAnnouncementInfoDiv">
                                <h6 className="mr-3 openedAnnouncementInfo" style={{float: 'left', color: '#343a40'}}>
                                    <FontAwesomeIcon icon={faUser} size="lg" style={{paddingRight: '4px'}}/>
                                    {this.state.author}
                                </h6>
                                <h6 className="mr-3 openedAnnouncementInfo" style={{float: 'left', color: '#343a40'}}>
                                    <FontAwesomeIcon icon={faCalendarPlus} size="lg" style={{paddingRight: '4px'}}/>
                                    {this.state.dateCreated}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                {/*this is the comment section*/}
                <div className="container openedAnnouncementAddCommentContainer">
                    <div className="row w-100 mr-3 ml-3 mt-2 mb-2">
                        <div className="input-group">
                            <input type="text"
                                   key={"mainMessage"}
                                   className="form-control openedAnnouncementAddCommentTextInput"
                                   placeholder={strings.commentPlaceholder}
                                   name={"body"}
                                   onChange={(e) => this.handleMessageChange(e, "mainMessage")}
                                   value={this.state.messageTexts["mainMessage"] ?? ""}
                            />
                            <span className="input-group-btn">
                            <button className="btn btn-primary openedAnnouncementAddCommentButton"
                                    type="button"
                                    onClick={() => this.addMessage("mainMessage")}>
                                {strings.comment}</button>
                        </span>
                        </div>
                    </div>
                </div>
                {this.state.messages.map((message, index) =>
                    // these are all the active messages which are main messages
                    <QuestionMessage message={message}
                                     username={this.state.username}
                                     isAdmin={this.state.isAdmin}
                                     isUser={this.state.isUser}
                                     deleteMessage={this.deleteMessage}
                                     unDeleteMessage={this.unDeleteMessage}
                                     showCommentSection={this.showCommentSection}
                                     handleMessageChange={this.handleMessageChange}
                                     addReplyMessage={this.addReplyMessage}
                                     key={index}
                                     messageTexts={this.state.messageTexts}
                                     number={index}
                    />
                )}
                <div className="container">
                    <NavLink to="/Question">
                        <button type="button"
                                className="btn btn-info openedAnnouncementAddCommentButton openedAnnouncementBackButton">{strings.goBack}</button>
                    </NavLink>
                </div>
            </div>
        )
    }

}

export default QuestionDetails;