import axios from "../axios/app";

const FeedbackRepository = {

    getFeedbackById: (id) => {
        return axios.get("/rest/feedback/" + id)
    },
    getFeedbackPage: (email = null,isFeedback, page = 0, size = 10) => {
        if(isFeedback)
            return axios.get(`/rest/feedback/paged?page=${page}&size=${size}`);
        else
            return axios.get(`/rest/feedback/pagedNotFeedback?page=${page}&size=${size}`);
    },

    addFeedback: (obj) => {
        return axios.post(`/rest/feedback/add?name=${obj.name}&description=${obj.description}&dueDate=${obj.dueDate}&selectedYear=${obj.selectedYear}&isFeedback=${obj.isFeedback}&areaOfInterestId=${obj.areaOfInterestId}`)
    },
    editFeedback: (entity) => {
        if(entity.areaOfInterestId == null){
            entity.areaOfInterestId = entity.areaOfInterest.id
        }
        return axios.patch(`/rest/feedback/edit/${entity.areaOfInterestId}`, entity)
    },
    deleteFeedback: (id) => {
        return axios.delete("/rest/feedback/" + id)
    },
    getFeedbackStatistics: (id) => {
        return axios.get("/rest/feedback/analyse/" + id)
    },
    getFeedbackStatisticsAnswersUsers: (feedbackId,answer) => {
        return axios.get(`/rest/answer/institutions?feedbackitemid=${feedbackId}&answer=${answer}`)
    },
    getNumberOfFeedbackPublishes: (id) => {
        return axios.get(`/rest/feedback/publishes-number/${id}`)
    },
    getAllQuestionsForFeedback: (feedbackId) => {
        return axios.get(`/rest/feedbackitem/all?feedbackId=${feedbackId}`)
    },
     getOptionsForFeedbackItem: (feedbackItemId) => {
        return axios.get(`/rest/feedback-item/all-options-for-feedback-item?feedbackItemId=${feedbackItemId}`)
    },

    getItemByFeedbackItemId : (feedbackItemId) => {
        return axios.get(`/rest/feedbackItem/${feedbackItemId}`)
    },

    addWithQuestions : (feedbackItemId) => {
        return axios.post(`/rest/feedback/addFeedbackWithQuestions?feedbackId=${feedbackItemId}`)
    },

    getAllLevels: () => {
        return axios.get(`/rest/feedback/all-levels-of-activity`);
    }
};

export default FeedbackRepository;