import { ItemImage } from "semantic-ui-react";
import axios from "../axios/app";

const FeedbackPublicationRepository = {

    publishFeedbackToInstitutions: (feedbackId, insIds) => {
        return axios.post("/rest/feedback/" + feedbackId + "/institution/" + insIds)
    },
    publishFeedbackToTags: (feedbackId, tagIds) => {
        return axios.post("/rest/feedback/" + feedbackId + "/tags/" + tagIds)
    },
    getFeedbackPublication: (feedbackId, page = 0, size = 10, institution="") => {
        return axios.get(`/rest/feedpub/${feedbackId}/paged?page=${page}&size=${size}&institution=${institution}`)
    },
    getFeedbackPublicationById: (feedPubId) => {
        return axios.get(`/rest/getFeedPub/${feedPubId}`)
    },
    deleteFeedbackPub: (feedpubId) => {
        return axios.delete(`/rest/feedbackPub/${feedpubId}`)
    },
    remindFeedbackPub: (institutionId, message, feedbackId) => {
        return axios.post(`/rest/feedback/mail/reminder?fpinstitutionId=${institutionId}&message=${message}&feedbackId=${feedbackId}`)
    },
    escalateFeedbackPub: (direktorEmail, message) => {
        return axios.post(`/rest/feedback/mail/escalate?direktorEmail=${direktorEmail}&message=${message}`)
    },
    getFeedbackPublicationsForInstitution: (institutionId, page = 0, size = 9) => {
        return axios.get(`/rest/feedbackpub/paged?institutionId=${institutionId}&page=${page}&size=${size}`);
    },
    markReadFeedPub: (feedpubId) => {
        return axios.post(`/rest/feedbackPub/markRead/${feedpubId}`)
    },
    submitAnswers: (feedbackPublicationId, answers,isFinished) => {

        const data = {}
        for (var item of answers) {
            if (Array.isArray(item.value)) {
                data[item.id] = item.value.join(";");
            } else {
                data[item.id] = item.value;
            }
        }
        return axios.post(`/rest/saveFeedbackAnswers/${feedbackPublicationId}?isFinished=${isFinished}`, data);
    },
    getAnswers: (feedbackPublicationId) => {
        return axios.get(`/rest/getByPublication/${feedbackPublicationId}`);
    },
    getAnswersPub: (feedbackPublicationId) => {
        return axios.get(`/rest/getByPublicationAnswers/${feedbackPublicationId}`);
    },
    submitAnswersFromAdmin: (feedbackPublicationId, isFinished, feedbackItemIdToAnswer) => {  
        return axios({
            method: 'post',
            url: `/rest/saveFeedbackAnswersFromAdmin/${feedbackPublicationId}`,
            params: {isFinished},
            data: feedbackItemIdToAnswer,
        })
    },
    sendEmailFromAdmin: (feedbackPublicationId, isFinished, form) => {
        return axios({
            method: 'post',
            url: `/rest/sendEmailFromAdmin/${feedbackPublicationId}`,
            params: {isFinished},
            data: form,
            headers: {
                    "content-type":`multipart/form-data`
                }
        })
    },
    getProactivityForInstitution: (institutionId, page = 0, size = 9) => {
        return axios.get(`/rest/proactivity/paged?institutionId=${institutionId}&page=${page}&size=${size}`);
    },
    findAllPublicationsForFeedbackAnsweredWithYear: (feedbackId, selectedYear, keyword, page = 0, size = 10) => {
        return axios.get(`/rest/feedbackPublication/${feedbackId}?selectedYear=${selectedYear}&keyword=${keyword}&page=${page}&size=${size}`)
    },

    findByFeedbackId:(feedbackId) => {
        return axios.get(`/rest/feedpub/${feedbackId}`);
    },
    filterFeedbackPublicationsNumber:(feedbackItemId, feedbackItemAnswerValueFrom, feedbackItemAnswerValueTo, page=0, size=10) => {
        return axios.get(`/rest/filterFeedbackPublicationsNumbers?feedbackItemId=${feedbackItemId}&feedbackItemAnswerValueFrom=${feedbackItemAnswerValueFrom}&feedbackItemAnswerValueTo=${feedbackItemAnswerValueTo}&page=${page}&size=${size}`)
    },
    filterFeedbackPublications:(form, page=0, size=10) => {
        return axios.post(`/rest/filterFeedbackPublications?page=${page}&size=${size}`,form);
    },
    sendUnreadRemindEmail: (message,feedbackId) => {
        return axios.post(`/rest/feedback/mail/remindAll?message=${message}&feedbackId=${feedbackId}`)
    },
    sendUnreadEscalateEmail: (message,feedbackId) => {
        return axios.post(`/rest/feedback/mail/escalateAll?message=${message}&feedbackId=${feedbackId}`)
    },


};

export default FeedbackPublicationRepository;