import React, {useEffect, useState} from "react";
import {strings} from "../../Localization/Localization";

const IndicatorReportRealUpdate = (props) => {

    const [validation] = useState([])

    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            props.onChange(name, value);
        } else {
            const value = target.value;
            const name = target.name;

            if (validation[name] !== undefined) {
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        }

    };

    const handleRadioButtonChange = e => {
        props.onChange('finished', e);
    }


    useEffect(() => {
        props.onChange('id', props.entity.id)
    }, []);


    return (
        <div className="container">

            {props.entity.indicator.indicatorType === "BOOLEAN" ?
                <div className="col-12 mt-4">
                    <label style={{paddingRight: "10px"}}
                           className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <small style={{color: "red"}}>*</small>
                        {strings.reportForIndicatorBool}
                    </label>
                    <br/>
                    <input name={"finished"}
                           onChange={() => handleRadioButtonChange(true)}
                           defaultChecked={props.entity.finished}
                           type={"radio"}/>
                    <label className="form-check-label m-1">{strings.yes}</label>

                    <input name={"finished"}
                           onChange={() => handleRadioButtonChange(false)}
                           defaultChecked={!props.entity.finished}
                           type={"radio"}/>
                    <label className="form-check-label m-1">{strings.no}</label>
                    {/*<input*/}
                    {/*    defaultValue={props.entity.finished}*/}
                    {/*    checked={props.entity.finished}*/}
                    {/*    style={{margin: "10px", marginBottom: 0, transform: "scale(2)"}}*/}
                    {/*    placeholder={""}*/}
                    {/*    name={"finished"}*/}
                    {/*    type={"checkbox"}*/}
                    {/*    onBlur={(e) => onChangeHandler(e)}*/}
                    {/*    onChange={(e) => onChangeHandler(e)}*/}
                    {/*/>*/}
                </div>

                :

                <div className="col-12 mt-4">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <small style={{color: "red"}}>*</small>
                        {strings.reportForIndicatorNum}
                    </label>
                    <input
                        required={true}
                        defaultValue={props.entity.counter}
                        placeholder={""}
                        name={"counter"}
                        type={"number"}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control mb-3"
                    />
                </div>
            }

        </div>
    )

}

export default IndicatorReportRealUpdate;