import React, {useEffect, useState} from "react";
import {strings} from "../../../Localization/Localization";
import ProblemRepository from "../../../repository/ProblemRepository";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ProposeRepository from "../../../repository/ProposeRepository";
import NAPRepository from "../../../repository/NAPRepository";

const MeasureEdit = (props) => {

    const [problems, setProblems] = useState([]);
    const [proposedProblems, setProposedProblems] = useState([]);
    const [problemsFromNap, setProblemsFromNap] = useState([]);
    const [proposedProblemsFromNap, setProposedProblemsFromNap] = useState([]);
    const [proposedActivities, setProposedActivities] = useState([]);
    const [startDate, setSelectedStartDate] = useState(null);
    const [endDate, setSelectedEndDate] = useState(null);

    const [validation] = useState([]);

    const [selectedProblem, setSelectedProblem] = useState("");
    const [selectedProposedProblem, setSelectedProposedProblem] = useState("");
    const  [isProposedProblem, setIsProposedProblem] = useState(false);

    const [naps, setNaps] = useState([]);
    const [selectedNap, setSelectedNap] = useState("");

    const getNaps = () => {
        NAPRepository.getAllNAPsList().then(res => {
            res.data.map(item => {
                naps.push({value: item.id, label: item.nameMk, name:"nap"})
            })
        })
    }


    const getProblems = () => {
        let selectedNap = props.fixedEntity?.realProblem ? props.fixedEntity?.realProblem?.nap?.id : props.fixedEntity?.problem?.nap?.id;

        ProblemRepository.findAllProblemsList().then(res => {
            res.data.map(item => {
                problems.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "problemId",
                    nap: item.nap?.id,
                })

                if(item.nap.id === selectedNap)
                    problemsFromNap.push({
                        value: item.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                        name: "problemId",
                        nap: item.nap?.id,
                    })
            })
        })
    }

    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            // onCheckHandler();
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            if(name === 'isProposedProblem')
                setIsProposedProblem(value)

            props.onChange(name, value);
        } else {
            const value = target.value;
            const name = target.name;

            if (validation[name] !== undefined) {
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        }

    };

    console.log("selectedProposedProblem", selectedProposedProblem)

    useEffect(() => {
        getNaps();
        getProblems();
        setValidationErrors();
        getIsProposedProblem();
        let startDateSplit = props.entity.startDate.split(".")
        let endDateSplit = props.entity.endDate.split(".")
        setSelectedStartDate(new Date(startDateSplit[1] + "/" + startDateSplit[0] + "/" + startDateSplit[2]))
        onStartDateChangeHandler(new Date(startDateSplit[1] + "/" + startDateSplit[0] + "/" + startDateSplit[2]));
        setSelectedEndDate(new Date(endDateSplit[1] + "/" + endDateSplit[0] + "/" + endDateSplit[2]))
        onEndDateChangeHandler(new Date(endDateSplit[1] + "/" + endDateSplit[0] + "/" + endDateSplit[2]))
        setSelectedNap(getSelectedNap());
        setSelectedProposedProblem(props.fixedEntity.problem !== null && props.fixedEntity.problem !== undefined ? {
            value: props.fixedEntity.problem.id,
            label: props.fixedEntity.problem.nameMk,
            name: "problemId",
            nap: props.fixedEntity.problem?.nap?.id
        } : "");
        setSelectedProblem(props.entity.realProblem !== null && props.entity.realProblem !== undefined ? {
            value: props.entity.realProblem.id,
            label: props.entity.realProblem.nameMk,
            name: "realProblemId"
        } : "");
        setProposedProblems(props.fields?.proposedProblems?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "problemId",
                nap: el.nap?.id
            }
        }))
            setProposedProblemsFromNap(props.fields?.proposedProblems?.map(el => {
                if(el.nap?.id === props.fixedEntity.problem?.nap?.id || el.nap?.id === props.fixedEntity.realProblem?.nap?.id ) {
                    return {
                        value: el.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                        name: "realProblemId",
                        nap: el.nap?.id
                    }
                }
            }))

        setProposedActivities(props.fields?.proposedActivities?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "proposedActivity"
            }
        }));

        onSelectedProblemChangeHandler({
            value: props.fixedEntity?.problem?.id,
            label: props.fixedEntity?.problem?.nameMk,
            name: "problemId"
        });

        onSelectedRealProblemChangeHandler({
            value: props.fixedEntity?.realProblem?.id,
            label: props.fixedEntity?.realProblem?.nameMk,
            name: "realProblemId"
        });},

         []);


    const setValidationErrors = () => {
        validation["nameMk"] = ({error: false, name: 'nameMk', message: strings.fillProblemNameMk})
        validation["descriptionMk"] = ({error: false, name: 'descriptionMk', message: strings.descMk})
        validation["titleMk"] = ({error: false, name: 'titleMk', message: strings.fillTitle})
        validation["realProblemId"] = ({error: false, name: 'realProblemId', message: strings.fillProblem})
        validation["problem"] = ({error: false, name: 'problem', message: strings.fillProposedProbleм})
        validation["startDate"] = ({error: false, name: 'startDate', message: strings.startDate})
        validation["endDate"] = ({error: false, name: 'endDate', message: strings.endtDate})
        validation["nap"] = ({error: false, name: 'nap', message: strings.PLEASE_CHOOSE_NAP})

    }

    const onSelectedNapChangeHandler = (nap) => {
        if(nap !== null && nap !== undefined && nap?.type !== "blur") {
            let selectedNap = nap.value;
            let problemsFromNap = problems.filter((el) => {if(el.nap === selectedNap) return el});
            setProblemsFromNap(problemsFromNap);
            setSelectedProblem(null);
            let proposedProblemsFromNap = proposedProblems.filter((el) => {if(el.nap === selectedNap) return el});
            setProposedProblemsFromNap(proposedProblemsFromNap);
            setSelectedProblem("");
            setSelectedProposedProblem("");
            setSelectedNap(naps.find(el=>el.value === selectedNap));
        }
        else if (selectedNap === null || selectedNap === undefined)  {
            setSelectedProblem("");
            setSelectedProposedProblem("");
        }
    }

    const onSelectedProblemChangeHandler = (problem) => {
        props.fixedEntity["problem"] = null;
        props.entity["problem"] = null;

        if (problem !== null && problem.type !== "blur") {
            props.onChange("problemId", problem.value)
            validation["problem"].error = false;
            if(proposedProblems.length > 0)
                setSelectedProposedProblem(proposedProblems.find(el => el.value === problem.value));
        } else {
            if (problem === null || selectedProposedProblem === null) {
                props.onChange("problem", null)
                validation["problem"].error = true;
                setSelectedProposedProblem("");
            }
        }
    }

    const onSelectedRealProblemChangeHandler = (realProblem) => {
        props.fixedEntity["problem"] = null;
        props.entity["problem"] = null;
        if (realProblem !== null && realProblem.type !== "blur") {
            props.onChange("realProblemId", realProblem.value)
            validation["realProblemId"].error = false;
            if(problems.length > 0)
               setSelectedProblem(problems.find(el => el.value === realProblem.value));
        } else {
            if (realProblem === null || selectedProblem === null) {
                props.onChange("realProblemId", null)
                validation["realProblemId"].error = true;
                setSelectedProblem("");
            }
        }
    }


    const onStartDateChangeHandler = (date) => {
        if (date !== null && date.type !== "blur") {
            props.onChange("startDate", date.toLocaleDateString())
            validation["startDate"].error = false
            setSelectedStartDate(date)
        } else {
            if (date === null) {
                props.onChange("startDate", null)
                validation["startDate"].error = true
                setSelectedStartDate(null)
            }
        }
    }

    const onEndDateChangeHandler = (date) => {
        if (date !== null && date.type !== "blur") {
            props.onChange("endDate", date.toLocaleDateString())
            validation["endDate"].error = false
            setSelectedEndDate(date)
        } else {
            if (date === null) {
                props.onChange("endDate", null)
                validation["endDate"].error = true;
                setSelectedEndDate(null)

            }
        }
    }

    let getSelectedProposeActivities = () => {

    }


    let getIsProposedProblem = () => {
         setIsProposedProblem(props.entity.problem ? true: false);
    }

    let getSelectedNap = () => {
        if (props.fixedEntity?.realProblem)
            return props.fixedEntity?.realProblem?.nap !== undefined && props.fixedEntity?.realProblem?.nap != null  ? {
            value: props.fixedEntity?.realProblem?.nap?.id,
            label: props.fixedEntity?.realProblem?.nap?.nameMk,
            name: "nap"
        } : ''

        if(props.fixedEntity?.problem)
            return props.fixedEntity?.problem?.nap !== undefined && props.fixedEntity?.problem?.nap != null ? {value: props.fixedEntity?.problem?.nap?.id,
            label: props.fixedEntity?.problem?.nap?.nameMk,
            name: "nap"
        } : ''
    }

    return (
        <div className="container">

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PLEASE_CHOOSE_NAP}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={naps}
                    onBlur={onSelectedNapChangeHandler}
                    onChange={onSelectedNapChangeHandler}
                    name={"nap"}
                    value={selectedNap}
                />

                <small
                    style={{color: "red"}}>{validation["nap"] && validation["nap"].error && validation["nap"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedProblems}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedProblem"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedProblem}
                />
            </div>

            {isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PROPOSALS_PROBLEM}
                </label>

                <Select
                    id="problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedProblemsFromNap}
                    onBlur={onSelectedProblemChangeHandler}
                    onChange={onSelectedProblemChangeHandler}
                    name={"problemId"}
                    value={selectedProposedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["problem"] && validation["problem"].error && validation["problem"].message}</small>
            </div>}


            {!isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.problem}
                </label>

                <Select
                    id="real-problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={problemsFromNap}
                    onBlur={onSelectedRealProblemChangeHandler}
                    onChange={onSelectedRealProblemChangeHandler}
                    name={"realProblemId"}
                    value={selectedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["realProblemId"] && validation["realProblemId"].error && validation["realProblemId"].message}</small>
            </div>}

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.nameMk}
                </label>
                <input
                    required={true}
                    placeholder={""}
                    name={"nameMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameMk ? props.entity.nameMk : ""}
                />
                <small
                    style={{color: "red"}}>{validation["nameMk"] && validation["nameMk"].error && validation["nameMk"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.nameAl}
                </label>
                <input
                    required={false}
                    placeholder={""}
                    name={"nameAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameAl ? props.entity.nameAl : ""}
                />
            </div>


            <div className="col-12 mt-4">
                <label className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.titleMk}
                </label>
                <input
                    required={true}
                    placeholder={""}
                    name={"titleMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.titleMk ? props.entity.titleMk : ""}
                />
                <small
                    style={{color: "red"}}>{validation["titleMk"] && validation["titleMk"].error && validation["titleMk"].message}</small>
            </div>
            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.titleAl}
                </label>
                <input
                    required={false}
                    placeholder={""}
                    name={"titleAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.titleAl ? props.entity.titleAl : ""}
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.descriptionMk}
                </label>
                <textarea
                    placeholder={""}
                    name={"descriptionMk"}
                    rows={6}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                    defaultValue={props.entity.descriptionMk ? props.entity.descriptionMk : ""}
                />
                <small
                    style={{color: "red"}}>{validation["descriptionMk"] && validation["descriptionMk"].error && validation["descriptionMk"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.descriptionAl}
                </label>

                <textarea
                    placeholder={""}
                    name={"descriptionAl"}
                    rows={6}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                    defaultValue={props.entity.descriptionAl ? props.entity.descriptionAl : ""}
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.startDate}
                </label>
                <br/>
                <DatePicker
                    selected={startDate}
                    name={"startDate"}
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    onBlur={date => onStartDateChangeHandler(date)}
                    onChange={date => onStartDateChangeHandler(date)}
                    className="form-control mb-3"
                />
                <small
                    style={{color: "red"}}>{validation["startDate"] && validation["startDate"].error && validation["startDate"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.endDate}
                </label>
                <br/>

                <DatePicker
                    selected={endDate}
                    name={"endDate"}
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    onBlur={date => onEndDateChangeHandler(date)}
                    onChange={date => onEndDateChangeHandler(date)}
                    className="form-control mb-3"
                />

                <small
                    style={{color: "red"}}>{validation["endDate"] && validation["endDate"].error && validation["endDate"].message}</small>
            </div>

        </div>
    );


}
export default MeasureEdit;