import React, {Component} from "react";
import InactiveUsersRepository from "../../repository/InactiveUsersRepository";
import {strings} from "../../Localization/Localization";
import Activation from "./Activation";
import {toast} from "react-toastify";
import './Css/InactiveUsers.css'
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import AreaOfInterestRepository from "../../repository/AreaOfInterestRepository";
import UserRepository from "../../repository/UserRepository";


class InactiveUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listUsers: [],
            instName: "",
            pageCount: 1,
            allAreasOfInterest: []
        }
    }

    getAllInactiveUsers = (selectedPage = 0) => {
        InactiveUsersRepository.getAllInactiveUsersByInstName("", selectedPage).then(res => {
            this.setState({
                listUsers: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    handlePageClick = data => {
        this.getAllInactiveUsers(data.selected);
    };


    componentDidMount() {
        this.getAllAreasOfInterest();
        this.getAllInactiveUsers();
    }

    getAllAreasOfInterest = () => {
        AreaOfInterestRepository.findAllActive().then((res) => {
            this.setState({allAreasOfInterest: res.data})
        })
    }

    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-4">{strings.inactiveUsers}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="form-group has-search mb-4">
                            <span className="fa fa-search form-control-feedback"/>
                            <input type="text" className="form-control" placeholder={strings.searchByInst}
                                   style={{width: "38%"}} onChange={this.handleSearch}/>
                        </div>
                    </div>
                </div>

                <table className="table-hover newTable" style={{width: "100%"}}>
                    <thead className="tableHead">
                        <th className="tableHeading firstHeading" style={{width: '15%'}}>{strings.firstName}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.lastName}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.institution}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.role}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.documentSolution}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.dateCreated}</th>
                    <th className="tableHeading lastHeading text-center" style={{width: '10%'}}>{strings.actions}</th>
                    </thead>

                    <tbody>

                    {this.state.listUsers.map(this.renderUsers)}

                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />

                <div className="row">
                    <div className="col-10"/>
                    <div className="col-2 text-right justify-content-end">
                        <Link to={'/users/'}
                              className="btn btn-dark defaultBtn mt-5">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-1"/>{strings.goBack}</Link>
                    </div>
                </div>

            </div>
        )
    }

    getAreasOfInterestUser = () => {
        let listToApprove = []
        this.state.allAreasOfInterest.forEach(item => {
            listToApprove.push({id:item.id, nameMk: item.nameMk})
        })
        console.log(listToApprove)
        return listToApprove
    }

    renderUsers = user => {
        let instNameMk = user.institution ? user.institution.nameMk : "";
        let instNameAl = user.institution ? user.institution.nameAl : "";
        return (

            <tr key={user.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData" style={{color: 'black', fontSize: '15px'}}>{user.firstName}</td>
                <td className="tableData" style={{color: 'black', fontSize: '15px'}}>{user.lastName}</td>
                <td className="tableData"
                    style={{
                        color: 'black',
                        fontSize: '15px'
                    }}>
                    {localStorage.getItem("activeLanguage") === "mk" ? instNameMk : instNameAl}
                </td>
                <td className="tableData">{user.role == "ROLE_ADMIN" ? "Администратор" : user.role == "ROLE_INSTITUTIONAL_MODERATOR" ? "Модератор" : user.role == "ROLE_EVALUATOR" ? "Евалуатор" : user.role == "ROLE_SUPERVISOR" ? "Супервизор" : user.role="ROLE_USER_PENDING" ? "Неодобрен корисник" : user.role}</td>
                <td className="tableData">
                    {user.attachment ?
                        <a href={process.env.REACT_APP_HOST_ENV + "rest/attachment/download/" + user.attachment.id} // TODO path should be changed later.
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{color: "blue"}}>
                            {user.attachment.name}
                        </a>
                        : strings.noDocumentAttached}
                </td>

                <td className="tableData" style={{color: 'black', fontStyle: 'bold'}}>{user.dateCreated}</td>
                <td className="tableData" style={{color: 'black', fontWeight: 'bold'}}>
                    <Activation
                        handleActivationUser={(areasToActivate) => this.handleActivationUser(user.id,areasToActivate)}
                        areasToActivate={this.getAreasOfInterestUser(user)}
                        prompt={strings.userJustActivate}
                    />
                </td>
            </tr>
        )
    }

    handleActivationUser = (userId,areasToActivate) => {
        return UserRepository.approveRejectedUser(userId,areasToActivate).then(() => {
            toast.success(strings.activatedUser, {
                duration:2000
            });
        }).then(()=>window.location.reload()).catch((err) => {
            toast.error(strings.deactivatedUser);
        })
    }



    handleSearch = (e) => {
        this.setState({instName: e.target.value}, () => {
            InactiveUsersRepository.getAllInactiveUsersByInstName(this.state.instName).then(r => this.setState({
                listUsers: r.data.content,
                pageCount: r.data.totalPages
            }));
        })
    }
}

export default InactiveUsers;
